import { Grid, Paper, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../layouts'

function Versoes() {
    return (
        <Layout
            titulo="Versões"
            subtitulo="Lista de versões e alterações do sistema"
        >

            <Grid container spacing={2} mt={2} mb={5}>

                <Grid item xs={12} md={8}>
                    <Paper elevation={5} sx={{ padding: 4 }}>
                        <Typography variant="h6" gutterBottom >Versões do SAIN</Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                            1.2.1 <span style={{ fontSize: 12 }}> (22/03/2023)</span>
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>Adição no módulo de Conta Corrente da funcionalidade de vinculação dos movimentos aos recebimentos e pagamentos, bem como a baixa automática dos valores a receber ou a pagar.</li>
                            </ol>
                        </Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                            1.2.0 <span style={{ fontSize: 12 }}> (14/02/2023)</span>
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>Criação do Módulo de Conta Corrente para possibilitar o lançamento dos extratos bancários no sistema.</li>
                            </ol>
                        </Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                            1.1.0 <span style={{ fontSize: 12 }}> (30/12/2022)</span>
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>Criação do item de cadastro "TABELAS E PARÂMETROS" para possibilitar a listagem e edição de todas as tabelas utilizadas pelo sistema.</li>
                                <ol type='a'>
                                    <li>Formas de Pagamento</li>
                                    <li>Grupos de Pessoas</li>
                                    <li>Origens dos Pagamentos</li>
                                    <li>Origens dos Recebimentos</li>
                                    <li>Priodicidade dos Serviços</li>
                                    <li>Status dos Pagamentos</li>
                                    <li>Status dos Projetos</li>
                                    <li>Status dos Recebimentos</li>
                                    <li>Status dos Serviços</li>
                                    <li>Tipos de Endereço</li>
                                    <li>Tipos de Pagamento</li>
                                    <li>Tipos de Projeto</li>
                                    <li>Tipos de Redes Sociais</li>
                                    <li>Tipos de Serviço</li>
                                    <li>Tipos de Telefone</li>
                                </ol>
                                <li>Criação de funções para salvar e excluir registros das tabelas acima tanto na API quanto no controle de estado do sistema (Redux).</li>
                                <li>Correção na autorização de acesso às rotas do sistema.</li>
                            </ol>
                        </Typography>

                        <Typography variant="h6" gutterBottom mt={2}>
                            1.0.2 <span style={{ fontSize: 12 }}> (14/12/2022)</span>
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>Implementação de registros em arquivos para os logs de controle e de erros na API.</li>
                                <li>Correção de erros no cadastro de pessoas.</li>
                                <li>Correção de erros na autenticação de acesso à API.</li>
                                <li>Padronização com ícones dos botões de ação dos formulários de inclusão e alteração.</li>
                                <li>Inclusão de loading no login.</li>
                                <li>Correção de outros erros no sistema.</li>
                            </ol>
                        </Typography>

                        <Typography variant="h6" gutterBottom >
                            1.0.1
                        </Typography>

                        <Typography variant='body'>
                            <ol>
                                <li>
                                    Inclusão de novas páginas desenvolvidas:
                                    <ol type='a'>
                                        <li>Configurações (em desenvolvimento)</li>
                                        <li>Documentação (em desenvolvimento)</li>
                                        <li>Versões</li>
                                    </ol>
                                </li>
                                <li>Inclusão do item de menu 'SISTEMA' na barra de navegação e no menu drawer lateral.</li>
                                <li>Outras correções e ajustes no sistema.</li>
                            </ol>
                        </Typography>


                        <Typography variant="h6" gutterBottom mt={2}>
                            1.0.0
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>
                                    Versão inicial do sistema contendo todas as planilhas e dados do legado.<br />
                                    Esta versão irá substituir completamente o sistema de administração anterior, atualmente hospedado em sistemaninja.com.br.<br />
                                    Algumas funcionalidades do sistema anterior serão desenvolvidas futuramente.
                                </li>

                            </ol>
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper elevation={5} sx={{ padding: 4 }}>
                        <Typography variant="h6" gutterBottom >
                            Desenvolvimento Futuro
                        </Typography>
                        <Typography variant="body">
                            <ol>
                                <li>Criação do módulo de tarefas.</li>
                            </ol>
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

        </Layout>
    )
}

export default Versoes