import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../config/configApi"
import header from '../../middleware/jwtInterceptor'

const initialState = {
    loading: true,
    registro: {},
    error: ''
}
export const fetchHistorico = createAsyncThunk('pessoa/historico', (pessoa_id) => {
    const autorizacao = header()
    return api
        .get(`/pessoas/historico/${pessoa_id}`, autorizacao)
        .then((res)=> res.data)
})

export const slice = createSlice({
    name: 'historico',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchHistorico.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchHistorico.fulfilled, (state, action) => {
            state.loading = false
            state.registro = action.payload
            state.error = ''
        })
        builder.addCase(fetchHistorico.rejected, (state, action) => {
            state.loading = false
            state.registro = {}
            state.error = action.error.message
        })
    }
})

export default slice.reducer