import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import TableBanco from './tableBanco'


export const Banco = (props) => {
    return (
        <Box>
            
            <Typography variant="h5" gutterBottom align='center' mt={1}>
                Bancos
            </Typography>
            <Divider sx={{ mb: '10px' }} />
            <TableBanco />
        </Box>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Banco)