import { Close, DeleteForever } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteServico, fetchServicos } from '../../../redux/cadastro/servicoSlice';

const Excluir = (props) => {

    const dispatch = useDispatch()

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { servico } = props
    
    const handleDelete = async (registro) => {
        await dispatch(deleteServico(registro))
        dispatch(fetchServicos())
    }

    return (
        <span>
            <Tooltip title="Excluir Serviço" placement="top">
                <IconButton
                    color="primary"
                    onClick={handleOpen}
                    size="small"
                >
                    <DeleteForever color='error'/>
                </IconButton>
            </Tooltip>
            
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.warning',
                        color: 'white'
                    }}
                >
                    Atenção!
                    <IconButton color="primary" aria-label="add to shopping cart" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 4, mt:2 }}>
                    <p>Você excluirá <strong>{servico?.nome}</strong> do cadastro.</p> 
                    <p>Confirma?</p>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.warning',
                        color: 'white',
                    }}
                >
                    <Button variant="contained" color="third"  onClick={() => handleDelete(servico)}>Sim</Button>
                    <Button variant="outlined" color="secondary"  onClick={handleClose}>Não</Button>
                </DialogActions>
            </Dialog>
        </span>
    )
}

export default Excluir