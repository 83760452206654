import { useState } from 'react'
import { Grid, Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Info, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { checarData } from '../../../funcoes/data';

export default function DialogDetalhes(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { pessoa } = props
    return (
        <span>
            <span onClick={handleOpen} >
                <Info color='alternativo' sx={{ cursor: 'pointer' }} />
            </span>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                    className="dialog-title"
                >
                    Informações completas da pessoa
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <Close sx={{ color: 'white' }} />
                    </IconButton>
                </DialogTitle>

                <DialogContent sx={{ p: 4 }}>
                    <Box rowSpacing={1}>

                        <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px", mt: '10px' }}>
                            <Typography id="modal-modal-description">
                                <strong>Dados Gerais</strong>
                            </Typography>
                            <Grid container columnSpacing={1} >
                                <Grid item xs={4}>Identificador</Grid><Grid item xs={8}>{pessoa.id}</Grid>
                                <Grid item xs={4}>Nome</Grid><Grid item xs={8}>{pessoa.nome}</Grid>
                                <Grid item xs={4}>Grupo de Pessoas</Grid><Grid item xs={8}>{pessoa.grupo_pessoa_id} - {pessoa.grupoPessoas.nome}</Grid>
                                <Grid item xs={4}>Tipo de Pessoa</Grid><Grid item xs={8}>{pessoa.tipo_pessoa} - {pessoa.tiposPessoa.nome}</Grid>
                                <Grid item xs={4}>Status</Grid><Grid item xs={8}>{pessoa.status_id} - {pessoa.status.nome}</Grid>
                                <Grid item xs={4}>Data de Inclusão</Grid><Grid item xs={8}>{format(new Date(pessoa.created_at), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                <Grid item xs={4}>Última Alteração</Grid><Grid item xs={8}>{format(new Date(pessoa.updated_at), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                {
                                    pessoa.deleted_at ? (
                                        <>
                                            <Grid item xs={4}>Exclusão</Grid>
                                            <Grid item xs={8}>{format(new Date(pessoa.deleted_at), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                        </>
                                    ) : null
                                }

                            </Grid>
                        </Box>
                        {/* {console.log(pessoa)} */}
                        {
                            
                            //pessoa?.pessoaFisica && parseInt(pessoa.tipo_pessoa) === 1 ? (
                            pessoa.pessoaFisica ? (
                                (
                                    <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px" }}>
                                        <Typography id="modal-modal-description">
                                            <strong>Dados da Pessoa Física</strong>
                                        </Typography>
                                        <Grid container columnSpacing={1} >
                                            <Grid item xs={4}>Identificador</Grid><Grid item xs={8}>{pessoa.pessoaFisica?.id}</Grid>
                                            <Grid item xs={4}>CPF</Grid><Grid item xs={8}>{pessoa.pessoaFisica?.cpf}</Grid>
                                            <Grid item xs={4}>Sexo</Grid>
                                            <Grid item xs={8}>{
                                                pessoa.pessoaFisica?.sexo
                                                    ? (parseInt(pessoa.pessoaFisica?.sexo) === 1 ? 'Masculino' : 'Feminino')
                                                    : ''
                                            }
                                            </Grid>
                                            <Grid item xs={4}>Data de Nascimento</Grid>
                                            <Grid item xs={8}>{
                                                pessoa.pessoaFisica?.data_nascimento
                                                    ? (pessoa.pessoaFisica?.data_nascimento?.substring(8, 10) / pessoa.pessoaFisica.data_nascimento?.substring(5, 7) / pessoa.pessoaFisica?.data_nascimento?.substring(0, 4))
                                                    : ''
                                            }
                                            </Grid>
                                            <Grid item xs={4}>Data de Inclusão</Grid><Grid item xs={8}>{format(new Date(checarData(pessoa.pessoaFisica?.created_at)), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                            <Grid item xs={4}>Última Alteração</Grid><Grid item xs={8}>{format(new Date(checarData(pessoa.pessoaFisica?.updated_at)), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                        </Grid>
                                    </Box>
                                )
                            ) : null
                        } 

                        {
                            pessoa.pessoaJuridica && pessoa.tipo_pessoa === 2 ? (
                                (
                                    <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px" }} key={pessoa.pessoaJuridica.id}>
                                        <Typography id="modal-modal-description">
                                            <strong>Dados da Pessoa Jurídica</strong>
                                        </Typography>
                                        <Grid container columnSpacing={1} >
                                            <Grid item xs={6}>Identificador</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.id}</Grid>
                                            <Grid item xs={6}>CNPJ</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.cnpj}</Grid>
                                            <Grid item xs={6}>Razão Social</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.razao_social}</Grid>
                                            <Grid item xs={6}>Inscrição Municipal</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.inscricao_municipal}</Grid>
                                            <Grid item xs={6}>Inscrição Estadual</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.inscricao_estadual}</Grid>
                                            <Grid item xs={6}>Inscrição Estadual para Substituição Tributária</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.inscricao_estadual_substituicao_tributaria}</Grid>
                                            <Grid item xs={6}>Contato</Grid><Grid item xs={6}>{pessoa.pessoaJuridica.contato_empresa}</Grid>
                                            <Grid item xs={6}>Data de Inclusão</Grid><Grid item xs={6}>{format(new Date(pessoa.pessoaJuridica.created_at), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                            <Grid item xs={6}>Última Alteração</Grid><Grid item xs={6}>{format(new Date(pessoa.pessoaJuridica.created_at), 'dd/MM/yyyy hh:mm:ss')}</Grid>
                                        </Grid>
                                    </Box>
                                )
                            ) : null
                        }
                        
                        {pessoa.enderecos.length > 0 ? (
                            <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px" }}>
                                <Typography id="modal-modal-description">
                                    <strong>Endereços</strong>
                                </Typography>
                                {
                                    pessoa.enderecos.map((end, index) => {
                                        return (
                                            <Grid container rowSpacing={1} key={end.id} sx={{ mb: "5px" }}>
                                                <Grid item xs={12}>{index + 1}){' '}
                                                    <strong>{end.tiposEndereco.nome}:{' '}</strong>
                                                    {end.logradouro}, {' '}
                                                    {end.numero}, {' '}
                                                    {end.complemento}, {' '}
                                                    Bairro {end.bairro}, {' '}
                                                    {end.cidade}, {' '}
                                                    {end.uf}, {' '}
                                                    CEP: {end.cep}.
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Box>
                        ) : null}

                        {pessoa.redesSociais.length > 0 ? (
                            <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px" }}>
                                <Typography id="modal-modal-description">
                                    <strong>Redes Sociais</strong>
                                </Typography>
                                {
                                    pessoa.redesSociais.map((rede, index) => {
                                        return (
                                            <Grid container rowSpacing={1} key={rede.id} sx={{ mb: "5px" }} >
                                                <Grid item xs={3}>{rede.tipoRedeSocial.nome}</Grid>
                                                <Grid item xs={9} sx={{ wordWrap: 'break-word' }}>
                                                    <a href={rede.link} target="_blank" rel="noreferrer">{rede.link}</a>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Box>
                        ) : null}
                        {pessoa.telefones.length > 0 ? (
                            <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px" }}>
                                <Typography id="modal-modal-description">
                                    <strong>Telefones</strong>
                                </Typography>
                                {
                                    pessoa.telefones.map((telefone, index) => {
                                        return (
                                            <Grid container rowSpacing={1} key={index} sx={{ mb: "5px" }} >
                                                <Grid item xs={3}>{telefone.tipoTelefone.nome}</Grid>
                                                <Grid item xs={9}>+{telefone.pais_codigo}({telefone.ddd_codigo}) {telefone.numero_telefone}</Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Box>
                        ) : null}
                    </Box> 
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                >
                    <Button variant="outlined" color="secondary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>

        </span>
    );
}


