import Logo from '../../img/ideia-footer-icone.png'

export const Logotipo = () => {
    return (
        <img
            src={Logo}
            alt="Logo"
            loading="lazy"
            width={40}
        />
    )
}
export default Logotipo