import { Grid, List, Typography } from '@mui/material'
import Layout from '../layouts'
import ItemRelatorio from './itemRelatorio'
import { styled } from '@mui/material/styles';

const itensRelatorio = [
    { id: 1, rota: '/relatorios/fechamentoPeriodo', titulo: 'Fechamento por Período', descricao: 'Relatórios de conferência dos valores incluídos no sistema por período informado.' },
    { id: 2, rota: '/relatorios/pagamentosAClassificar', titulo: 'Pagamentos a classificar', descricao: 'Lista de pagamentos que necessitam de classificação com relação a projetos e serviços.' },
    { id: 3, rota: '/relatorios/contasAReceber', titulo: 'Contas a receber', descricao: 'Lista de parcelas a receber de clientes.' },
    { id: 4, rota: '/relatorios/controleGeral', titulo: 'Controle Geral', descricao: 'Relatórios de controle da planilha.' },
    
]
itensRelatorio.sort((r1, r2) => r1.titulo > r2.titulo ? 1 : -1)

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function Relatorios() {
    return (
        <Layout
            titulo="Relatórios"
            subtitulo="Acesso aos relatórios do sistema"
        >
            <Grid 
                container 
                spacing={2}
                display='flex'
                flexDirection={'column'}
            >
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Relatórios de Administração
                    </Typography>
                </Grid>
                <Demo>
                    <List dense={true}>
                        <Grid container >
                            {itensRelatorio && itensRelatorio.map(item => (
                                <Grid item xs={12} md={6} lg={4} key={item.id}>
                                    <ItemRelatorio item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Demo>
            </Grid>
        </Layout>
    )
}

export default Relatorios