// import { InputAdornment, TextField } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import { InputAdornment, TextField } from "@mui/material"
// import SearchIcon from '@mui/icons-material/Search'
import { useState } from 'react'
import { useAsyncDebounce } from "react-table"

export const FiltroGlobal = ({ filter, setFilter }) => {
    const [value, setValue] = useState(filter)

    const onChange = useAsyncDebounce(value => {
        setFilter(value || undefined)
    }, 400)

    return (
        <span>
            <TextField
                size="small"
                margin="normal"
                id="pesquisar"
                label="Pesquisar"
                name="pesquisar"
                autoFocus
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    onChange(e.target.value)
                }}
                // InputProps={{
                //     endAdornment: (
                //         <InputAdornment>
                //             <SearchIcon />
                //         </InputAdornment>
                //     ) 

                // }}
            />
        </span>
    )
}