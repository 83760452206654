import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../config/configApi'
import header from '../../middleware/jwtInterceptor'

const initialState = {
    loading: true,
    registros: [],
    registroAtual: {},
    error: '',
}

export const fetchNegociacoes = createAsyncThunk('cadastro/negociacoes', () => {
    const autorizacao = header()
    return api
        .get('negociacoes/lista', autorizacao)
        .then(res => res.data)
})

export const saveNegociacao = createAsyncThunk('cadastro/negociacoes/saveNegociacao', (registro) => {
    const autorizacao = header()
    return api
        .post(`/negociacoes/salvar`, registro, autorizacao)
        .then((res) => res.data)
})

export const deleteNegociacao = createAsyncThunk('cadastro/negociacoes/delete', (registro) => {
    const autorizacao = header()
    return api
        .delete(`/negociacoes/excluir/${registro.id}`, autorizacao)
        .then((res) => res.data)
})

export const slice = createSlice({
    name: 'negociacoes',
    initialState,
    reducers: {
        addRegistro: (state, action) => {
            state.registros.push(action.payload)
        },
        deleteRegistro: (state, action) => {
            state.registros = state.registros.filter((registro) => registro.id !== action.payload.id)
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchNegociacoes.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchNegociacoes.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchNegociacoes.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        builder.addCase(saveNegociacao.pending, state => {
            state.loading = true
        })
        builder.addCase(saveNegociacao.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(saveNegociacao.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })

        builder.addCase(deleteNegociacao.pending, state => {
            state.loading = true
        })
        builder.addCase(deleteNegociacao.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(deleteNegociacao.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })
    }
})

export const { addRegistro, deleteRegistro } = slice.actions
export default slice.reducer

