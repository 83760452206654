import { useState } from 'react'
import { Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Info, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { checarData } from '../../../funcoes/data'

export default function Detalhes(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { projeto } = props

    return (
        <span>
            <span onClick={handleOpen} >
                <Info color='alternativo' sx={{ cursor: 'pointer' }} />
            </span>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                    className="dialog-title"
                >
                    Informações completas da projeto
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <Close sx={{ color: 'white' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 4 }}>
                    <Box rowSpacing={1}>
                        <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px", mt: '10px' }}>
                            <Grid container columnSpacing={1} >
                                <Grid item xs={4}>Identificador</Grid><Grid item xs={8}>{projeto.id}</Grid>
                                <Grid item xs={4}>Nome</Grid><Grid item xs={8}>{projeto.nome}</Grid>
                                <Grid item xs={4}>Descrição</Grid><Grid item xs={8}>{projeto.descricao}</Grid>
                                <Grid item xs={4}>Cliente</Grid><Grid item xs={8}>{projeto.cliente_id} - {projeto.cliente.nome}</Grid>
                                <Grid item xs={4}>Tipo de Projeto</Grid><Grid item xs={8}>{projeto.tipo_projeto_id} - {projeto.tipoProjeto.nome}</Grid>
                                <Grid item xs={4}>Data de Início</Grid><Grid item xs={8}>{checarData(projeto.data_inicio) ? format(new Date(projeto.data_inicio), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Data de Conclusão</Grid><Grid item xs={8}>{checarData(projeto.data_termino) ? format(new Date(projeto.data_termino), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Status</Grid><Grid item xs={8}>{projeto.status_projeto_id} - {projeto?.statusProjeto.nome}</Grid>
                                <Grid item xs={4}>Data de Inclusão</Grid><Grid item xs={8}>{checarData(projeto.created_at) ? format(new Date(projeto.created_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                <Grid item xs={4}>Última Alteração</Grid><Grid item xs={8}>{checarData(projeto.updated_at) ? format(new Date(projeto.updated_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                {
                                    projeto.deleted_at ? (
                                        <>
                                            <Grid item xs={4}>Exclusão</Grid>
                                            <Grid item xs={8}>{checarData(projeto.deleted_at) ? format(new Date(projeto.deleted_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                        </>
                                    ) : null
                                }
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                >
                    <Button variant="outlined" color="secondary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}


