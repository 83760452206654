import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'
const autorizacao = header()

const initialState = {
    loading: true,
    registros: [],
    error: ''
}

export const fetchEnderecos = createAsyncThunk('endereco/fetchEndereco', (pessoa_id) => {
    return api
        .get(`enderecos/${pessoa_id}`, autorizacao)
        .then((res) => res.data)
})

export const saveEndereco = createAsyncThunk('endereco/saveEndereco', (registro) => {
    return api
        .post(`enderecos/salvar`, registro, autorizacao)
        .then((res) => res.data.registro)
})

export const slice = createSlice({
    name: 'enderecos',
    initialState,
    reducers: {
        changeEndereco(state, { payload }){
            return {...state, loading: false, registros: payload}
        },
        addEndereco: (state, action) => {
            state.registros.push(action.payload);
        },
        deleteEndereco: (state, action) => {
            state.registros = state.registros.filter((endereco) => endereco.id !== action.payload.id);
        },
        updateEndereco: (state, action) => {
            // eslint-disable-next-line array-callback-return
            state.registros.map((r) => {
                if(r.id === action.payload.id){
                    return r = action.payload
                }
            })
        },
    },
    extraReducers: builder  => {
        builder.addCase(fetchEnderecos.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchEnderecos.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchEnderecos.rejected, (state, action)=> {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
        builder.addCase(saveEndereco.pending, state => {
            state.loading = true
        })
        builder.addCase(saveEndereco.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(saveEndereco.rejected, (state, action)=> {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
    }
})

export default slice.reducer
export const { changeEndereco, addEndereco, deleteEndereco, updateEndereco } = slice.actions
export const selectEnderecos = state => state.enderecos.registros

//https://www.youtube.com/watch?v=bml92jhF4t8
