import { Box, Divider, IconButton, Popover, Typography } from '@mui/material'
import React from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function PopoverSain(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <IconButton
                color="primary"
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ p:0 }}
            >
                <InfoOutlinedIcon color='success' sx={{ fontSize: '20px', ml: 1}}/>
            </IconButton>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                    maxWidth: '900px'
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box sx={{ px: 1, py:2 }}>
                    <Typography><strong>{props.titulo}</strong></Typography>
                    <Divider/>
                    {props.children}
                </Box>
            </Popover>
        </>
    )
}

export default PopoverSain