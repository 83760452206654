import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import { useState } from 'react'
import FormularioRecebimento from '../../cadastro/recebimentos/Form'
import { Box } from '@mui/system'

export default function AlterarRecebimento(props) {
    
    const { registro, setAtualizado, intervalo } = props

    const [modo] = useState({ status: 'Alterar Recebimento', registro: registro })
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setAtualizado(true)
        setOpen(false)
    };

    return (
        <div>
            <Tooltip title={<Box sx={{ fontSize: '13px', p: 0.5 }}>Alterar Recebimento</Box>} placement="left">
                <IconButton
                    color="primary"
                    onClick={() => { handleOpen() }}
                    size="small"
                >
                    <EditIcon color='success' />
                </IconButton>
            </Tooltip>

            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                    }}
                    className="dialog-title"
                >
                    Alterar Recebimento
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>

                </DialogTitle>
                <Divider />
                <DialogContent sx={{ p: 1 }}>
                    <FormularioRecebimento
                        modo={modo}
                        intervalo={intervalo}
                    />
                </DialogContent>
                <Divider />
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                    }}
                >
                    <Button variant="outlined" color="primary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}