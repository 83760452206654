// https://www.youtube.com/watch?v=83TejqrvwJM&list=PLC3y8-rFHvwiPmFbtzEWjESkqBVDbdgGu&index=35

import Layout from '../../components/layouts'
import FormikContainer from './form/FormikContainer'

function Auxiliar() {
    return (

        <Layout
            titulo="Auxiliar"
            subtitulo="Área de testes e experimentos"
        >
            <FormikContainer />
        </Layout>
    )
}

export default Auxiliar