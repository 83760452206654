import { ViewColumnTwoTone } from '@mui/icons-material'
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Menu } from '@mui/material'
import React, { useState } from 'react'
import { CheckboxInput } from './checkbox'

const ExibirColunasComponents = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => { setAnchorEl(event.currentTarget) }
    const handleClose = () => { setAnchorEl(null) }
    return (
        <div style={{ color: 'black' }}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<ViewColumnTwoTone />}
                color='secondary'
            >
                Exibir

            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <FormGroup sx={{ display: 'flex' }}>
                    <Box display='flex' flexDirection='column' sx={{ p: 2 }}>
                        <FormControlLabel control={<CheckboxInput {...props.getToggleHideAllColumnsProps()} />} label="Mostrar Todas" />
                        {
                            props.allColumns.map(column => (
                                <FormControlLabel key={column.id} control={<Checkbox size="small" {...column.getToggleHiddenProps()} color="primary" />} label={column.Header} />
                            ))
                        }
                    </Box>
                </FormGroup>
            </Menu>
        </div>
    )
}

export default ExibirColunasComponents