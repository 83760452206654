import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'

const initialState = {
    loading: false,
    registros: [],
    registroAtual: {},
    error: ''
}

export const fetchTiposProjeto = createAsyncThunk('tabelas/tiposProjeto', () => {
    const autorizacao = header()
    return api
        .get('cadastro/tiposProjeto', autorizacao)
        .then((res) => res.data.registros.sort((r1, r2) => r1.nome > r2.nome ? 1 : -1))
})

export const salvar = createAsyncThunk('cadastro/tiposProjeto/salvar', (registro) => {
    const autorizacao = header()
    return api
        .post(`cadastro/tiposProjeto/salvar`, registro, autorizacao)
        .then((res) => res.data)
})

export const excluir = createAsyncThunk('cadastro/tiposProjeto/excluir', (id) => {
    const autorizacao = header()
    return api
        .delete(`/cadastro/tiposProjeto/excluir/${id}`, autorizacao)
        .then((res)=> res.data)
})

export const slice = createSlice({
    name: 'tiposProjeto',
    initialState,
    reducers: {
        addRegistro: (state, action) => {
            state.registros.push(action.payload)
        },
        deleteRegistro: (state, action) => {
            state.registros = state.registros.filter((registro) => registro.id !== action.payload)
        },
        resetRegistros: (state, action) => {
            state.registros= []
        },
    },
    extraReducers: (builder) => {
        //Listar
        builder.addCase(fetchTiposProjeto.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchTiposProjeto.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchTiposProjeto.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
        //Salvar
        builder.addCase(salvar.pending, state => {
            state.loading = true
        })
        builder.addCase(salvar.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(salvar.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })
        //Excluir
        builder.addCase(excluir.pending, state => {
            state.loading = true
        })
        builder.addCase(excluir.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(excluir.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })
    }
})

export default slice.reducer
export const { addRegistro, deleteRegistro, resetRegistros } = slice.actions
export const selectTiposProjeto = state => state.tiposProjeto.registros
