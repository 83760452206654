import { format } from "date-fns"
import pt from 'date-fns/locale/pt-BR';
import { checarData } from "../../../funcoes/data"

export default function COLUMNS() {

    return [
        {
            Header: 'Id',
            accessor: 'id',
            Footer: 'Id',
            disableFilters: true,
            sticky: 'left'
        },
        {
            Header: 'Cliente',
            accessor: 'cliente.nome',
            Footer: 'Cliente',
            sticky: 'left'
        },
        {
            Header: 'Projeto',
            accessor: 'nome',
            Footer: 'Projeto',
            sticky: 'left'
        },
        {
            Header: 'Tipo de Projeto',
            accessor: 'tipoProjeto.nome',
            Footer: 'Tipo de Projeto',
            sticky: 'left'
        },
        
        {
            Header: 'Status',
            accessor: 'statusProjeto.nome',
            Footer: 'Status',
            sticky: 'left'
        },
        {
            Header: 'Inicio',
            accessor: 'data_inicio',
            Footer: 'Inicio',
            disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return format(new Date(value || null), 'dd/MM/yyyy', { locale: pt })
                }
            },
        },
        {
            Header: 'Término',
            accessor: 'data_termino',
            Footer: 'Término',
            disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return format(new Date(value || null), 'dd/MM/yyyy', { locale: pt })
                }
            },
        },
    ]
}