import { checarData, dataBRExibir } from "../../../../funcoes/data"

export default function COLUMNS() {

    return [
        {
            Header: 'Id',
            accessor: 'id',
            Footer: 'Id',
            disableFilters: true,
            sticky: 'left',
        },
        {
            Header: 'Nome',
            accessor: 'nome',
            Footer: 'Nome',
            sticky: 'left'
        },
        {
            Header: 'Descrição',
            accessor: 'descricao',
            Footer: 'Descrição',
            sticky: 'left',
        },
        {
            Header: 'Cor Padrão',
            accessor: 'cor_padrao',
            Footer: 'Cor Padrão',
            sticky: 'left',
        },
        {
            Header: 'Cor Fonte',
            accessor: 'cor_fonte',
            Footer: 'Cor Fonte',
            sticky: 'left',
        },
        {
            Header: 'Inclusão',
            accessor: 'created_at',
            Footer: 'Inclusão',
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return dataBRExibir(value)
                }
            },
        },
        {
            Header: 'Alteração',
            accessor: 'updated_at',
            Footer: 'Alteração',
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return dataBRExibir(value)
                }
            },
        },
    ]
}