import React from 'react'
import Layout from '../layouts'
import TipoRecebimento from './TipoRecebimento'

function Dashboard() {
    return (
        <Layout
            titulo="Dashboard"
            subtitulo="Gráficos e dados gerenciais"
        >
            <div>Dashboard</div>
            <TipoRecebimento/>
        </Layout>
        
    )
}

export default Dashboard