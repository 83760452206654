
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { 
    Autocomplete, 
    Box, 
    Chip, 
    Divider, 
    FormControl, 
    FormControlLabel, 
    Grid, 
    IconButton, 
    InputAdornment, 
    MenuItem, 
    Popper, 
    Radio, 
    RadioGroup, 
    TextField, 
    Tooltip, 
    Typography 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/styles'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close'
import { saveNegociacao } from '../../../redux/cadastro/negociacaoSlice';
import { useDispatch } from 'react-redux';
import { adicionarAno, adicionarMes } from '../../../funcoes/data';



const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            "& .MuiAutocomplete-listbox": {
                border: "2px solid black",
                fontSize: 12,
                zIndex: 9999,
                "& li:nth-child(even)": { backgroundColor: '#F2F2F2' },
                "& li:nth-child(odd)": { backgroundColor: '#FFF' },
            },


        },
        menu: (styles) => ({
            ...styles,
            zIndex: 9999,
        }),


    })
)

const CustomPopper = (props) => {
    const classes = useStyles()
    return <Popper sx={{ zIndex: 999 }} {...props} className={classes.root} placement="bottom" />
}





export default function Editar(props) {

    const dispatch = useDispatch()
    

    const {
        registro,
        fornecedores,
        formasPagamento,
        ultimoRecebimento,
        ultimoPagamento,
        tiposPagamento,
        statusNegociacao,
        setAtualizarNegociacoes,
        
    } = props
    const [open, setOpen] = useState(false);
    const [tipoNegociacao, setTipoNegociacao] = useState(registro.tipo_id)
    const [fornecedorId, setFornecedorId] = useState({
        value: registro.fornecedor_id ? registro.fornecedor_id : '',
        label: registro.fornecedor?.nome ? registro.fornecedor?.nome : ''
    })
    const [formaPagamentoId, setFormaPagamentoId] = useState(registro.forma_pagamento_id)
    const [dataPrimeiraParcela, setDataPrimeiraParcela] = useState('')
    const [parcelaAtualizada, setParcelaAtualizada] = useState(true)
    const [valorReferencia, setValorReferencia] = useState('')
    const [qtdeParcelas, setQtdeParcelas] = useState(registro.qtde_parcelas || '')
    const [tipoPagamento, setTipoPagamento] = useState(registro.tipo_pagamento_id || '')
    const [status, setStatus] = useState(registro.status_id || '')
    const [observacoes, setObservacoes] = useState(registro.observacoes || '')
    

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        
        if(parcelaAtualizada){
            if (parseInt(registro.tipo_id) === 1){
                if(formaPagamentoId === 3){
                    setDataPrimeiraParcela(adicionarMes(ultimoRecebimento.data))
                }
                if(formaPagamentoId === 4){
                    setDataPrimeiraParcela(adicionarAno(ultimoRecebimento.data))
                }
            }

            if (parseInt(registro.tipo_id) === 2){
                if(formaPagamentoId === 3){
                    setDataPrimeiraParcela(adicionarMes(ultimoPagamento.data))
                }
                if(formaPagamentoId === 4){
                    setDataPrimeiraParcela(adicionarAno(ultimoPagamento.data))
                }
            }

            //setDataPrimeiraParcela(parseInt(registro.tipo_id) === 1 ? adicionarMes(ultimoRecebimento.data) : adicionarMes(ultimoPagamento.data))
            setValorReferencia(parseInt(registro.tipo_id) === 1 ? parseFloat(ultimoRecebimento.valor).toFixed(2) : parseFloat(ultimoPagamento.valor).toFixed(2))
            setParcelaAtualizada(false)
            if(!dataPrimeiraParcela){
                if(formaPagamentoId === 3){
                    setDataPrimeiraParcela(adicionarMes(registro.data_referencia))
                }
                if(formaPagamentoId === 4){
                    setDataPrimeiraParcela(adicionarAno(registro.data_referencia))
                }
                //setDataPrimeiraParcela(adicionarMes(registro.data_referencia))
            }
            if(!valorReferencia){
                setValorReferencia(parseFloat(registro.valor_referencia).toFixed(2))
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registro, ultimoPagamento, ultimoRecebimento, tipoNegociacao, parcelaAtualizada, dataPrimeiraParcela, valorReferencia])


    const fornecedoresOptions = fornecedores?.map((item) => {
        return {
            value: item.id,
            label: item.nome,
        }
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTipoNegociacao = (e) => {
        setTipoNegociacao(e.target.value)
    }

    const salvarNegociacao = async () => {
        
        let registroSalvar = {
            id: "",
            tipo_id: tipoNegociacao,
            servico_id: registro.servico_id,
            forma_pagamento_id: formaPagamentoId,
            fornecedor_id: fornecedorId.value || null,
            tipo_pagamento_id: tipoPagamento || null,
            valor_referencia: parseFloat(valorReferencia).toFixed(2),
            qtde_parcelas: qtdeParcelas,
            data_referencia: dataPrimeiraParcela,
            data_ultima_Parcela: dataPrimeiraParcela,
            observacoes: observacoes || null,
            status_id: status,
        }
        await dispatch(saveNegociacao(registroSalvar))
        setAtualizarNegociacoes(true)
        setOpen(false)
    }

    return (
        <div>
            <IconButton onClick={handleClickOpen} >
                <EditIcon color='success' />
            </IconButton>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                scroll={'paper'}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                sx={{
                    '& .MuiDialogTitle-root': {
                        padding: theme.spacing(2),
                        backgroundColor: '#FAE10B',
                    },
                }}
            >
                <DialogTitle
                    id="responsive-dialog-title"

                >
                    {"Alterar Negociação"}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <Divider></Divider>
                <DialogContent sx={{ height: '100%' }}>

                    <Typography variant="body1" gutterBottom>
                        Atualize os dados na negociação para criação automática
                        das parcelas.
                    </Typography>

                    <Grid container display='flex' flexDirection='column'>

                        <FormControl
                            sx={{ minWidth: '100%', align: 'center' }}
                            variant='outlined'
                        >
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={tipoNegociacao}
                                defaultChecked={registro.tipo_id}
                                onChange={(e) => handleTipoNegociacao(e)}
                                sx={{ display: 'flex', justifyContent: 'center', mb: '10px' }}


                            >
                                <FormControlLabel value={1} control={<Radio size='small' />} label={<span style={{ fontSize: '0.9rem' }}>Recebimentos (entradas)</span>} />
                                <FormControlLabel value={2} control={<Radio size='small' />} label={<span style={{ fontSize: '0.9rem' }}>Pagamentos (Saídas)</span>} />
                            </RadioGroup>

                            <Divider>
                                <Chip label="Atualize os dados da negociação" />
                            </Divider>

                            <Grid
                                container
                                display='flex'
                                flexDirection='row'
                                justifyContent={'space-between'}
                                spacing={2}
                                mt={2}
                            >
                                {parseInt(tipoNegociacao) === 2 ? (
                                    <Grid item xs={12} md={6} >
                                        <Autocomplete
                                            disablePortal
                                            id="fornecedor_id"
                                            name="fornecedor_id"
                                            size="small"
                                            noOptionsText={'Nenhuma opção disponível'}
                                            options={fornecedoresOptions}
                                            value={fornecedorId}
                                            isOptionEqualToValue={(option) => option = fornecedorId}
                                            getOptionLabel={(option) => option.label || ''}
                                            onChange={(event, newValue, option) => {
                                                setFornecedorId(newValue)
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    required
                                                    label="Fornecedor"
                                                    value={fornecedorId}
                                                />
                                            )}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props} key={option.value}>
                                                    {`${option.label} (${option.value})`}
                                                </Box>
                                            )}
                                            PopperComponent={CustomPopper}
                                        />
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        size="small"
                                        id="forma_pagamento_id"
                                        select
                                        label="Forma de pagamento"
                                        value={formaPagamentoId}
                                        defaultValue={formaPagamentoId}
                                        onChange={(e) => setFormaPagamentoId(e.target.value)}
                                        fullWidth
                                    >
                                        {formasPagamento.map((option) => {
                                            return (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.nome}
                                                </MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        type='date'
                                        id="data_referencia"
                                        name="data_referencia"
                                        label="Data próxima parcela"
                                        fullWidth
                                        size="small"
                                        value={dataPrimeiraParcela}
                                        InputLabelProps={{ shrink: true, }}
                                        onChange={(e) => setDataPrimeiraParcela(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        size='small'
                                        id='numero_total_parcelas'
                                        name='numero_total_parcelas'
                                        label='Quantidade de Parcelas'
                                        type='number'
                                        fullWidth
                                        value={qtdeParcelas}
                                        onChange={(e) => setQtdeParcelas(e.target.value)}
                                    >
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6} >
                                    <TextField
                                        size='small'
                                        id='valor_referencia'
                                        name='valor_referencia'
                                        label='Valor da Parcela'
                                        type='number'
                                        fullWidth
                                        value={valorReferencia}
                                        onChange={(e) => setValorReferencia(e.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        }}
                                    >
                                    </TextField>
                                </Grid>
                                {parseInt(tipoNegociacao) === 2 ? (
                                    <Grid item xs={12} md={6} >
                                        <TextField
                                            size="small"
                                            id="tipo_pagamento_id"
                                            select
                                            label="Tipo de Pagamento"
                                            value={tipoPagamento}
                                            defaultValue={tipoPagamento}
                                            onChange={(e) => setTipoPagamento(e.target.value)}
                                            fullWidth
                                        >
                                            {tiposPagamento.map((option) => {
                                                return (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.nome}
                                                    </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} md={6} >
                                    <Tooltip title="Delete">
                                        <TextField
                                            size="small"
                                            id="status_id"
                                            select
                                            label="Status da Negociação"
                                            value={status}
                                            defaultValue={status}
                                            onChange={(e) => setStatus(e.target.value)}
                                            fullWidth
                                        >
                                            {statusNegociacao.map((option) => {

                                                return (
                                                    <MenuItem key={option.id} value={option.id || ''} >
                                                        {option.nome}
                                                    </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        size='small'
                                        id='observacoes'
                                        name='observacoes'
                                        label='Observações'
                                        type='text'
                                        fullWidth
                                        value={observacoes}
                                        onChange={(e) => setObservacoes(e.target.value)}
                                        multiline
                                        minRows={3}
                                    >
                                    </TextField>
                                </Grid>

                            </Grid>
                        </FormControl>
                    </Grid>
                </DialogContent>
                <Divider></Divider>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={salvarNegociacao}
                        startIcon={<SaveIcon />}
                    >
                        <strong>Salvar</strong>
                    </Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleClose} autoFocus
                        startIcon={<CancelIcon fontSize="small" />}
                    >
                        <strong>Cancelar</strong>
                    </Button>

                </DialogActions>
            </Dialog>
        </div>
    );
}

