import React from 'react'
import Input from './Input'
import Textarea from './Textarea'
import SelectOptions from './Select'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import DatePicker from './DatePicker'

function FormikControl(props) {

    const { control, ...rest } = props

    switch (control) {
        case 'input': return <Input {...rest} sx={{ m: 1 }} />
        case 'textarea': return <Textarea {...rest} sx={{ m: 1 }} />
        case 'select': return <SelectOptions {...rest} />
        case 'radio': return <RadioButtons {...rest} />
        case 'checkbox': return <CheckboxGroup {...rest} />
        case 'date': return <DatePicker {...rest} />
        default: return null
    }
}

export default FormikControl