import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { savePessoaJuridica } from '../../../redux/cadastro/pessoa/pessoaJuridicaSlice'
import { selectPessoaAtual } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { changeAbasPessoa, selectAbasPessoa } from '../../../redux/auxiliar/abasPessoaSlice'
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FormDadosPJ = (props) => {

    const dispatch = useDispatch()
    const pessoaAtual = useSelector(selectPessoaAtual)

    const abasPessoa = useSelector(selectAbasPessoa)
    let abasPessoaCopia = JSON.parse(JSON.stringify(abasPessoa));

    let initialValues = {
        id: '',
        pessoa_id: pessoaAtual?.id,
        cnpj: '',
        razao_social: '',
        inscricao_municipal: '',
        inscricao_estadual: '',
        inscricao_estadual_substituicao_tributaria: '',
        contato_empresa: '',
    }

    if (pessoaAtual?.pessoaJuridica) {
        initialValues = {
            id: pessoaAtual?.pessoaJuridica?.id || '',
            pessoa_id: pessoaAtual?.pessoaJuridica?.pessoa_id || pessoaAtual?.id,
            cnpj: pessoaAtual?.pessoaJuridica?.cnpj || '',
            razao_social: pessoaAtual?.pessoaJuridica?.razao_social || '',
            inscricao_municipal: pessoaAtual?.pessoaJuridica?.inscricao_municipal || '',
            inscricao_estadual: pessoaAtual?.pessoaJuridica?.inscricao_estadual || '',
            inscricao_estadual_substituicao_tributaria: pessoaAtual?.pessoaJuridica?.inscricao_estadual_substituicao_tributaria || '',
            contato_empresa: pessoaAtual?.pessoaJuridica?.contato_empresa || '',
        }
    }
    
    const validationSchema = Yup.object({
        cnpj: Yup
            .string()
            .min(13, 'Mínimo de 11 carateres')
            .max(18, 'Máximo de 15 carateres')
            .required('Informe o número do CNPJ'),
        razao_social: Yup
            .string()
            .required('A razão social precisa ser informada'),
    })

    const onSubmit = async (values) => {
        await dispatch(savePessoaJuridica(values))
        props.setAlterado(true)
        abasPessoaCopia.status.enderecos = false
        dispatch(changeAbasPessoa(abasPessoaCopia.status))
        props.setValue(3)
    }

    return (
        <Grid container justify="center" flexDirection='column' spacing={1} >
            <Grid item sx={{ width: '100%' }}>
                <Card sx={{ width: '100%' }}>
                    <CardHeader title="Dados da pessoa jurídica" subtitle={props.value}></CardHeader>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {
                            ({
                                values,
                                isSubmitting,
                                isValid,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                touched,
                                errors,
                                setFieldValue
                            }) => {
                                return (
                                    <Form>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    gap: '15px',
                                                }}
                                            >
                                                <Field
                                                    size='small'
                                                    label="CNPJ"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="cnpj"
                                                    id='cnpj'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cnpj}
                                                />

                                                <Field
                                                    size='small'
                                                    label="Razão Social"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="razao_social"
                                                    id='razao_social'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.razao_social}
                                                />

                                                <Field
                                                    size='small'
                                                    label="Inscrição municipal"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="inscricao_municipal"
                                                    id='inscricao_municipal'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.inscricao_municipal}
                                                />

                                                <Field
                                                    size='small'
                                                    label="Inscrição estadual"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="inscricao_estadual"
                                                    id='inscricao_estadual'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.inscricao_estadual}
                                                />

                                                <Field
                                                    size='small'
                                                    label="Inscrição estadual para substituição tributária"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="inscricao_estadual_substituicao_tributaria"
                                                    id='inscricao_estadual_substituicao_tributaria'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.inscricao_estadual_substituicao_tributaria}
                                                />

                                                <Field
                                                    size='small'
                                                    label="Pessoa de contato na empresa"
                                                    variant="outlined"
                                                    fullWidth
                                                    name="contato_empresa"
                                                    id='contato_empresa'
                                                    component={TextField}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.contato_empresa}
                                                />

                                            </Box>
                                        </CardContent>
                                        <CardActions sx={{ ml: 1 }}>
                                            <Button
                                                variant="contained"
                                                type='submit'
                                                disabled={!isValid || isSubmitting}
                                                startIcon={<SaveIcon/>}
                                            >
                                                Salvar
                                            </Button>
                                            {
                                                props.modo !== 'edicao' ? (
                                                    <Button
                                                        type='reset'
                                                        variant="contained"
                                                        color="secondary"
                                                        startIcon={<ClearIcon/>}
                                                    >
                                                        Limpar
                                                    </Button>
                                                ) : null
                                            }
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                type="reset"
                                                onClick={() => props.setModo('table')}
                                                startIcon={<ArrowBackIcon/>}
                                            >
                                                VOLTAR
                                            </Button>

                                        </CardActions>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </Card>
            </Grid>
        </Grid>
    )
}

export default FormDadosPJ