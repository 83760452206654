import { AddCommentTwoTone, DeleteTwoTone } from '@mui/icons-material'
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem } from '@mui/material'
import { Field, FieldArray, Form, Formik } from 'formik'
import { Select, TextField } from 'formik-mui'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { selectPessoaAtual, fetchPessoa } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchTelefones, saveTelefones } from '../../../redux/cadastro/pessoa/TelefonesSlice'
import { fetchTiposTelefone, selectTiposTelefone } from '../../../redux/cadastro/tabelas/tiposTelefoneSlice'
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const FormTelefones = (props) => {

    const dispatch = useDispatch()
    const pessoaAtual = useSelector(selectPessoaAtual)

    useEffect(() => {
        dispatch(fetchPessoa(pessoaAtual?.id))
        dispatch(fetchTiposTelefone())
        
    }, [dispatch, pessoaAtual?.id])

    const tiposTelefone = useSelector(selectTiposTelefone)

    let initialValues = {}
    if (pessoaAtual?.telefones?.length > 0) {
        initialValues = {
            telefones: pessoaAtual?.telefones
        }
    } else {
        initialValues = {
            telefones: [{
                id: '',
                pessoa_id: pessoaAtual?.id,
                tipo_telefone_id: '',
                pais_codigo: '',
                ddd_codigo: '',
                numero_telefone: '',
            }]
        }
    }

    const validationSchema = Yup.object({
        telefones: Yup.array().of(
            Yup.object({
                tipo_telefone_id: Yup
                    .string()
                    .required('Informe o tipo de telefone'),
                ddd_codigo: Yup
                    .string()
                    .required('Informe o DDD'),
                numero_telefone: Yup
                    .string()
                    .required('Informe o número'),
            })
        )
    })

    const tiposTelefoneOptions = tiposTelefone.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })

    const onSubmit = (values) => {
        const listaTelefones = values.telefones
        listaTelefones.forEach(element =>{
            dispatch(saveTelefones(element))
        })

        dispatch(fetchTelefones(pessoaAtual.id))
        //dispatch(fetchPessoa(''))
        props.setAlterado(true)
        props.setValue(0)
    }


    return (
        <Grid container justify="center" flexDirection='column' spacing={1} >
            <Grid item sx={{ width: '100%' }}>
                <Card sx={{ width: '100%' }}>
                    <CardHeader title="Telefones" subtitle={props.value}></CardHeader>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {
                            ({
                                values,
                                isValid,
                                isSubmitting,

                            }) => {
                                return (
                                    <Form>
                                        <CardContent>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    gap: '15px',
                                                }}
                                            >
                                                <FieldArray name='telefones'>
                                                    {
                                                        (fieldArrayProps) => {
                                                            const { push, remove, form } = fieldArrayProps
                                                            const { values, handleBlur, handleChange, touched, errors } = form
                                                            const { telefones } = values
                                                            return (
                                                                <>
                                                                    {
                                                                        telefones && telefones.length > 0 ? (
                                                                            telefones.map((telefone, index) => {
                                                                                return (
                                                                                    <Box key={index}>
                                                                                        <Grid
                                                                                            container
                                                                                            flexDirection='row'
                                                                                            spacing={1}
                                                                                        >
                                                                                            <Grid item sx={{ mt: -1 }}>
                                                                                                <Field
                                                                                                    label="Tipo de telefone"
                                                                                                    component={Select}
                                                                                                    size="small"
                                                                                                    name={`telefones[${index}].tipo_telefone_id`}
                                                                                                    id={`telefones[${index}].tipo_telefone_id`}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    sx={{ mt: 1, width: '222px' }}
                                                                                                    value={telefones?.[index]?.tipo_telefone_id || ''}
                                                                                                    defaultValue={telefones?.[index]?.tipo_telefone_id || ''}
                                                                                                >
                                                                                                    {
                                                                                                        tiposTelefoneOptions.map(option => {
                                                                                                            return (
                                                                                                                <MenuItem key={option.value} value={option.value}>
                                                                                                                    {option.label}
                                                                                                                </MenuItem>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </Field>
                                                                                            </Grid>

                                                                                            <Grid item>
                                                                                                <Field
                                                                                                    size='small'
                                                                                                    label="Código do país "
                                                                                                    name={`telefones[${index}].pais_codigo`}
                                                                                                    id={`telefones[${index}].pais_codigo`}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    value={telefones?.[index]?.pais_codigo || ''}
                                                                                                    component={TextField}
                                                                                                    error={Boolean(touched.telefones?.[index]?.pais_codigo) && Boolean(errors.telefones?.[index]?.pais_codigo)}
                                                                                                    helperText={touched.telefones?.[index]?.pais_codigo && errors.telefones?.[index]?.pais_codigo}
                                                                                                />
                                                                                            </Grid>
                                                                                            
                                                                                            <Grid item>
                                                                                                <Field
                                                                                                    size='small'
                                                                                                    label="DDD"
                                                                                                    name={`telefones[${index}].ddd_codigo`}
                                                                                                    id={`telefones[${index}].ddd_codigo`}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    value={telefones?.[index]?.ddd_codigo || ''}
                                                                                                    component={TextField}
                                                                                                    error={Boolean(touched.telefones?.[index]?.ddd_codigo) && Boolean(errors.telefones?.[index]?.ddd_codigo)}
                                                                                                    helperText={touched.telefones?.[index]?.ddd_codigo && errors.telefones?.[index]?.ddd_codigo}
                                                                                                />
                                                                                            </Grid>
                                                                                            
                                                                                            <Grid item>
                                                                                                <Field
                                                                                                    size='small'
                                                                                                    label="Número"
                                                                                                    name={`telefones[${index}].numero_telefone`}
                                                                                                    id={`telefones[${index}].numero_telefone`}
                                                                                                    onChange={handleChange}
                                                                                                    onBlur={handleBlur}
                                                                                                    value={telefones?.[index]?.numero_telefone || ''}
                                                                                                    component={TextField}
                                                                                                    error={Boolean(touched.telefones?.[index]?.numero_telefone) && Boolean(errors.telefones?.[index]?.numero_telefone)}
                                                                                                    helperText={touched.telefones?.[index]?.numero_telefone && errors.telefones?.[index]?.numero_telefone}
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                        <Grid container spacing={2} sx={{ m: 2, ml: 0 }}>
                                                                                                <Button
                                                                                                    type='button'
                                                                                                    onClick={() => push(
                                                                                                        {
                                                                                                            id: '',
                                                                                                            pessoa_id: pessoaAtual.id
                                                                                                        })}
                                                                                                ><AddCommentTwoTone /></Button>
                                                                                                {
                                                                                                    index > 0 && (
                                                                                                        <Button
                                                                                                            type='button'
                                                                                                            onClick={() => remove(index)}
                                                                                                        ><DeleteTwoTone /></Button>
                                                                                                    )
                                                                                                }
                                                                                            </Grid>

                                                                                    </Box>
                                                                                )
                                                                            })
                                                                        ) : null
                                                                    }
                                                                </>
                                                            )
                                                        }
                                                    }
                                                </FieldArray>
                                            </Box>
                                        </CardContent>
                                        <CardActions sx={{ ml: 1 }}>
                                            <Button
                                                variant="contained"
                                                type='submit'
                                                disabled={!isValid || isSubmitting}
                                                startIcon={<SaveIcon/>}
                                            >
                                                Salvar
                                            </Button>
                                            {
                                                props.modo !== 'edicao' ? (
                                                    <Button
                                                        type='reset'
                                                        variant="contained"
                                                        color="secondary"
                                                        startIcon={<ClearIcon/>}
                                                    >
                                                        Limpar
                                                    </Button>
                                                ) : null
                                            }
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                type="reset"
                                                onClick={() => props.setModo('table')}
                                                startIcon={<ArrowBackIcon/>}
                                            >
                                                VOLTAR
                                            </Button>

                                        </CardActions>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
                </Card>
            </Grid>
        </Grid>
    )
}

export default FormTelefones