import { Chart } from "react-google-charts";


export function TipoRecebimento() {

    const data = [
        {
            "ano_mes": "2017/09",
            "tipo_servico": "Artes",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2017/09",
            "tipo_servico": "Hospedagem",
            "qtde": 1,
            "valor": "120.00"
        },
        {
            "ano_mes": "2017/09",
            "tipo_servico": "Investimento",
            "qtde": 1,
            "valor": "2500.00"
        },
        {
            "ano_mes": "2017/09",
            "tipo_servico": "Site",
            "qtde": 3,
            "valor": "1150.00"
        },
        {
            "ano_mes": "2017/10",
            "tipo_servico": "Hospedagem",
            "qtde": 1,
            "valor": "220.00"
        },
        {
            "ano_mes": "2017/10",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "150.00"
        },
        {
            "ano_mes": "2017/10",
            "tipo_servico": "Manutenção",
            "qtde": 1,
            "valor": "312.00"
        },
        {
            "ano_mes": "2017/10",
            "tipo_servico": "Marketing",
            "qtde": 2,
            "valor": "1017.00"
        },
        {
            "ano_mes": "2017/10",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "1705.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Artes",
            "qtde": 1,
            "valor": "160.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Hospedagem",
            "qtde": 2,
            "valor": "440.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Logotipos",
            "qtde": 4,
            "valor": "950.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Marketing",
            "qtde": 2,
            "valor": "1037.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "330.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Site",
            "qtde": 8,
            "valor": "2225.00"
        },
        {
            "ano_mes": "2017/11",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "190.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Artes",
            "qtde": 1,
            "valor": "100.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Hospedagem",
            "qtde": 2,
            "valor": "728.56"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Impressão",
            "qtde": 3,
            "valor": "1747.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Marketing",
            "qtde": 2,
            "valor": "997.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "330.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Site",
            "qtde": 7,
            "valor": "1925.00"
        },
        {
            "ano_mes": "2017/12",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "190.00"
        },
        {
            "ano_mes": "2018/01",
            "tipo_servico": "Artes",
            "qtde": 3,
            "valor": "97.00"
        },
        {
            "ano_mes": "2018/01",
            "tipo_servico": "Hospedagem",
            "qtde": 2,
            "valor": "520.00"
        },
        {
            "ano_mes": "2018/01",
            "tipo_servico": "Impressão",
            "qtde": 1,
            "valor": "100.00"
        },
        {
            "ano_mes": "2018/01",
            "tipo_servico": "Marketing",
            "qtde": 2,
            "valor": "1017.00"
        },
        {
            "ano_mes": "2018/01",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "1625.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "819.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Hospedagem",
            "qtde": 4,
            "valor": "970.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "190.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Marketing",
            "qtde": 4,
            "valor": "1214.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "2575.00"
        },
        {
            "ano_mes": "2018/02",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "380.00"
        },
        {
            "ano_mes": "2018/03",
            "tipo_servico": "Impressão",
            "qtde": 2,
            "valor": "687.20"
        },
        {
            "ano_mes": "2018/03",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "430.00"
        },
        {
            "ano_mes": "2018/03",
            "tipo_servico": "Marketing",
            "qtde": 4,
            "valor": "1564.00"
        },
        {
            "ano_mes": "2018/03",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "80.00"
        },
        {
            "ano_mes": "2018/03",
            "tipo_servico": "Site",
            "qtde": 5,
            "valor": "1995.00"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "280.00"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Hospedagem",
            "qtde": 4,
            "valor": "1713.24"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Impressão",
            "qtde": 2,
            "valor": "687.20"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "610.00"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Marketing",
            "qtde": 3,
            "valor": "1414.00"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "2195.33"
        },
        {
            "ano_mes": "2018/04",
            "tipo_servico": "Site",
            "qtde": 4,
            "valor": "2375.00"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "640.00"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Hospedagem",
            "qtde": 9,
            "valor": "2599.44"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Impressão",
            "qtde": 1,
            "valor": "394.70"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "630.00"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Marketing",
            "qtde": 4,
            "valor": "1514.00"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "1733.33"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "2245.00"
        },
        {
            "ano_mes": "2018/05",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "2000.00"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "6.72"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Hospedagem",
            "qtde": 2,
            "valor": "643.24"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Impressão",
            "qtde": 3,
            "valor": "3720.00"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "1332.00"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Marketing",
            "qtde": 5,
            "valor": "1994.00"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Sistemas",
            "qtde": 3,
            "valor": "3373.34"
        },
        {
            "ano_mes": "2018/06",
            "tipo_servico": "Site",
            "qtde": 8,
            "valor": "3645.00"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "45.03"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "1900.00"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Hospedagem",
            "qtde": 1,
            "valor": "320.00"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "1392.00"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Marketing",
            "qtde": 9,
            "valor": "3135.14"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "1496.00"
        },
        {
            "ano_mes": "2018/07",
            "tipo_servico": "Site",
            "qtde": 7,
            "valor": "4828.73"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "3.08"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "1900.00"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "1594.60"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "1312.00"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Marketing",
            "qtde": 8,
            "valor": "2754.00"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Site",
            "qtde": 5,
            "valor": "3790.00"
        },
        {
            "ano_mes": "2018/08",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "550.00"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Administração",
            "qtde": 2,
            "valor": "5.20"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Artes",
            "qtde": 8,
            "valor": "15188.00"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "1436.20"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Impressão",
            "qtde": 1,
            "valor": "145.00"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1446.00"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Marketing",
            "qtde": 8,
            "valor": "2814.00"
        },
        {
            "ano_mes": "2018/09",
            "tipo_servico": "Site",
            "qtde": 5,
            "valor": "3190.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "34.29"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "4890.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Hospedagem",
            "qtde": 5,
            "valor": "1102.96"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "1112.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Marketing",
            "qtde": 4,
            "valor": "2254.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "1824.00"
        },
        {
            "ano_mes": "2018/10",
            "tipo_servico": "Site",
            "qtde": 1,
            "valor": "320.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "29.88"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Artes",
            "qtde": 7,
            "valor": "3860.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Hospedagem",
            "qtde": 2,
            "valor": "446.48"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Impressão",
            "qtde": 2,
            "valor": "220.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Impulsionamento",
            "qtde": 2,
            "valor": "100.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "584.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Marketing",
            "qtde": 5,
            "valor": "2088.52"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "2440.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Site",
            "qtde": 7,
            "valor": "3200.00"
        },
        {
            "ano_mes": "2018/11",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "2000.00"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "0.70"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Artes",
            "qtde": 1,
            "valor": "1400.00"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Hospedagem",
            "qtde": 3,
            "valor": "865.89"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Impressão",
            "qtde": 3,
            "valor": "1595.00"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Logotipos",
            "qtde": 4,
            "valor": "2130.76"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Marketing",
            "qtde": 8,
            "valor": "3275.44"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "1120.00"
        },
        {
            "ano_mes": "2018/12",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "1100.00"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "33.45"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Artes",
            "qtde": 1,
            "valor": "160.00"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Hospedagem",
            "qtde": 3,
            "valor": "530.10"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Impressão",
            "qtde": 2,
            "valor": "1239.95"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Impulsionamento",
            "qtde": 3,
            "valor": "70.79"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "584.00"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Manutenção",
            "qtde": 1,
            "valor": "0.02"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Marketing",
            "qtde": 9,
            "valor": "5916.49"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "3091.00"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Site",
            "qtde": 1,
            "valor": "1120.00"
        },
        {
            "ano_mes": "2019/01",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "400.00"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "0.70"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "580.00"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Hospedagem",
            "qtde": 7,
            "valor": "1669.28"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Impressão",
            "qtde": 3,
            "valor": "2299.95"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Impulsionamento",
            "qtde": 7,
            "valor": "261.49"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "804.00"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Marketing",
            "qtde": 10,
            "valor": "6379.59"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Sistemas",
            "qtde": 3,
            "valor": "4170.00"
        },
        {
            "ano_mes": "2019/02",
            "tipo_servico": "Site",
            "qtde": 3,
            "valor": "815.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "29.96"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Artes",
            "qtde": 3,
            "valor": "440.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Hospedagem",
            "qtde": 5,
            "valor": "1141.11"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Impressão",
            "qtde": 2,
            "valor": "1295.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Impulsionamento",
            "qtde": 5,
            "valor": "140.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1434.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Manutenção",
            "qtde": 1,
            "valor": "0.70"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Marketing",
            "qtde": 13,
            "valor": "7488.18"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Sistemas",
            "qtde": 7,
            "valor": "6417.00"
        },
        {
            "ano_mes": "2019/03",
            "tipo_servico": "Site",
            "qtde": 1,
            "valor": "300.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "5.81"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Artes",
            "qtde": 4,
            "valor": "2976.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "233.53"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Hospedagem",
            "qtde": 5,
            "valor": "1578.83"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Impressão",
            "qtde": 9,
            "valor": "4097.54"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Impulsionamento",
            "qtde": 4,
            "valor": "149.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Logotipos",
            "qtde": 6,
            "valor": "2952.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Marketing",
            "qtde": 16,
            "valor": "8414.73"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Sistemas",
            "qtde": 4,
            "valor": "6373.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Site",
            "qtde": 4,
            "valor": "1400.00"
        },
        {
            "ano_mes": "2019/04",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "2750.00"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "18.54"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Artes",
            "qtde": 3,
            "valor": "700.00"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Filmagem",
            "qtde": 1,
            "valor": "5500.00"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Hospedagem",
            "qtde": 7,
            "valor": "2175.63"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Impressão",
            "qtde": 12,
            "valor": "7924.80"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Impulsionamento",
            "qtde": 1,
            "valor": "159.90"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1542.00"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Marketing",
            "qtde": 18,
            "valor": "9065.17"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "400.00"
        },
        {
            "ano_mes": "2019/05",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "5600.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "1.05"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "2740.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Hospedagem",
            "qtde": 7,
            "valor": "2250.30"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Impressão",
            "qtde": 12,
            "valor": "6827.62"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Impulsionamento",
            "qtde": 5,
            "valor": "190.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "1292.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Marketing",
            "qtde": 20,
            "valor": "10255.54"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "3451.00"
        },
        {
            "ano_mes": "2019/06",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "9060.00"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Artes",
            "qtde": 2,
            "valor": "160.00"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "1.05"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Impressão",
            "qtde": 5,
            "valor": "6270.00"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Impulsionamento",
            "qtde": 8,
            "valor": "215.93"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "1292.00"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Marketing",
            "qtde": 19,
            "valor": "9657.87"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Sistemas",
            "qtde": 4,
            "valor": "2800.00"
        },
        {
            "ano_mes": "2019/07",
            "tipo_servico": "Site",
            "qtde": 8,
            "valor": "6400.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "1230.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "0.70"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Hospedagem",
            "qtde": 5,
            "valor": "1100.59"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Impressão",
            "qtde": 6,
            "valor": "1641.05"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Impulsionamento",
            "qtde": 6,
            "valor": "435.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "652.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Manutenção",
            "qtde": 2,
            "valor": "280.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Marketing",
            "qtde": 23,
            "valor": "12447.31"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Site",
            "qtde": 9,
            "valor": "7290.00"
        },
        {
            "ano_mes": "2019/08",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "2500.00"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "5.08"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Artes",
            "qtde": 10,
            "valor": "2570.00"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "1.75"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Drone",
            "qtde": 1,
            "valor": "300.00"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Hospedagem",
            "qtde": 4,
            "valor": "934.12"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Impressão",
            "qtde": 7,
            "valor": "4548.80"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Impulsionamento",
            "qtde": 5,
            "valor": "330.00"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Marketing",
            "qtde": 25,
            "valor": "13096.66"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Sistemas",
            "qtde": 5,
            "valor": "5062.20"
        },
        {
            "ano_mes": "2019/09",
            "tipo_servico": "Site",
            "qtde": 4,
            "valor": "2050.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "1.05"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "750.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Drone",
            "qtde": 1,
            "valor": "450.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "1401.18"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Impressão",
            "qtde": 5,
            "valor": "3347.85"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Impulsionamento",
            "qtde": 9,
            "valor": "563.18"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1040.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Marketing",
            "qtde": 22,
            "valor": "13718.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Site",
            "qtde": 4,
            "valor": "1740.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Suporte",
            "qtde": 1,
            "valor": "4500.00"
        },
        {
            "ano_mes": "2019/10",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "720.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "1.05"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Artes",
            "qtde": 7,
            "valor": "1030.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "1858.85"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Impressão",
            "qtde": 11,
            "valor": "3362.35"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Impulsionamento",
            "qtde": 7,
            "valor": "510.99"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Logotipos",
            "qtde": 4,
            "valor": "3800.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Marketing",
            "qtde": 23,
            "valor": "17078.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Reembolso",
            "qtde": 1,
            "valor": "613.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Site",
            "qtde": 5,
            "valor": "1950.00"
        },
        {
            "ano_mes": "2019/11",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "1470.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Administração",
            "qtde": 1,
            "valor": "2.45"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Artes",
            "qtde": 3,
            "valor": "620.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "18.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Hospedagem",
            "qtde": 1,
            "valor": "439.12"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Impressão",
            "qtde": 8,
            "valor": "6262.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Impulsionamento",
            "qtde": 8,
            "valor": "539.38"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "750.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Marketing",
            "qtde": 24,
            "valor": "14838.00"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Site",
            "qtde": 5,
            "valor": "2062.29"
        },
        {
            "ano_mes": "2019/12",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "1220.00"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "4400.00"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "2.45"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Hospedagem",
            "qtde": 3,
            "valor": "636.51"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Impressão",
            "qtde": 4,
            "valor": "572.92"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Impulsionamento",
            "qtde": 6,
            "valor": "536.25"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Logotipos",
            "qtde": 7,
            "valor": "3545.33"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Marketing",
            "qtde": 24,
            "valor": "14708.00"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Sistemas",
            "qtde": 2,
            "valor": "1655.00"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Site",
            "qtde": 4,
            "valor": "1723.29"
        },
        {
            "ano_mes": "2020/01",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "1220.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Artes",
            "qtde": 3,
            "valor": "652.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Drone",
            "qtde": 1,
            "valor": "490.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "2110.17"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Impressão",
            "qtde": 6,
            "valor": "1625.88"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Impulsionamento",
            "qtde": 7,
            "valor": "873.01"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1100.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Marketing",
            "qtde": 23,
            "valor": "14009.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Reembolso",
            "qtde": 2,
            "valor": "1.82"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "2250.00"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Site",
            "qtde": 6,
            "valor": "2113.29"
        },
        {
            "ano_mes": "2020/02",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "720.00"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Artes",
            "qtde": 4,
            "valor": "1892.00"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "13.54"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Hospedagem",
            "qtde": 4,
            "valor": "1142.89"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Impressão",
            "qtde": 5,
            "valor": "525.82"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Impulsionamento",
            "qtde": 5,
            "valor": "389.15"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Logotipos",
            "qtde": 2,
            "valor": "215.00"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Marketing",
            "qtde": 22,
            "valor": "13735.00"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Reembolso",
            "qtde": 1,
            "valor": "1.40"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Site",
            "qtde": 11,
            "valor": "6618.29"
        },
        {
            "ano_mes": "2020/03",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "350.00"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Artes",
            "qtde": 7,
            "valor": "1361.00"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "13.27"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Domínios",
            "qtde": 2,
            "valor": "159.73"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Hospedagem",
            "qtde": 6,
            "valor": "1851.74"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Impressão",
            "qtde": 6,
            "valor": "2498.99"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Impulsionamento",
            "qtde": 4,
            "valor": "280.81"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Logotipos",
            "qtde": 5,
            "valor": "1294.00"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Marketing",
            "qtde": 21,
            "valor": "12335.00"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Site",
            "qtde": 7,
            "valor": "3690.00"
        },
        {
            "ano_mes": "2020/04",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "200.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Artes",
            "qtde": 14,
            "valor": "1387.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "10.91"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Hospedagem",
            "qtde": 8,
            "valor": "2737.90"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Impressão",
            "qtde": 6,
            "valor": "901.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Impulsionamento",
            "qtde": 7,
            "valor": "401.85"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "INPI",
            "qtde": 1,
            "valor": "300.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Logotipos",
            "qtde": 4,
            "valor": "1514.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Marketing",
            "qtde": 19,
            "valor": "10880.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Sistemas",
            "qtde": 5,
            "valor": "6905.00"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Site",
            "qtde": 10,
            "valor": "3515.86"
        },
        {
            "ano_mes": "2020/05",
            "tipo_servico": "Video",
            "qtde": 1,
            "valor": "100.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Artes",
            "qtde": 10,
            "valor": "1079.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "10.13"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Drone",
            "qtde": 3,
            "valor": "1500.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Hospedagem",
            "qtde": 8,
            "valor": "2572.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Impressão",
            "qtde": 10,
            "valor": "2549.82"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Impulsionamento",
            "qtde": 11,
            "valor": "926.25"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1069.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Marketing",
            "qtde": 21,
            "valor": "12785.00"
        },
        {
            "ano_mes": "2020/06",
            "tipo_servico": "Site",
            "qtde": 13,
            "valor": "4107.00"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Artes",
            "qtde": 6,
            "valor": "435.00"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "2.10"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Hospedagem",
            "qtde": 5,
            "valor": "1719.61"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Impressão",
            "qtde": 10,
            "valor": "4309.51"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Impulsionamento",
            "qtde": 6,
            "valor": "463.02"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Logotipos",
            "qtde": 6,
            "valor": "1788.00"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Marketing",
            "qtde": 29,
            "valor": "15305.00"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Site",
            "qtde": 15,
            "valor": "5919.41"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Suporte",
            "qtde": 1,
            "valor": "30.00"
        },
        {
            "ano_mes": "2020/07",
            "tipo_servico": "Video",
            "qtde": 3,
            "valor": "795.00"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Artes",
            "qtde": 11,
            "valor": "3735.07"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 4,
            "valor": "86.22"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "184.00"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Hospedagem",
            "qtde": 7,
            "valor": "1491.65"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Impressão",
            "qtde": 7,
            "valor": "1410.00"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Impulsionamento",
            "qtde": 5,
            "valor": "481.66"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Logotipos",
            "qtde": 6,
            "valor": "1874.50"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Marketing",
            "qtde": 30,
            "valor": "16235.00"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Reembolso",
            "qtde": 1,
            "valor": "397.95"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "961.87"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Site",
            "qtde": 7,
            "valor": "3659.50"
        },
        {
            "ano_mes": "2020/08",
            "tipo_servico": "Suporte",
            "qtde": 1,
            "valor": "30.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Artes",
            "qtde": 10,
            "valor": "2207.40"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 2,
            "valor": "9.35"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Drone",
            "qtde": 1,
            "valor": "200.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Hospedagem",
            "qtde": 4,
            "valor": "978.12"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Impressão",
            "qtde": 9,
            "valor": "7013.59"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Impulsionamento",
            "qtde": 11,
            "valor": "2063.26"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "875.25"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Marketing",
            "qtde": 33,
            "valor": "17773.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "1800.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Site",
            "qtde": 8,
            "valor": "3610.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Suporte",
            "qtde": 2,
            "valor": "180.00"
        },
        {
            "ano_mes": "2020/09",
            "tipo_servico": "Video",
            "qtde": 4,
            "valor": "1225.00"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Artes",
            "qtde": 13,
            "valor": "4342.40"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Despesas Bancárias",
            "qtde": 1,
            "valor": "1.75"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Domínios",
            "qtde": 1,
            "valor": "40.00"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Hospedagem",
            "qtde": 9,
            "valor": "2631.31"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Impressão",
            "qtde": 7,
            "valor": "3142.43"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Impulsionamento",
            "qtde": 11,
            "valor": "1081.50"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Logotipos",
            "qtde": 3,
            "valor": "1996.45"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Marketing",
            "qtde": 28,
            "valor": "16005.00"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Sistemas",
            "qtde": 1,
            "valor": "50.00"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Site",
            "qtde": 10,
            "valor": "4388.75"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Suporte",
            "qtde": 2,
            "valor": "280.00"
        },
        {
            "ano_mes": "2020/10",
            "tipo_servico": "Video",
            "qtde": 2,
            "valor": "1750.00"
        },
        {
            "ano_mes": "2020/11",
            "tipo_servico": "Logotipos",
            "qtde": 1,
            "valor": "164.00"
        },
        {
            "ano_mes": "2020/12",
            "tipo_servico": "Marketing",
            "qtde": 1,
            "valor": "500.00"
        }
    ];


    let dadosFormatados = []
    dadosFormatados.push(['ano/mes', 'Tipo de Serviço', 'qtde', 'Recebimentos'])
    data.map(el => {
        dadosFormatados.push([el.ano_mes, el.tipo_servico, el.qtde, parseFloat(el.valor)])
        return true
    })

    const options = {
        legend: "none",
        chartArea: { left: 0, top: 0, right: 0, bottom: 0 },
        pieSliceText: "label",
        chart: {
            title: "Faturamento por tipo de recebimento",
            subtitle: "Valores recebidos (Entradas)",
        },
        hAxis: {
            title: 'Mês',
            minValue: 0
        },
        vAxis: {
            title: 'Tipo de Recebimento'
        }
    };

    return (
        <Chart
            chartType="Bar"
            width="100%"
            height="400px"
            data={dadosFormatados}
            options={options}
            chartWrapperParams={{ view: { columns: [0,3] } }}
            chartPackages={["corechart", "controls"]}
            controls={[
                {
                    controlEvents: [
                        {
                            eventName: "statechange",
                            callback: ({ chartWrapper, controlWrapper }) => {
                                console.log("State changed to", controlWrapper?.getState());
                            },
                        },
                    ],
                    controlType: "CategoryFilter",
                    options: {
                        filterColumnIndex: 1,
                        ui: {
                            labelStacking: "vertical",
                            label: "Tipo de Serviço",
                            allowTyping: false,
                            allowMultiple: false,
                        },
                    },
                },
            ]}
        />
    );
}

// import { Grid } from "@mui/material";
// import { Chart } from "react-google-charts";

// export const data = [
//     ["Year", "Sales", "Expenses", "Profit"],
//     ["2014", 1000, 400, 200],
//     ["2015", 1170, 460, 250],
//     ["2016", 660, 1120, 300],
//     ["2017", 1030, 540, 350],
// ];

// export const options = {
//     chart: {
//         title: "Faturamento por tipo de recebimento",
//         subtitle: "Valores recebidos (Entradas)",
//     },
// };

// export function TipoRecebimento() {
//     return (
//         <Grid sx={{width:'500px'}}>
//         <Chart
//             chartType="Bar"
//             width="100%"
//             height="400px"
//             data={data}
//             options={options}
//         />
//         </Grid>
//     );
// }

export default TipoRecebimento