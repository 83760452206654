
import {
    Alert,
    AlertTitle,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    Stack,
    TextField,
    Typography
} from "@mui/material"
import { Copyright } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { authLogin } from '../../redux/auth/authActions'
import { connect, useDispatch, useSelector } from "react-redux";
import { navigate } from "hookrouter";
import Logo from '../../img/logo-site-ninja.png'
import Capa from '../../img/personagens.png'
import LoginIcon from '@mui/icons-material/Login';

function Login() {

    const [form, setForm] = useState({ email: '', senha: '' })
    const [msgErro, setMsgErro] = useState({ email: '', senha: '' })
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useDispatch()

    function changeForm(e) {
        const { name, value } = e.target
        setForm({ ...form, [name]: value })
        setMsgErro({ email: '', senha: '' })
    }

    const { isAuthenticated } = useSelector(state => state.auth)
    const login = useSelector(state => state.auth)
    //localStorage.setItem('stateAutenticacao', JSON.stringify(login))

    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault()
        if (form.email === '') {
            msgErro.email = 'Informe um e-mail válido'
        }
        if (form.senha === '') {
            msgErro.senha = 'Informe a sua senha'
        }
        dispatch(authLogin(form))
    }

    useEffect(() => {
        if (isAuthenticated) {
            localStorage.setItem('stateAutenticacao', JSON.stringify(login))
            navigate('/home')
        }
        setIsLoading(false)
    }, [isAuthenticated, login ])

    return (
        <Grid container component='main' sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img src={Capa} alt='Logotipo Ideia Ninja' loading="lazy" width={500} />
            </Grid>

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ my: 3, mx: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="secondary" />
                    </Backdrop>

                    <Grid item>
                        <img src={Logo} alt='Logotipo Ideia Ninja' loading="lazy" width={250} />
                    </Grid>

                    <Typography variante="subtitle1" gutterBottom sx={{ fontSize: '25px', mb: 0 }}>
                        SAIN
                    </Typography>

                    <Typography variante="body2" sx={{ fontSize: '15px', mb: 3 }}>
                        Sistema de Administração Ideia Ninja
                    </Typography>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={changeForm}
                            onFocus={() => login.error = ''}
                            value={form.email}
                            error={!!msgErro.email}
                            helperText={msgErro.email}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="senha"
                            label="Senha"
                            name="senha"
                            autoComplete="current-password"
                            onChange={changeForm}
                            onFocus={() => login.error = ''}
                            value={form.senha}
                            error={!!msgErro.senha}
                            helperText={msgErro.senha}
                        />

                        {!!login.error ? (
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error" sx={{ fontSize: '12px' }}>
                                    <AlertTitle sx={{ fontSize: '15px' }}>E-mail e/ou senha incorretos ou não cadastrados.</AlertTitle>
                                    Entre em contato com o administrador do sistema.
                                </Alert>
                            </Stack>
                        ) : null}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={!form.email || !form.senha || !!login.error}
                            endIcon={<LoginIcon />}
                        >
                            Entrar
                        </Button>

                        <Typography component='p' sx={{ fontSize: '0.8rem', textAlign: 'center' }} >
                            Copyright
                            <Copyright sx={{ mt: 5 }} />
                            2013 – 2022 Ideia Ninja Marketing e Soluções Web LTDA – CNPJ: 28.577.650/0001-13
                        </Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>

    )
}

const mapStateToProps = state => {
    return {
        //userData: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        //fetchUsers: () => dispatch(fetchUsers())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login)

// TUTORIAL JWT
// https://www.bezkoder.com/react-redux-jwt-auth/#Overview_of_React_Redux_JWT_Authentication_example