import HistoryIcon from '@mui/icons-material/History';
import { useState, useEffect } from 'react'
import { fetchHistorico } from '../../../redux/cadastro/historicoSlice';
import { connect } from 'react-redux'
import { dataBRExibir, valorMoeda } from '../../../funcoes/data';
import { Close, ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Badge,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { Box } from '@mui/system';

function Historico(props) {
    const {
        pessoa_id,
        historico,
        carregarHistorico,
        loading
    } = props

    const [open, setOpen] = useState(false)
    const [resultado, setResultado] = useState({ entradas: 0.00, saidas: 0.00 })

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const calcularSubTotal = (dados) => {
        if (dados) {
            const subTotal = dados.map(x => parseFloat(x.valor)).reduce((a, b) => a + b, 0)
            return subTotal
        }
        return 0.00
    }

    useEffect(() => {
        calcularResultadoCliente(historico.projetos)
    }, [historico])

    const calcularResultadoProjeto = (dados) => {
        let valores = []
        if (dados) {
            dados.map(servico => {
                valores.push({
                    entradas: servico.recebimentos?.map(x => parseFloat(x.valor)).reduce((a, b) => a + b, 0),
                    saidas: servico.pagamentos?.map(x => parseFloat(x.valor)).reduce((a, b) => a + b, 0),
                })
                return true
            })
        }
        return {
            entradas: valores?.map(x => parseFloat(x.entradas)).reduce((a, b) => a + b, 0),
            saidas: valores?.map(x => parseFloat(x.saidas)).reduce((a, b) => a + b, 0)
        }
    }

    const calcularResultadoCliente = (dados) => {
        let valores = []
        if (dados) {
            dados.map(projeto => {
                projeto.servicos.map(servico => {
                    valores.push({
                        entradas: servico.recebimentos?.map(x => parseFloat(x.valor)).reduce((a, b) => a + b, 0),
                        saidas: servico.pagamentos?.map(x => parseFloat(x.valor)).reduce((a, b) => a + b, 0)
                    })
                    return true
                })
                return true
            })
        }
        setResultado({
            entradas: valores?.map(x => parseFloat(x.entradas)).reduce((a, b) => a + b, 0),
            saidas: valores?.map(x => parseFloat(x.saidas)).reduce((a, b) => a + b, 0)
        })
    }

    return (
        <div>
            <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                onClick={() => {
                    carregarHistorico(pessoa_id)
                    handleClickOpen()
                }}
            >
                <HistoryIcon />
            </IconButton>
            <Dialog
                fullWidth
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Box>
                        <Typography>{"Histórico de Projetos"}</Typography>
                        <Typography variant='body2'>{historico.nome}</Typography>
                    </Box>
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>

                    {
                        loading && (
                            <Box display="flex" justifyContent={'center'} alignItems="center">
                                <CircularProgress color="primary" />
                            </Box>
                        )
                    }
                    
                    {
                        
                        historico.projetos?.length > 0 ? (
                            <Grid container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    border: '1px solid',
                                    p: 1,
                                    mb: 2,
                                    bgcolor: '#FAFAD0'
                                }}
                            >
                                <Grid item sx={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                                    <Typography variant="body">
                                        <strong>{'Resultado Global'}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ width: '18%' }}>
                                    <Typography variant="body2">Entradas</Typography>
                                    <Typography variant="body2">{valorMoeda(resultado.entradas)}</Typography>
                                </Grid>
                                <Grid item sx={{ width: '18%' }}>
                                    <Typography variant="body2">Saídas</Typography>
                                    <Typography variant="body2">{valorMoeda(resultado.saidas)}</Typography>
                                </Grid>
                                <Grid item sx={{ width: '18%' }}>
                                    <Typography variant="body2">Resultado</Typography>
                                    <Typography variant="body2">{valorMoeda(resultado.entradas - resultado.saidas)}</Typography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Typography>Não há projetos com movimentação.</Typography>
                        )
                    }

                    {
                        !loading && historico.projetos && historico.projetos.map(p => (
                            <Accordion key={p.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Grid container
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <Grid item sx={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                                            <Typography variant="body">
                                                <Badge color="primary" badgeContent={p.servicos.length}>
                                                    <strong>{p.nome}</strong>
                                                </Badge>
                                            </Typography>
                                        </Grid>
                                        <Grid item sx={{ width: '18%' }}>
                                            <Typography variant="body2">Entradas</Typography>
                                            <Typography variant="body2">{valorMoeda(calcularResultadoProjeto(p.servicos).entradas)}</Typography>
                                        </Grid>
                                        <Grid item sx={{ width: '18%' }}>
                                            <Typography variant="body2">Saídas</Typography>
                                            <Typography variant="body2">{valorMoeda(calcularResultadoProjeto(p.servicos).saidas)}</Typography>
                                        </Grid>
                                        <Grid item sx={{ width: '18%' }}>
                                            <Typography variant="body2">Resultado</Typography>
                                            <Typography variant="body2">{valorMoeda(calcularResultadoProjeto(p.servicos).entradas - calcularResultadoProjeto(p.servicos).saidas)}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        p.servicos && p.servicos.map(s => (
                                            <Accordion key={s.id}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMore />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Grid container
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between'
                                                        }}
                                                    >
                                                        <Grid item sx={{ width: '46%', display: 'flex', alignItems: 'center' }}>
                                                            <Typography variant="subtitle2">{s.nome}</Typography>
                                                        </Grid>
                                                        <Grid item sx={{ width: '18%' }}>
                                                            <Typography variant="body2">Entradas</Typography>
                                                            <Typography variant="body2">{valorMoeda(calcularSubTotal(s.recebimentos))}</Typography>
                                                        </Grid>
                                                        <Grid item sx={{ width: '18%' }}>
                                                            <Typography variant="body2">Saídas</Typography>
                                                            <Typography variant="body2">{valorMoeda(calcularSubTotal(s.pagamentos))}</Typography>
                                                        </Grid>
                                                        <Grid item sx={{ width: '18%' }}>
                                                            <Typography variant="body2">Resultado</Typography>
                                                            <Typography variant="body2">{valorMoeda(calcularSubTotal(s.recebimentos) - calcularSubTotal(s.pagamentos))}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} justifyContent={'center'}>
                                                        {s.recebimentos.length > 0 ? (
                                                            <Grid item>
                                                                <TableContainer component={Paper}>
                                                                    <Table size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell colSpan={4} align="center">Entradas</TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }}>Data</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }}>Status</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }}>Situação</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }} align="right">Valor</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {
                                                                                s.recebimentos.length > 0 &&
                                                                                s.recebimentos.slice().sort((r1, r2) => r1.data < r2.data ? 1 : -1)

                                                                                    .map((row) => (
                                                                                        <TableRow
                                                                                            key={row.id}
                                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                        >
                                                                                            <TableCell component="th" scope="row">
                                                                                                {dataBRExibir(row.data)}
                                                                                            </TableCell>
                                                                                            <TableCell sx={{ bgcolor: `${row.statusRecebimento.cor_padrao}`, color: `${row.statusRecebimento.cor_fonte}` }}>{row.statusRecebimento.nome}</TableCell>
                                                                                            <TableCell sx={{ bgcolor: `${row.origemRecebimento?.cor_padrao}`, color: `${row.origemRecebimento?.cor_fonte}` }}>{row.origemRecebimento?.nome}</TableCell>
                                                                                            <TableCell align="right">{valorMoeda(row.valor)}</TableCell>

                                                                                        </TableRow>
                                                                                    ))}
                                                                            <TableRow>
                                                                                <TableCell colSpan={2}>Sub-total</TableCell>
                                                                                <TableCell colSpan={2} align="right">{valorMoeda(calcularSubTotal(s.recebimentos))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        ) : null}
                                                        {s.pagamentos.length > 0 ? (
                                                            <Grid item>
                                                                <TableContainer component={Paper}>
                                                                    <Table size="small">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell colSpan={4} align="center">Saídas</TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }}>Data</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }}>Fornecedor</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }} align="right">Status</TableCell>
                                                                                <TableCell sx={{ bgcolor: '#FAFA90' }} align="right">Valor</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>
                                                                        <TableBody>
                                                                            {s.pagamentos.map((row) => (
                                                                                <TableRow
                                                                                    key={row.id}
                                                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                                >
                                                                                    <TableCell component="th" scope="row">{dataBRExibir(row.data_vencimento)}</TableCell>
                                                                                    <TableCell component="th" scope="row">{row.fornecedor?.nome}</TableCell>
                                                                                    <TableCell sx={{ bgcolor: `${row.statusPagamento.cor_padrao}`, color: `${row.statusPagamento.cor_fonte}` }}>{row.statusPagamento.nome}</TableCell>
                                                                                    <TableCell align="right">{valorMoeda(row.valor)}</TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                            <TableRow key={`total-${s.id}`}>
                                                                                <TableCell colSpan={2}>Sub-total</TableCell>
                                                                                <TableCell colSpan={2} align="right">{valorMoeda(calcularSubTotal(s.pagamentos))}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </Table>
                                                                </TableContainer>
                                                            </Grid>
                                                        ) : null}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </AccordionDetails>
                            </Accordion>
                        )
                        )
                    }

                </DialogContent>
                <DialogActions>

                    <Button variant="contained" color="secondary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        loading: state.historico.loading,
        historico: state.historico.registro,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        carregarHistorico: (pessoa_id) => dispatch(fetchHistorico(pessoa_id))
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Historico)
