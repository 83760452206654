import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../config/configApi'
import header from '../../middleware/jwtInterceptor'

const initialState ={
    loading: true,
    registros: [],
    projetoAtual: {},
    error: '',
}

export const fetchProjetos = createAsyncThunk('cadastro/projetos', () => {
    const autorizacao = header()
    return api
        .get('projetos/lista', autorizacao)
        .then(res => {
            return res.data
        })
})

export const saveProjeto = createAsyncThunk('cadastro/projetos/savePessoa', (registro) => {
    const autorizacao = header()
    return api
        .post(`/projetos/salvar`, registro, autorizacao)
        .then((res) => res.data)
})

export const deleteProjeto = createAsyncThunk('cadastro/projetos/delete', (registro) => {
    const autorizacao = header()
    return api
        .delete(`/projetos/excluir/${registro.id}`, autorizacao)
        .then((res)=> res.data)
})

export const slice = createSlice({
    name:'projetos',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchProjetos.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchProjetos.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchProjetos.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        builder.addCase(saveProjeto.pending, state => {
            state.loading = true
        })
        builder.addCase(saveProjeto.fulfilled, (state, action) => {
            state.loading = false
            state.projetoAtual = action.payload
            state.error = ''
        })
        builder.addCase(saveProjeto.rejected, (state, action) => {
            state.loading = false
            state.projetoAtual = {}
            state.error = action.error.message
        })

        builder.addCase(deleteProjeto.pending, state => {
            state.loading = true
        })
        builder.addCase(deleteProjeto.fulfilled, (state, action) => {
            state.loading = false
            state.projetoAtual = action.payload
            state.error = ''
        })
        builder.addCase(deleteProjeto.rejected, (state, action) => {
            state.loading = false
            state.projetoAtual = {}
            state.error = action.error.message
        })
    }
}) 

export default slice.reducer

