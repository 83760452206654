import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { salvar } from '../../../../redux/cadastro/tabelas/tiposRedesSociaisSlice';
import Aviso from '../../../layouts/Aviso';
import DialogErro from '../../../layouts/DialogErro';

const validationSchema = yup.object({
    nome: yup
        .string('Informe o nome')
        .min(3, 'O nome precisa ter no mínimo 3 caracteres')
        .max(255, 'o nome pode ter no máximo 255 caracteres')
        .required('Um nome precisa ser informado'),
    descricao: yup
        .string('Informe a descrição')
        .min(3, 'A descrição precisa ter no mínimo 3 caracteres')
        .max(255, 'A descrição pode ter no máximo 255 caracteres')
        .required('Uma descrição precisa ser informada'),
});

export const Formulario = (props) => {

    const { modo, setModo, salvar } = props
    const registro = modo.registro

    const [openAviso, setOpenAviso] = useState(false);
    const [openDialogErro, setOpenDialogErro] = useState(false);
    const [dialogError, setDialogError] = useState('');

    const formik = useFormik({
        initialValues: {
            id: registro.id || '',
            nome: registro.nome || '',
            descricao: registro.descricao || '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSalvar(values)
        },
    });

    const handleSalvar = async (values) => {

        const res = await salvar(values)

        if (!!res?.error) {
            setDialogError(`Novo Tipo de Redes Sociais: ${res.error?.code} - ${res.error?.message}`)
            setOpenDialogErro(true)
        } else {
            setOpenAviso(true)
            formik.resetForm()
            if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
        }

    }


    return (
        <Container sx={{ mt: 10 }} >
            <Paper sx={{ p: 5, mt: 12 }}>
                <Typography variant="h5" gutterBottom mb={4} align='center'>
                    {modo.status === 'add' ? 'Novo Tipo de Rede Social' : 'Editar Tipo de Rede Social'}
                </Typography>

                <Grid
                    container
                    component="form"
                    onSubmit={formik.handleSubmit}
                    rowSpacing={{ xs: 2, sm: 2, }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                    flexDirection='row'
                //justifyContent='center'
                //sx = {{'& .MuiTextField-root': {m:1, width:'40%'}}}
                >
                    <Grid item xs={12} sm={6} lg={4} >
                        <TextField
                            size='small'
                            fullWidth
                            id="nome"
                            name="nome"
                            label="Nome"
                            value={formik.values.nome}
                            onChange={formik.handleChange}
                            error={formik.touched.nome && Boolean(formik.errors.nome)}
                            helperText={formik.touched.nome && formik.errors.nome}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} lg={8} >
                        <TextField
                            size='small'
                            fullWidth
                            id="descricao"
                            name="descricao"
                            label="Descrição"
                            value={formik.values.descricao}
                            onChange={formik.handleChange}
                            error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                            helperText={formik.touched.descricao && formik.errors.descricao}
                        />
                    </Grid>

                    <Grid item xs={12} md={8} lg={6} >
                        <Grid
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', sm: 'row' },
                                gap: 2,
                                mt: 3
                            }}
                        >
                            <Button
                                fullWidth
                                color="primary"
                                variant="contained"
                                type="submit"
                                startIcon={<SaveIcon />}
                            >
                                {modo.status === 'add' ? 'Adicionar' : 'Alterar'}
                            </Button>

                            {
                                modo.status === 'add' ? (
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => formik.handleReset(formik.resetForm)}
                                        startIcon={<ClearIcon />}
                                    >
                                        Limpar
                                    </Button>
                                ) : null
                            }

                            <Button
                                fullWidth
                                variant="contained"
                                color="warning"
                                onClick={() => setModo({ status: 'table', registro: {} })}
                                startIcon={<ArrowBackIcon />}
                            >
                                VOLTAR
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Aviso
                openSnakbar={openAviso}
                setOpenAviso={setOpenAviso}
                mensagem={`Registro ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
                tipo="success"
            />
            <DialogErro
                openDialogErro={openDialogErro}
                setOpenDialogErro={setOpenDialogErro}
                dialogError={dialogError}
                mensagem="Não foi possível incluir o registro. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
            />

        </Container>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        salvar: (registro) => dispatch(salvar(registro)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Formulario)