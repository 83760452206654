import { Add } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Button, Container, Grid, IconButton, Menu, MenuItem, Toolbar } from '@mui/material'
import React from 'react'
import ExibirColunasComponents from './ExibirColunasComponents';
import ExibirExcluidos from './ExibirExcluidosComponent';
import ExportarTableComponent from './ExportarTableComponent';
import { FiltroGlobal } from './FiltroGlobalComponent';

function AppBarComponent(props) {

    const { botaoExcluidos } = props

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar sx={{ mt: 8, pl: 2, pr: 2, bgcolor: '#fff' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters >
                    <Grid
                        container
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'space-between',
                            alignItems: "center",
                            width: '100%',
                        }}
                    >
                        <FiltroGlobal
                            filter={props.globalFilter}
                            setFilter={props.setGlobalFilter}
                        />

                        {/* <Button
                            variant="contained"
                            color="third"
                            startIcon={<Add />}
                            onClick={() => props.setModo({ status: 'add', registro: {} })}
                        >
                            Adicionar
                        </Button> */}

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="primary"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                            keepMounted
                            transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: 'block', md: 'none' }, }}
                        >

                            <MenuItem>
                                <ExibirExcluidos
                                    exibirExcluidos={props.exibirExcluidos}
                                    setExibirExcluidos={props.setExibirExcluidos}
                                    labelExibirExcluidos={props.labelExibirExcluidos}
                                />
                            </MenuItem>

                            <MenuItem>
                                <ExportarTableComponent
                                    exportData={props.exportData}
                                />
                            </MenuItem>
                            <MenuItem sx={{ width: "100%" }}>
                                <ExibirColunasComponents
                                    getToggleHideAllColumnsProps={props.getToggleHideAllColumnsProps}
                                    allColumns={props.allColumns}
                                />
                            </MenuItem>

                            <MenuItem>
                                <Button
                                    variant="contained"
                                    color="third"
                                    endIcon={<Add />}
                                    onClick={() => props.setModo({ status: 'add', registro: {} })}
                                >
                                    Adicionar
                                </Button>
                            </MenuItem>
                        </Menu>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <FiltroGlobal
                            filter={props.globalFilter}
                            setFilter={props.setGlobalFilter}
                        />
                        
                        {botaoExcluidos ? (
                            <ExibirExcluidos
                                exibirExcluidos={props.exibirExcluidos}
                                setExibirExcluidos={props.setExibirExcluidos}
                                labelExibirExcluidos={props.labelExibirExcluidos}
                            />
                        ) : null}

                        <ExportarTableComponent
                            exportData={props.exportData}
                        />

                        <ExibirColunasComponents
                            getToggleHideAllColumnsProps={props.getToggleHideAllColumnsProps}
                            allColumns={props.allColumns}
                        />

                        <Button
                            variant="contained"
                            color="third"
                            startIcon={<Add />}
                            onClick={() => props.setModo({ status: 'add', registro: {} })}
                        >
                            Adicionar
                        </Button>
                    </Grid>
                </Toolbar>
            </Container>

        </AppBar>
    )
}

export default AppBarComponent