import Layout from "../../layouts"
import { useState } from "react"
import { Box } from "@mui/system"
import { Table } from "./table/Table"
import FormAdicionar from './FormAdicionar'
import FormAlterar from "./FormAlterar"

export const Pessoas = (props) => {

    const [modo, setModo] = useState('table')
    const [pessoaAtual, setPessoaAtual] = useState({})

    return (
        <Layout
            titulo="Pessoas"
            subtitulo="Cadastro geral de pessoas"
        >
            <Box>
                {
                    modo === 'table' ? (
                        <Table
                            modo={modo}
                            setModo={setModo}
                            setPessoaAtual={setPessoaAtual}
                        />
                    ) : (
                        modo === 'adicao' ? (
                            <FormAdicionar
                                setModo={setModo}
                            />
                        ) : (modo === 'edicao' ? (
                            <FormAlterar
                                modo={modo}
                                setModo={setModo}
                                setPessoaAtual={setPessoaAtual}
                                pessoaAtual={pessoaAtual}
                            />
                        ) : null)
                    )
                }
            </Box>
        </Layout>
    )
}

export default Pessoas




