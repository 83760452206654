import './App.css';
import { useRoutes } from 'hookrouter'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Provider } from 'react-redux'
import store from './redux/store'
import Login from './components/login'
import Home from './components/home'
import Cadastro from './components/cadastro'
import Pessoas from './components/cadastro/pessoas'
import Projetos from './components/cadastro/projetos'
import Servicos from './components/cadastro/servicos'
import Auxiliar from './auxiliar/components'
import Recebimentos from './components/cadastro/recebimentos'
import Pagamentos from './components/cadastro/pagamentos'
import Dashboard from './components/dashboard'
import Relatorios from './components/relatorios'
import FechamentoPeriodo from './components/relatorios/fechamentoPeriodo'
import Producao from './components/producao'
import Parcelas from './components/producao/parcelas';
import Usuarios from './components/cadastro/usuarios';
import PagamentosAClassificar from './components/relatorios/pagamentosAClassificar';
import ContasAReceber from './components/relatorios/contasAReceber';
import ControleGeral from './components/relatorios/controleGeral';
import autorizacao from './middleware/autorizacao'
import NaoAutorizado from './components/login/naoAutorizado'
import PaginaNaoEncontrada from './components/login/paginaNaoEncontrada'
import AlterarSenha from './components/login/alterarSenha';
import Preferencias from './components/login/preferencias';
import Versoes from './components/sistema/versoes';
import Documentacao from './components/sistema/documentacao';
import Configuracao from './components/sistema/configuracao';
import Tabelas from './components/cadastro/tabelas';
import FormasPagamento from './components/cadastro/tabelas/FormasPagamento';
import GruposPessoas from './components/cadastro/tabelas/GruposPessoas';
import OrigensPagamentos from './components/cadastro/tabelas/OrigensPagamentos';
import OrigensRecebimentos from './components/cadastro/tabelas/OrigensRecebimentos';
import PeriodicidadesServicos from './components/cadastro/tabelas/PeriodicidadesServicos';
import StatusPagamentos from './components/cadastro/tabelas/StatusPagamentos';
import StatusProjetos from './components/cadastro/tabelas/StatusProjetos';
import StatusRecebimentos from './components/cadastro/tabelas/StatusRecebimentos';
import StatusServicos from './components/cadastro/tabelas/StatusServicos';
import TiposEndereco from './components/cadastro/tabelas/TiposEndereco';
import TiposPagamento from './components/cadastro/tabelas/TiposPagamento';
import TiposProjeto from './components/cadastro/tabelas/TiposProjeto';
import TiposRedesSociais from './components/cadastro/tabelas/TiposRedesSociais';
import TiposServico from './components/cadastro/tabelas/TiposServico';
import TiposTelefone from './components/cadastro/tabelas/TiposTelefone';
import Contas from './components/sistema/contas';

const theme = createTheme({
  palette: {
    primary: {
      main: '#262626',
      dark: '#000000',
      light: '#4e4e4e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#fae10b',
      dark: '#c3b000',
      light: '#ffff57',
      contrastText: '#000',
    },
    third: {
      main: '#50a19d',
      dark: '#18726f',
      light: '#82d2ce',
      contrastText: '#fff',
    },
    alternativo: {
      main: '#0d47a1',
      dark: '#002171',
      light: '#5472d3',
      contrastText: '#000',
    },
    background: {
      default: '#fff',
      paper: '#f5f5f5',
      error: '#D33131',
      warning: '#ff9800',
      alternativo: '#0d47a1'
    },

  },
})

const routes = {
  '/': () => <Login />,
  '/home': () => <Home />,
  '/dashboard': () => autorizacao() ? <Dashboard /> : <NaoAutorizado /> ,
  '/usuarios': () => autorizacao() ? <Usuarios /> : <NaoAutorizado />,
  '/usuarios/alterarsenha': () => <AlterarSenha /> ,
  '/usuarios/preferencias': () => <Preferencias /> ,
  '/cadastro': () => autorizacao()? <Cadastro /> : <NaoAutorizado />,
  '/cadastro/pessoas': () => autorizacao() ? <Pessoas /> : <NaoAutorizado />,
  '/cadastro/projetos': () => autorizacao() ? <Projetos /> : <NaoAutorizado />,
  '/cadastro/servicos': () => autorizacao() ? <Servicos /> : <NaoAutorizado />,
  '/cadastro/recebimentos': () => autorizacao() ? <Recebimentos /> : <NaoAutorizado />,
  '/cadastro/pagamentos': () => autorizacao() ? <Pagamentos /> : <NaoAutorizado />,
  '/auxiliar': () => autorizacao() ? <Auxiliar /> : <NaoAutorizado />,
  '/relatorios': () => autorizacao() ? <Relatorios /> : <NaoAutorizado />,
  '/relatorios/fechamentoPeriodo': () => autorizacao() ? <FechamentoPeriodo /> : <NaoAutorizado />,
  '/relatorios/pagamentosAClassificar': () => autorizacao() ? <PagamentosAClassificar/> : <NaoAutorizado />,
  '/relatorios/contasAReceber': () => autorizacao() ? <ContasAReceber/> : <NaoAutorizado />,
  '/relatorios/controleGeral' : () => autorizacao() ? <ControleGeral/> : <NaoAutorizado />,
  '/producao' : () => autorizacao() ? <Producao /> : <NaoAutorizado />,
  '/producao/parcelas': () => autorizacao() ? <Parcelas /> : <NaoAutorizado />,
  '/sistema/versoes': () => autorizacao() ? <Versoes /> : <NaoAutorizado />,
  '/sistema/documentacao': () => autorizacao() ? <Documentacao /> : <NaoAutorizado />,
  '/sistema/configuracao': () => autorizacao() ? <Configuracao /> : <NaoAutorizado />,
  '/naoautorizado': () => <NaoAutorizado />,
  '/cadastro/tabelas': () => autorizacao() ? <Tabelas/> : <NaoAutorizado />,
  '/cadastro/tabelas/formaspagamento': () => autorizacao() ? <FormasPagamento/> : <NaoAutorizado />, 
  '/cadastro/tabelas/grupospessoas': () => autorizacao() ? <GruposPessoas/> : <NaoAutorizado />, 
  '/cadastro/tabelas/origenspagamento': () => autorizacao() ? <OrigensPagamentos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/origensrecebimento': () => autorizacao() ? <OrigensRecebimentos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/periodicidadeservico': () => autorizacao() ? <PeriodicidadesServicos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/statuspagamento': () => autorizacao() ? <StatusPagamentos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/statusrecebimento': () => autorizacao() ? <StatusRecebimentos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/statusprojeto': () => autorizacao() ? <StatusProjetos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/statusservico': () => autorizacao() ? <StatusServicos/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tiposendereco': () => autorizacao() ? <TiposEndereco/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tipospagamento': () => autorizacao() ? <TiposPagamento/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tiposprojeto': () => autorizacao() ? <TiposProjeto/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tiposredessociais': () => autorizacao() ? <TiposRedesSociais/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tiposservico': () => autorizacao() ? <TiposServico/> : <NaoAutorizado />, 
  '/cadastro/tabelas/tipostelefone': () => autorizacao() ? <TiposTelefone/> : <NaoAutorizado />, 
  '/sistema/contas': () => autorizacao() ? <Contas/> : <NaoAutorizado />, 
  
}

function App() {
  const routeResult = useRoutes(routes)
  return (
    
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          {routeResult || <PaginaNaoEncontrada/>}
        </Provider>
      </ThemeProvider>
    
  );
}

export default App;
