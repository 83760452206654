
import { Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { dataBRExibir } from '../../../../funcoes/data'
import { atualizarSaldo } from '../../../../redux/sistemas/contas/contasSlice'
import { excluirMovimento, listarMovimentosPorPeriodo } from '../../../../redux/sistemas/contas/movimentosSlice'
import DialogExclusao from '../../../layouts/DialogExclusao'
import PopoverSain from '../../../layouts/PopoverSain'
import FormMovimento from './formMovimento'
import LinkIcon from '@mui/icons-material/Link';
import FormVinculos from './formVinculos'
import ViewVinculos from './viewVinculos'

export const TableMovimento = (props) => {
    const {
        contaSelecionada,
        listarMovimentosPorPeriodo,
        excluirMovimento,
        atualizarSaldo,
        movimentos,
        setSaldo,
        parametros
    } = props

    // eslint-disable-next-line no-unused-vars
    const [idExclusao, setIdExclusao] = useState('')
    const [atualizarListaMovimentos, setAtualizaListaMovimentos] = useState(true)

    useEffect(() => {
        if (atualizarListaMovimentos) {
            if (parametros.contaSelecionada) {
                listarMovimentosPorPeriodo(parametros)
            }
        }
        setAtualizaListaMovimentos(false)
    }, [atualizarListaMovimentos, listarMovimentosPorPeriodo, parametros])

    let movimentosSort = [...movimentos.registros]
    movimentosSort.sort((r1, r2) => r1.data < r2.data ? 1 : -1)
    const rows = movimentosSort

    const handleExcluir = async (registro) => {
        excluirMovimento(registro.id)

        let registroAtual = { ...registro }
        registroAtual.valor = 0.00
        const reg = {
            registroAnterior: registro,
            registroAtual: registroAtual,
            conta_id: contaSelecionada
        }
        const result = await atualizarSaldo(reg)
        setSaldo(result.payload.registro.saldo)
    }

    const renderViewVinculados = (row) => {
        return (
            <ViewVinculos
                movimento={row}
            />
        )
    }

    return (
        <TableContainer component={Paper} sx={{ p: 2 }}>
            {movimentos.loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                        <Typography variant='h5'>Extrato da conta</Typography>
                        <Box>
                            <FormMovimento
                                contaSelecionada={contaSelecionada}
                                setSaldo={setSaldo}
                                acao='add'
                            />
                        </Box>
                    </Box>

                    {rows.length === 0 ? (
                        <Typography variant='h6'>Nenhum movimento registrado para esta conta no período informado.</Typography>
                    ) : (
                        <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'Silver' }}>
                                    <TableCell>#</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell align="center"><LinkIcon /></TableCell>
                                    <TableCell width={'10px'}>DATA</TableCell>
                                    <TableCell>DESCRIÇÃO</TableCell>
                                    <TableCell align="right">VALOR</TableCell>
                                    <TableCell align="center">D/C</TableCell>
                                    <TableCell align="center">AÇÕES</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length > 0 && rows.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell align="center">
                                            {row.pagamentos?.length > 0 || row.recebimentos?.length > 0 ? renderViewVinculados(row) : null}
                                        </TableCell>
                                        <TableCell>{dataBRExibir(row.data)}</TableCell>
                                        <TableCell>
                                            {row.tipoMovimento.nome}
                                            {row.historico ? (
                                                <PopoverSain titulo="Histórico">{row.historico}</PopoverSain>
                                            ) : null}
                                        </TableCell>
                                        <TableCell align="right">{Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(row.valor)}</TableCell>
                                        <TableCell align="center">{row.natureza}</TableCell>
                                        <TableCell>
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                                {row.pagamentos?.length === 0 && row.recebimentos?.length === 0 ? (
                                                    <FormMovimento
                                                        contaSelecionada={contaSelecionada}
                                                        setSaldo={setSaldo}
                                                        registro={row}
                                                        acao='alt'
                                                    />
                                                ) : null}
                                                {row.pagamentos?.length === 0 && row.recebimentos?.length === 0 ? (
                                                    <FormVinculos
                                                        registro={row}
                                                        setAtualizaListaMovimentos={setAtualizaListaMovimentos}
                                                    />
                                                ) : null}

                                                {row.pagamentos?.length === 0 && row.recebimentos?.length === 0 ? (
                                                    <DialogExclusao
                                                        title="o Movimento "
                                                        referencia={`
                                                        ${row.tipoMovimento.nome}
                                                        | ${row.natureza}
                                                        | ${dataBRExibir(row.data)}
                                                        | ${Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(row.valor)} 
                                                    `}
                                                        idExclusao={row.id}
                                                        setIdExclusao={setIdExclusao}
                                                        handleExcluir={() => handleExcluir(row)}
                                                    />
                                                ) : null}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}
                </>
            )}
        </TableContainer>
    )
}

const mapStateToProps = (state) => {
    return {
        movimentos: {
            loading: state.movimentos.loading,
            registros: state.movimentos.registros,
            error: state.movimentos.error
        },
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listarMovimentosPorPeriodo: (parametros) => dispatch(listarMovimentosPorPeriodo(parametros)),
        excluirMovimento: (id) => dispatch(excluirMovimento(id)),
        atualizarSaldo: (registro) => dispatch(atualizarSaldo(registro)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableMovimento)