import Papa from "papaparse";
import { utils, writeFile } from "xlsx";
import jsPDF from 'jspdf'
import "jspdf-autotable";

export function getExportFileBlob({ columns, data, fileType, fileName }) {

    if (fileType === 'csv') {
        const headerNames = columns.map((col) => col.exportValue)
        const csvString = Papa.unparse({ fields: headerNames, data })
        return new Blob(["\uFEFF" + csvString], { type: "text/csv;charset=utf-8" })
    } else if (fileType === "xlsx") {
        const header = columns.map((c) => c.exportValue)
        const compatibleData = data.map((row) => {
            const obj = {}
            header.forEach((col, index) => {
                obj[col] = row[index]
            })
            return obj
        })
        let wb = utils.book_new()
        let ws1 = utils.json_to_sheet(compatibleData, {
            header,
        })
        utils.book_append_sheet(wb, ws1, "React Table Data")
        writeFile(wb, `${fileName}.xlsx`)

        return false
    }

    if (fileType === 'pdf') {
        const headerNames = columns.map((column) => column.exportValue)
        const doc = new jsPDF();
        doc.autoTable({
            head: [headerNames],
            body: data,
            margin: { top: 15 },
            styles: {
                minCelHeight: 15,
                halign: "left",
                fontSize: 9,
            }
        })
        doc.save(`${fileName}.pdf`)
        return false
    }
    return false
}