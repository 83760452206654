import { Box, Container, SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material"
import { NavBar } from "./navbar"

import EditIcon from '@mui/icons-material/Edit'
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import HandymanIcon from '@mui/icons-material/Handyman';
import PaymentIcon from '@mui/icons-material/Payment';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { navigate } from "hookrouter"

const customSpeedAction = {
    width: 50,
    height: 50,
    backgroundColor: 'lightblue'
}

export const Layout = (props) => {
    const user = JSON.parse(localStorage.getItem('stateAutenticacao')) 

    return (
        <Container maxWidth='xl'>
            <Box sx={{ pl: 5, pr: 5 }}>
                <NavBar
                    titulo={props.titulo}
                    subtitulo={props.subtitulo}
                />
                <Box sx={{ height: '100vh - 50px' }} mt={8}>
                    {props.children}
                </Box>
            </Box>
            {
                user.login.user?.tipo === 1 ? (
                    <SpeedDial
                        ariaLabel="Navigation speed dial"
                        sx={{
                            position: 'fixed',
                            bottom: 16,
                            right: 8,
                            '& .MuiFab-primary': {
                                width: 50,
                                height: 50,
                                backgroundColor: 'gold',
                                opacity: 0.6,
                                color: 'blue',
                                '&:hover': { backgroundColor: 'yellow', opacity: 1, }
                            },

                        }}
                        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
                    >
                        <SpeedDialAction
                            icon={<PeopleAltIcon />}
                            tooltipTitle={<Box sx={{ fontSize: '1.2rem' }}>Pessoas</Box>}
                            //tooltipOpen
                            onClick={() => navigate('/cadastro/pessoas')}
                            sx={customSpeedAction}
                        />
                        <SpeedDialAction
                            icon={<AccountTreeIcon />}
                            tooltipTitle={<Box sx={{ fontSize: '1.2rem' }}>Projetos</Box>}
                            //tooltipOpen
                            onClick={() => navigate('/cadastro/projetos')}
                            sx={customSpeedAction}
                        />
                        <SpeedDialAction
                            icon={<HandymanIcon />}
                            tooltipTitle={<Box sx={{ fontSize: '1.2rem' }}>Serviços</Box>}
                            //tooltipOpen
                            onClick={() => navigate('/cadastro/servicos')}
                            sx={customSpeedAction}
                        />
                        <SpeedDialAction
                            icon={<PaymentIcon />}
                            tooltipTitle={<Box sx={{ fontSize: '1.2rem' }}>Saídas</Box>}
                            //tooltipOpen
                            onClick={() => navigate('/cadastro/pagamentos')}
                            sx={customSpeedAction}
                        />
                        <SpeedDialAction
                            icon={<LocalAtmIcon />}
                            tooltipTitle={<Box sx={{ fontSize: '1.2rem' }}>Entradas</Box>}
                            //tooltipOpen
                            onClick={() => navigate('/cadastro/recebimentos')}
                            sx={customSpeedAction}
                        />
                    </SpeedDial>
                ) : null
            }

        </Container>
    )
}

export default Layout