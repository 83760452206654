import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { salvarAgencia } from '../../../../redux/sistemas/contas/agenciasSlice';


const validationSchema = yup.object({
    banco_id: yup
        .string('Selecione o banco')
        .required('Um banco precisa ser selecionado'),
    codigo: yup
        .string('Informe o código da Agência')
        .required('Um código de Agência precisa ser informado'),
    nome: yup
        .string('Informe o nome da Agência')
        .min(3, 'O nome da Agência precisa ter no mínimo 3 caracteres')
        .max(100, 'O nome da Agência precisa ter no máximo 100 carcateres')
        .required('O nome da Agência é obrigatório'),
});

export const FormAgencia = (props) => {

    const { registro, salvarAgencia, acao, bancos } = props
    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: registro?.id || '',
            banco_id: registro?.banco_id || '',
            codigo: registro?.codigo || '',
            nome: registro?.nome || ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            salvarAgencia(values)
            handleClose()
        },
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                acao === "alt" ? (
                    <Tooltip title={<Box sx={{ fontSize: '0.9rem' }}>Alterar dados</Box>} placement="top" >
                        <IconButton
                            color="primary"
                            onClick={handleClickOpen}
                            size="small"
                        >
                            <EditIcon color='success' />
                        </IconButton>
                    </Tooltip>
                ) : (


                    <Button
                        variant="contained"
                        color="third"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon />}
                    >
                        Adicionar Agência
                    </Button>

                )
            }

            <Dialog open={open} onClose={handleClose} fullWidth >
                <Grid
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <DialogTitle>{acao === "alt" ? "Alterar Dados da Agência" : "Adicionar Nova Agência"}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Box
                            mt={2}
                            display='flex'
                            flexDirection={'column'}
                            gap={3}
                        >
                            <TextField
                                select
                                size='small'
                                fullWidth
                                value={formik.values.banco_id}
                                name="banco_id"
                                label="Banco"
                                onChange={formik.handleChange}
                                error={formik.touched.banco_id && Boolean(formik.errors.banco_id)}
                                helperText={formik.touched.banco_id && formik.errors.banco_id}
                            >
                                {bancos.map((banco, index) => (
                                    <MenuItem value={banco.id} key={index}>{banco.nome}</MenuItem>
                                ))}
                            </TextField>


                            <TextField
                                size='small'
                                fullWidth
                                name="codigo"
                                label="Código da Agência"
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                                helperText={formik.touched.codigo && formik.errors.codigo}
                            />
                            <TextField
                                size='small'
                                fullWidth
                                name="nome"
                                label="Nome do Agência"
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                error={formik.touched.nome && Boolean(formik.errors.nome)}
                                helperText={formik.touched.nome && formik.errors.nome}
                            />
                        </Box>
                    </DialogContent>

                    <DialogActions >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon />}
                        >
                            Salvar
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            type="reset"
                            onClick={handleClose}
                            startIcon={<ArrowBackIcon />}
                        >
                            Cancelar
                        </Button>

                    </DialogActions>
                </Grid>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        salvarAgencia: registro => dispatch(salvarAgencia(registro)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormAgencia)