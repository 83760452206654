import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import { Box, FormLabel } from '@mui/material'

function CheckboxGroup(props) {
    const { label, name, options, ...rest } = props
    return (
        <Box sx={{ m: 1 }}>
            <FormLabel component="legend">{label}</FormLabel>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            >
                <Field name={name}>
                    {({ field }) => {
                        return options.map(option => {
                            return (
                                <React.Fragment key={option.key}>
                                    <input
                                        error
                                        type='checkbox'
                                        id={option.value}
                                        {...field}
                                        {...rest}
                                        value={option.value}
                                        checked={field.value.includes(option.value)}
                                    />
                                    <label htmlFor={option.value}>{option.key}</label>
                                </React.Fragment>
                            )
                        })
                    }}
                </Field>
            </Box>
            <ErrorMessage component={TextError} name={name} />
        </Box>
    )
}

export default CheckboxGroup