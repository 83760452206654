import { checarData, dataBRExibir } from "../../../funcoes/data"

export default function COLUMNS() {

    return [
        {
            Header: 'Id',
            accessor: 'id',
            Footer: 'Id',
            disableFilters: true,
            sticky: 'left'
        },
        
        
        {
            Header: 'Cliente',
            accessor: 'cliente.nome',
            Footer: 'Cliente',
            sticky: 'left'
        },
        {
            Header: 'Projeto',
            accessor: 'projeto.nome',
            Footer: 'Projeto',
            sticky: 'left'
        },
        {
            Header: 'Serviço',
            accessor: 'nome',
            Footer: 'Serviço',
            sticky: 'left'
        },
        {
            Header: 'Tipo',
            accessor: 'tipoServico.nome',
            Footer: 'Tipo',
            sticky: 'left'
        },
        {
            Header: 'Periodicidade',
            accessor: 'periodicidadeServico.nome',
            Footer: 'Periodicidade',
            sticky: 'left'
        },
        {
            Header: 'Status',
            accessor: 'statusServico.nome',
            Footer: 'Status',
            sticky: 'left'
        },



        {
            Header: 'Inicio',
            accessor: 'data_inicio',
            Footer: 'Inicio',
            disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return dataBRExibir(value)
                }
            },
        },
        {
            Header: 'Término',
            accessor: 'data_termino',
            Footer: 'Término',
            disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return dataBRExibir(value)
                }
            },
        },
    ]
}