import Layout from "../layouts"
import { Grid, List, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import ItemCadastro from "../cadastro/itemCadastro"

const itensProducao = [
    { id: 1, rota: '/producao/parcelas', titulo: 'Parcelas', descricao: 'Produção das parcelas de serviços com priodicidade mensal e anual.' },
    // { id: 2, rota: '', titulo: 'Teste', descricao: 'teste.' },
]
itensProducao.sort((r1, r2) => r1.titulo > r2.titulo ? 1 : -1)

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function Producao() {
    
    
    
    return (
        <Layout
            titulo="Produção"
            subtitulo="Rotinas de produção do sistema"
        >
            <Grid 
                container 
                spacing={2}
                display='flex'
                flexDirection={'column'}
            >
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Produção
                    </Typography>
                </Grid>
                <Demo>
                    <List dense={true}>
                        <Grid container >
                            {itensProducao.map(item => (
                                <Grid item xs={12} md={6} lg={4} key={item.id}>
                                    <ItemCadastro  
                                        item={item}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Demo>
            </Grid>
        </Layout>
    )
}

export default Producao