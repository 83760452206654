import { InputAdornment, TextField } from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'

export const FiltroColuna = ({ column }) => {
    const { filterValue, setFilter } = column
    return (
        <span>
            <TextField
                variant="outlined" 
                color="secondary"
                size="small"
                margin="normal"
                id="pesquisarColuna"
                label="Pesquisar"
                name="pesquisarColuna"
                autoFocus
                value={filterValue || ''}
                onChange={e => setFilter(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <SearchIcon />
                        </InputAdornment>
                    ) 

                }}
                sx={{
                    padding:0,
                    margin:0,
                    marginTop:'5px',
                    maxWidth:'250px',
                }}
                
            />
        </span>
    )
}