import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'

const initialState = {
    loading: true,
    registros: [],
    vinculados:[],
    error: {}
}

export const listarMovimentosConta = createAsyncThunk('contas/listar/movimentosConta', (conta_id) => {
    const autorizacao = header()
    return api
        .get(`/movimentos/conta/${conta_id}`, autorizacao)
        .then(res => res.data)
})

export const listarMovimentosPorPeriodo = createAsyncThunk('contas/listar/movimentosPorPeriodo', (registro) => {
    const autorizacao = header()
    return api
        .post('/movimentos/listarMovimentoPorPeriodo', registro, autorizacao)
        .then(res => res.data)
})

export const salvarMovimento = createAsyncThunk('contas/movimento/salvar', (registro) => {
    const autorizacao = header()
    return api
        .post('movimentos/salvar', registro, autorizacao)
        .then(res => res.data)
})

export const excluirMovimento = createAsyncThunk('contas/movimento/excluir', (id) => {
    const autorizacao = header()
    return api
        .get(`/movimentos/excluir/${id}`, autorizacao)
        .then(res => {
            return res.data
        })
})

export const listarVinculados = createAsyncThunk('contas/movimento/vinculos/listar', (registro) => {
    const autorizacao = header()
    //console.log('listarVinculados', registro)
    return api
        .post('movimentos/vinculos/listar', registro, autorizacao)
        .then(res => res.data)
})

export const salvarVinculos =  createAsyncThunk('contas/movimento/vinculos/salvar', (registro) => {
    const autorizacao = header()
    return api
        .post('movimentos/vinculos/salvar', registro, autorizacao)
        .then(res => res.data)
})

export const slice = createSlice({
    name: 'movimentos',
    initialState: initialState,
    extraReducers: builder => {
        //Listar Movimentos por Conta
        builder.addCase(listarMovimentosConta.pending, state => {
            state.loading = true
        })
        builder.addCase(listarMovimentosConta.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(listarMovimentosConta.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        // Listar movimento por conta por Período
        builder.addCase(listarMovimentosPorPeriodo.pending, state => {
            state.loading = true
        })
        builder.addCase(listarMovimentosPorPeriodo.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = {}
        })
        builder.addCase(listarMovimentosPorPeriodo.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        // Salvar Movimento
        builder.addCase(salvarMovimento.pending, state => {
            state.loading = true
        })
        builder.addCase(salvarMovimento.fulfilled, (state, action) => {
            state.loading = false
            const index = state.registros.findIndex(registro => registro.id === action.payload.registro.id)
            if (index === -1) {
                state.registros.push(action.payload.registro)
            } else {
                state.registros[index] = {
                    ...state.registros[index],
                    ...action.payload.registro
                }
            }
            state.error = ''
            state.error = []
        })
        builder.addCase(salvarMovimento.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        // Excluir movimento
        builder.addCase(excluirMovimento.pending, state => {
            state.loading = true
        })
        builder.addCase(excluirMovimento.fulfilled, (state, action) => {
            const id = parseInt(action.payload)
            state.loading = false
            state.registros = state.registros.filter((registro) => registro.id !== id)
            state.error = {}
        })
        builder.addCase(excluirMovimento.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
        // Parcelas vinculadas
        builder.addCase(listarVinculados.fulfilled, (state, action) => {
            state.loading = false
            state.vinculados = action.payload
            state.error = {}
        })
        
    }
})

export default slice.reducer