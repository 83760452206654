import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux'
import { excluirTipoConta, listarTiposConta } from '../../../../redux/sistemas/contas/tiposContaSlice';
import { useEffect } from 'react';
import { useState } from 'react';
import { Box } from '@mui/material';
import Loading from '../../../layouts/Loading';
import DialogErro from '../../../layouts/DialogErro';
import { dataHoraBRExibir } from '../../../../funcoes/data';
import FormConta from './formTiposConta';
import DialogExclusao from '../../../layouts/DialogExclusao';

export const TableTiposConta = (props) => {

    const { tiposConta, error, listarTiposConta, excluirTipoConta } = props
    const [openDialogErro, setOpenDialogErro] = useState(false)
    const [atualizarLista, setAtualizarLista] = useState(true)

    // eslint-disable-next-line no-unused-vars
    const [idExclusao, setIdExclusao] = useState('')

    useEffect(() => {
        if (atualizarLista) {
            listarTiposConta()
            setAtualizarLista(false)
        }
        if (error) setOpenDialogErro(true)
    }, [atualizarLista, error, listarTiposConta])

    const rows = tiposConta.registros

    const handleExcluir = idExclusao => {
        excluirTipoConta(idExclusao)
    }

    return (
        <Box>
            {tiposConta.loading ? <Loading /> : tiposConta.error ? (
                <DialogErro
                    openDialogErro={openDialogErro}
                    setOpenDialogErro={setOpenDialogErro}
                    dialogError={error}
                    mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
                />
            ) : (
                <>
                    <FormConta
                        registro={null}
                        acao='add'
                        tiposConta ={ tiposConta.registros}
                    />

                    <TableContainer component={Paper}>

                        <Table sx={{ minWidth: 650, mt: '20px' }} aria-label="simple table">
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'Silver' }}>
                                    <TableCell>#</TableCell>
                                    <TableCell>ID</TableCell>
                                    <TableCell>NOME</TableCell>
                                    <TableCell>DESCRIÇÃO</TableCell>
                                    <TableCell align="center">INCLUSÃO</TableCell>
                                    <TableCell align="center">ALTERAÇÃO</TableCell>
                                    <TableCell align="center">AÇÕES</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">{index + 1}</TableCell>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell>{row.descricao}</TableCell>
                                        <TableCell align="center">{dataHoraBRExibir(row.created_at)}</TableCell>
                                        <TableCell align="center">{dataHoraBRExibir(row.updated_at)}</TableCell>
                                        <TableCell >
                                            <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                                                <FormConta
                                                    registro={row}
                                                    acao='alt'
                                                    tiposConta = {tiposConta.registros}
                                                />
                                                <DialogExclusao
                                                    title="o Tipo de Conta "
                                                    referencia={`Tipos de Conta: ${row.nome} / ${row.descricao}`}
                                                    idExclusao={row.id}
                                                    setIdExclusao={setIdExclusao}
                                                    handleExcluir={handleExcluir}
                                                />
                                            </Box>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )
            }
        </Box >
    );
}

const mapStateToProps = state => {
    return {
        tiposConta: {
            loading: state.tiposConta.loading,
            registros: state.tiposConta.registros,
            error: state.tiposConta.error
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listarTiposConta: () => dispatch(listarTiposConta()),
        excluirTipoConta: (id) => dispatch(excluirTipoConta(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableTiposConta)