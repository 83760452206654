import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormDadosGerais from './FormDadosGerais';
import { useState } from 'react'
import FormDadosPF from './FormDadosPF';
import FormDadosPJ from './FormDadosPJ';
import FormEnderecos from './FormEnderecos';
import FormRedesSociais from './FormRedesSociais';
import FormTelefones from './FormTelefones';
import { useSelector } from 'react-redux';
import { selectAbasPessoa } from '../../../redux/auxiliar/abasPessoaSlice';

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: 1, width: '100%' }} >
                    {children}
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function FormAdicionar(props) {
    const [value, setValue] = useState(0);
    const [alterado, setAlterado] = useState(false)

    const abasPessoa = useSelector(selectAbasPessoa)
    // const pessoaAtual = useSelector((state) => state.pessoaAtual)
    // console.log('pessoaAtual em formAdicionar:', pessoaAtual)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Typography variant="h4" gutterBottom component="div" sm={12} sx={{ textAlign: 'center', paddingTop: '10px' }}>
                Incluir Nova Pessoa
            </Typography>
            <Box
                sx={{ flexGrow: 1,  display: 'flex', height: '100vh' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab label="Dados Gerais" {...a11yProps(0)} disabled={abasPessoa.status.dadosGerais} />
                    <Tab label="Pessoa Física" {...a11yProps(1)} disabled={abasPessoa.status.pessoaFisica} />
                    <Tab label="PessoaJurídica" {...a11yProps(2)} disabled={abasPessoa.status.pessoaJuridica} />
                    <Tab label="Endereços" {...a11yProps(3)} disabled={abasPessoa.status.enderecos} />
                    <Tab label="Redes Sociais" {...a11yProps(4)} disabled={abasPessoa.status.redesSociais} />
                    <Tab label="Telefones" {...a11yProps(5)} disabled={abasPessoa.status.telefones} />

                </Tabs>

                <TabPanel
                    value={value}
                    index={0} 
                >
                    <FormDadosGerais
                        //pessoaId={pessoaId}
                        setModo={props.setModo}
                        setValue={setValue}
                        alterado={alterado}
                        setAlterado={setAlterado}
                    />
                </TabPanel>

                <TabPanel
                    value={value}
                    index={1}
                >
                    <FormDadosPF
                        setModo={props.setModo}
                        setValue={setValue}
                        setAlterado={setAlterado}
                    />
                </TabPanel>
                
                <TabPanel 
                    value={value} 
                    index={2}
                >
                    <FormDadosPJ
                        setModo={props.setModo}
                        setValue={setValue}
                        setAlterado={setAlterado}
                    />
                </TabPanel>

                <TabPanel 
                    value={value} 
                    index={3}
                >
                    <FormEnderecos
                        setModo={props.setModo}
                        setValue={setValue}
                        value={value}
                        setAlterado={setAlterado}
                    />
                </TabPanel>

                <TabPanel 
                    value={value} 
                    index={4}
                >
                    <FormRedesSociais
                        setModo={props.setModo}
                        setValue={setValue}
                        setAlterado={setAlterado}
                    />
                </TabPanel>

                <TabPanel 
                    value={value} 
                    index={5}
                >
                    <FormTelefones
                        setModo={props.setModo}
                        setValue={setValue}
                        setAlterado={setAlterado}
                    />
                </TabPanel>
            </Box>
        </>
    );
}