import { addYears, format } from 'date-fns'
import addMonths from 'date-fns/addMonths'
//import ptBR from 'date-fns/locale/pt-BR';

export function checarData(data) {
    if (!data) {
        return false
    }
    const d = new Date(data)
    return d instanceof Date && !isNaN(d)
}

export function dataBRExibir(data) {

    if (data) {
        let dt = corrigirFuso(data)
        let dt1 = new Intl.DateTimeFormat('pt-BR').format(dt)
        return dt1
    }
    return ''
}

export function dataHoraBRExibir(data) {
    const options = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
    }
    if (data) {
        const dt = new Date(data)
        let dt1 = new Intl.DateTimeFormat('pt-BR', options).format(dt)
        return dt1
    }
    return ''
}

export function valorMoeda(value) {
    if (value === '' || value === 0.00) return ''
    return Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(parseFloat(value)) || ''
}

export function valorInteiro(value) {
    if (value === '') return ''
    return Intl.NumberFormat('pt-br', { maximumSignificantDigits: 5 }).format(value)
}

export function adicionarMes(data) {
    if (data) {
        let dt = corrigirFuso(data)
        let dtAdicionada = addMonths(dt, 1)
        return format(dtAdicionada, 'yyyy-MM-dd')
    }
    return ''
}
export function adicionarAno(data) {
    if (data) {
        let dt = corrigirFuso(data)
        let dtAdicionada = addYears(dt, 1)
        return format(dtAdicionada, 'yyyy-MM-dd')
    }
    return ''
}
export function adicionarMeses(data, qtde) {
    if (data) {
        let dt = corrigirFuso(data)
        let dtAdicionada = addMonths(dt, qtde)
        return format(dtAdicionada, "yyyy-MM-dd")
    }
    return ''
}

function corrigirFuso(dt) {
    const dtDate = new Date(dt)
    const dtDateOnly = new Date(dtDate.valueOf() + dtDate.getTimezoneOffset() * 60 * 1000);
    //const dtDateOnly = new Date(dtDate.valueOf() + dtDate.getTimezoneOffset() * 1);
    //const dtDateOnly = dtDate
    return dtDateOnly
}

export function validarPeriodo(dtInicio, dtFim) {
    let msg = ''
    let validado = true
    if (dtInicio === '') {
        msg = 'Data inicial não informada ou inválida.'
        validado = false
    } else if (dtFim === '') {
        msg = 'Data final não informada ou inválida.'
        validado = false
    } else if (dtInicio > dtFim) {
        msg = 'A data final é anterior a data de inicio'
        validado = false
    }
    return { validado, msg }
}
