import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, Grid, IconButton, InputAdornment, MenuItem, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit'
import SaveIcon from '@mui/icons-material/Save'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { salvarConta } from '../../../../redux/sistemas/contas/contasSlice';

const validationSchema = yup.object({
    banco_id: yup
        .string('Selecione o Banco')
        .required('Um banco precisa ser selecionado'),
    agencia_id: yup
        .string('Selecione a Agência')
        .required('Uma Agencia precisa ser selecionada'),
    tipo_id: yup
        .string('Selecione o Tipo de Conta')
        .required('Um Tipo de Conta precisa ser selecionado'),
    numero: yup
        .string('Informe o Número da Conta')
        .required('Um Número de Conta é obrigatório'),
    saldo: yup
        .number('Informe o saldo atual da conta')
        .required('Um Saldo precisa ser informado. (p. ex. 0,00)'),
});

export const FormConta = (props) => {

    const { registro, salvarConta, acao, bancos, agencias, tiposConta } = props
    const [open, setOpen] = useState(false);

    const formik = useFormik({
        initialValues: {
            id: registro?.id || '',
            banco_id: registro?.banco_id || '',
            agencia_id: registro?.agencia_id || '',
            tipo_id: registro?.tipo_id || '',
            numero: registro?.numero || '',
            saldo: registro?.saldo || ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            salvarConta(values)
            handleClose()
        },
    })

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                acao === "alt" ? (
                    <Tooltip title={<Box sx={{ fontSize: '0.9rem' }}>Alterar dados</Box>} placement="top" >
                        <IconButton
                            color="primary"
                            onClick={handleClickOpen}
                            size="small"
                        >
                            <EditIcon color='success' />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Button
                        variant="contained"
                        color="third"
                        onClick={handleClickOpen}
                        startIcon={<AddIcon />}
                    >
                        Adicionar Conta
                    </Button>
                )
            }
            <Dialog open={open} onClose={handleClose} fullWidth >
                <Grid
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <DialogTitle>{acao === "alt" ? "Alterar Dados da Conta" : "Adicionar Nova Conta"}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <Box
                            mt={2}
                            display='flex'
                            flexDirection={'column'}
                            gap={3}
                        >
                            <TextField
                                select
                                size='small'
                                fullWidth
                                value={formik.values.banco_id}
                                name="banco_id"
                                label="Banco"
                                onChange={(event) => {
                                    formik.handleChange(event)
                                    formik.setFieldValue('agencia_id', '')
                                }}
                                error={formik.touched.banco_id && Boolean(formik.errors.banco_id)}
                                helperText={formik.touched.banco_id && formik.errors.banco_id}
                            >
                                {bancos.map((banco, index) => (
                                    <MenuItem value={banco.id} key={index}>{banco.nome}</MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                size='small'
                                fullWidth
                                value={formik.values.agencia_id}
                                name="agencia_id"
                                label="Agência"
                                onChange={formik.handleChange}
                                error={formik.touched.agencia_id && Boolean(formik.errors.agencia_id)}
                                helperText={formik.touched.agencia_id && formik.errors.agencia_id}
                            >
                                {
                                    agencias.filter(agencia => agencia.banco_id === formik.values.banco_id)
                                        .map((ag, index) => (
                                            <MenuItem value={ag.id} key={index}>{ag.nome}</MenuItem>
                                        ))
                                }
                            </TextField>
                            <TextField
                                select
                                size='small'
                                fullWidth
                                value={formik.values.tipo_id}
                                name="tipo_id"
                                label="Tipo de Conta"
                                onChange={formik.handleChange}
                                error={formik.touched.tipo_id && Boolean(formik.errors.tipo_id)}
                                helperText={formik.touched.tipo_id && formik.errors.tipo_id}
                            >
                                {tiposConta.map((tipoConta, index) => (
                                    <MenuItem value={tipoConta.id} key={index}>{tipoConta.nome}</MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                size='small'
                                fullWidth
                                name="numero"
                                label="Número da Conta"
                                value={formik.values.numero}
                                onChange={formik.handleChange}
                                error={formik.touched.numero && Boolean(formik.errors.numero)}
                                helperText={formik.touched.numero && formik.errors.numero}
                            />
                            <TextField
                                size='small'
                                type="number"
                                fullWidth
                                name="saldo"
                                label="Saldo da Conta"
                                value={formik.values.saldo}
                                onChange={formik.handleChange}
                                error={formik.touched.saldo && Boolean(formik.errors.saldo)}
                                helperText={formik.touched.saldo && formik.errors.saldo}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions >
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            startIcon={<SaveIcon />}
                        >
                            Salvar
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            type="reset"
                            onClick={handleClose}
                            startIcon={<ArrowBackIcon />}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Grid>
            </Dialog>
        </div>
    );
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = dispatch => {
    return {
        salvarConta: registro => dispatch(salvarConta(registro)),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormConta)