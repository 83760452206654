import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../config/configApi'
import header from '../../middleware/jwtInterceptor'

const initialState ={
    loading: true,
    registros: [],
    servicoAtual: {},
    error: '',
}

export const fetchServicos = createAsyncThunk('cadastro/servicos', () => {
    const autorizacao = header()
    return api
        .get('servicos/lista', autorizacao)
        .then(res => res.data)
})

export const saveServico = createAsyncThunk('cadastro/servicos/saveServico', (registro) => {
    const autorizacao = header()
    return api
        .post(`/servicos/salvar`, registro, autorizacao)
        .then((res) => res.data)
})

export const deleteServico = createAsyncThunk('cadastro/servicos/delete', (registro) => {
    const autorizacao = header()
    return api
        .delete(`/servicos/excluir/${registro.id}`, autorizacao)
        .then((res)=> res.data)
})

export const slice = createSlice({
    name:'servicos',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchServicos.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchServicos.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchServicos.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        builder.addCase(saveServico.pending, state => {
            state.loading = true
        })
        builder.addCase(saveServico.fulfilled, (state, action) => {
            state.loading = false
            state.servicoAtual = action.payload
            state.error = ''
        })
        builder.addCase(saveServico.rejected, (state, action) => {
            state.loading = false
            state.servicoAtual = {}
            state.error = action.error.message
        })

        builder.addCase(deleteServico.pending, state => {
            state.loading = true
        })
        builder.addCase(deleteServico.fulfilled, (state, action) => {
            state.loading = false
            state.servicoAtual = action.payload
            state.error = ''
        })
        builder.addCase(deleteServico.rejected, (state, action) => {
            state.loading = false
            state.servicoAtual = {}
            state.error = action.error.message
        })
    }
}) 

export default slice.reducer

