import { Grid, Typography } from '@mui/material'
import React from 'react'
import Layout from '../layouts'
import Logo from '../../img/logo-site-ninja.png'

function paginaNaoEncontrada() {
    return (
        <Layout
            titulo="ERRO"
            subtitulo="Página não encontrada"
        >
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
            }}>
                <Grid item
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{ p: 1, width: { lg: '500px', md: '400px', sm: '350px', xs: '300px', } }}
                >
                    <img
                        src={Logo}
                        alt='Logotipo Ideia Ninja'
                        loading="lazy"
                        width={'100%'}
                    />
                </Grid>
                <Grid item>
                    <Typography sx={{ fontSize: { lg: '60px', md: '50px', sm: '40px', xs: '30px', } }}>
                        SAIN
                    </Typography>
                </Grid>
                <Grid item 
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}   
                >
                    <Typography sx={{ fontSize: { lg: '30px', md: '25px', sm: '20px', xs: '18px', } }}>
                        Sistema de Administração Idéia Ninja
                    </Typography>
                    <Typography align="center" sx={{ fontSize: { lg: '30px', md: '25px', sm: '20px', xs: '18px', }, color: 'red', mt:'25px' }}>
                        Página não encontrada. Tente novamente. Se o problema persisitir, entre em contato com o administrador do sistema.
                    </Typography>
                </Grid>
                {/* <Grid item mt={10}>
                    <Typography align="center" sx={{ fontSize: { lg: '30px', md: '25px', sm: '20px', xs: '18px', }, color: 'red' }}>
                        Página não encontrada. Tente novamente. Se o problema persisitir, entre em contato com o administrador do sistema.
                    </Typography>
                </Grid> */}
            </Grid>
        </Layout>
    )
}

export default paginaNaoEncontrada