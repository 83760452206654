import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'

const initialState = {
    loading: true,
    registros: [],
    error: {}
}

export const listarAgencias = createAsyncThunk('contas/listar/agencias', () => {
    const autorizacao = header()
    return api
        .get('/agencias', autorizacao)
        .then(res => res.data)
})

export const listarAgenciasPorBanco = createAsyncThunk('contas/listar/agencias/banco', (banco_id) => {
    const autorizacao = header()
    return api
        .get(`/agencias/banco/${banco_id}`, autorizacao)
        .then(res => res.data)
})

export const salvarAgencia = createAsyncThunk('contas/salvar/agencia', (registro) => {
    const autorizacao = header()
    return api
        .post('/agencias/salvar', registro, autorizacao)
        .then(res => res.data)
})

export const excluirAgencia = createAsyncThunk('contas/excluir/agencia', id => {
    const autorizacao = header()
    return api
        .get(`/agencias/excluir/${id}`, autorizacao)
        .then(res=> res.data)
})

export const slice = createSlice({
    name: 'agencias',
    initialState: initialState,
    extraReducers: builder => {
        //Listar Agencias
        builder.addCase(listarAgencias.pending, state => {
            state.loading = true
        })
        builder.addCase(listarAgencias.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(listarAgencias.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
        // Listar Agencias por Banco
        builder.addCase(listarAgenciasPorBanco.pending, state => {
            state.loading = true
        })
        builder.addCase(listarAgenciasPorBanco.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(listarAgenciasPorBanco.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
        // Salvar Agência
        builder.addCase(salvarAgencia.pending, state => {
            state.loading = true
        })
        builder.addCase(salvarAgencia.fulfilled, (state, action) => {
            
            const index = state.registros.findIndex(registro => registro.id === action.payload.registro.id)
            if (index === -1) {
                state.registros.push(action.payload.registro)
            } else {
                state.registros[index] = {
                    ...state.registros[index],
                    ...action.payload.registro
                }
            }
            state.loading = false
        })
        // Excluir Agência
        builder.addCase(excluirAgencia.pending, state => {
            state.loading = true
        })
        builder.addCase(excluirAgencia.fulfilled, (state, action) => {
            const id = parseInt(action.payload)
            state.registros = state.registros.filter((registro) => registro.id !== id)
            state.loading = false
        })
    }
})

export default slice.reducer