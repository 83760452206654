import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'

import { useState } from 'react'

export default function ExibirColunas(props) {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => { setAnchorEl(event.currentTarget)}
    const handleClose = () => { setAnchorEl(null)}

    return (
        <div >
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={props.endIcon}
                color='secondary'
            >
                {props.title}
            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {props.children}
            </Menu>
        </div>
    );
}
