import { alpha, Box, Checkbox, FormControlLabel, IconButton, Paper, Switch, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useState } from 'react';
import { dataBRExibir, valorMoeda } from '../../../funcoes/data';
import TableCell from '@mui/material/TableCell';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ConfirmarPagamento from './ConfirmarRecebimento'
import AlterarRecebimento from '../fechamentoPeriodo/AlterarRecebimento';
import { fetchAReceber } from '../../../redux/cadastro/recebimentoSlice';
import { connect } from 'react-redux';
import { setRefreshContasAPagar } from '../../../redux/cadastro/controleSlice';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'cliente',
    numeric: false,
    disablePadding: true,
    label: 'Cliente',
  },
  {
    id: 'projeto',
    numeric: false,
    disablePadding: false,
    label: 'Projeto',
  },
  {
    id: 'servico',
    numeric: false,
    disablePadding: false,
    label: 'Serviço',
  },
  {
    id: 'numero_parcela',
    numeric: true,
    disablePadding: false,
    label: 'Nº',
  },
  {
    id: 'data',
    numeric: true,
    disablePadding: false,
    label: 'Vencimento',
  },
  {
    id: 'valor',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
  },
  {
    id: 'status_recebimento_id',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'origem_recebimento_id',
    numeric: false,
    disablePadding: false,
    label: 'Origem',
  },
];

function EnhancedTableHead(props) {

  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts', }}
          />
        </TableCell>
        {
          headCells.map( (headCell) => {
            return (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  <strong>{headCell.label}</strong>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'ascendente' : 'descendente'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
          }
          )
        }
        <TableCell><strong>Ações</strong></TableCell>
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, selected, recebimentos } = props
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 2, sm: 1 },
        ...(numSelected) > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color='inherit'
          variant='subtitle1'
          component="div"
        >
          {numSelected} Selecionados
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Recebimentos
        </Typography>
      )}
      {numSelected > 0 ? (
        <Box display='flex' >


          <ConfirmarPagamento
            numSelected={numSelected}
            selected={selected}
            recebimentos={recebimentos}
          />


          <Tooltip placement={'top'} title={<Box sx={{ fontSize: '1.0rem' }}>Excluir {numSelected} Registros</Box>}>
            <IconButton
              onClick={() => alert('Esta função ainda não foi desenvolvida. caso seja necessário, exclua o recebimento diretamente na tabela (CADASTRO->Recebimentos).')}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  )

}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
}

function TableContasAReceber(props) {

  const [atualizado, setAtualizado] = useState(true)

  const {
    recebimentos,
    //refreshContasAPagar, 
    intervalo,
    refresh,
    carregarRecebimentos,
  } = props

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(500);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = recebimentos.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - recebimentos.length) : 0;

  function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };



    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  if (refresh) {
    carregarRecebimentos(intervalo)
  }

  return (
    <Box sx={{ width: '100%' }}>

      <Paper sx={{ width: '100%', mb: 2 }}>

        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
          recebimentos={recebimentos}
        />

        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={recebimentos.length}
            />
            <TableBody>

              {
                stableSort(recebimentos, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${index}`

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId, }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding='none'
                        >
                          {row.id}
                        </TableCell>
                        <TableCell>{row.projeto.cliente.nome}</TableCell>
                        <TableCell>{row.projeto.nome}</TableCell>
                        <TableCell>{row.servico.nome}</TableCell>
                        <TableCell align="right">{row.numero_parcela}</TableCell>
                        <TableCell align="right">{dataBRExibir(row.data)}</TableCell>
                        <TableCell align="right">{valorMoeda(row.valor)}</TableCell>
                        <TableCell>{row.statusRecebimento.nome}</TableCell>
                        <TableCell>{row.origemRecebimento?.nome}</TableCell>
                        <TableCell>
                          <AlterarRecebimento
                            registro={row}
                            intervalo={intervalo}
                            atualizado={atualizado}
                            setAtualizado={setAtualizado}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
              }
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500, { value: -1, label: 'Todos' }]}
          component="div"
          count={recebimentos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' de ' + count
            }
          }
          labelRowsPerPage='Linhas por página:'
        />

      </Paper>

      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Linhas densas"
      />
    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.recebimentos.loading,
    recebimentos: state.recebimentos.registros,
    error: state.recebimentos.error,
    refresh: state.recebimentos.refresh,
    //refreshContasAPagar: state.controle.refreshContasAPagar
  }
}

const mapDispatchToProps = dispatch => {
  return {
    carregarRecebimentos: intervalo => dispatch(fetchAReceber(intervalo)),
    setRefreshContasAPagar: status => dispatch(setRefreshContasAPagar(status))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TableContasAReceber)


//https://codesandbox.io/s/ck9c0l?file=/demo.js:6358-6409