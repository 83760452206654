// import { format } from "date-fns"
import { checarData, dataBRExibir } from "../../../funcoes/data"

export default function COLUMNS() {

    return [
        {
            Header: 'Id',
            accessor: 'id',
            Footer: 'Id',
            disableFilters: true,
            sticky: 'left'
        },
        {
            Header: 'Cliente',
            accessor: 'projeto.cliente.nome',
            Footer: 'Cliente',
            sticky: 'left'
        },
        {
            Header: 'Projeto',
            accessor: 'projeto.nome',
            Footer: 'Projeto',
            sticky: 'left'
        },
        {
            Header: 'Serviço',
            accessor: 'servico.nome',
            Footer: 'Serviço',
            sticky: 'left'
        },
        // {
        //     Header: 'Origem',
        //     accessor: 'origemRecebimento.nome',
        //     Footer: 'Origem',
        //     sticky: 'left'
        // },
        {
            Header: 'Nº',
            accessor: 'numero_parcela',
            Footer: 'Nº',
            sticky: 'right',
            width:'1px',
            // Cell: ({ value }) => {
            //     if (checarData(value)) {
            //         return (
            //             <span style={{display:'block', textAlign: 'center'}}>
            //                 {value}
            //             </span>
            //         )
            //     }
            // },
        },
        {
            Header: 'Vencimento',
            accessor: 'data',
            Footer: 'Vencimento',
            // disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return (
                        <span style={{display:'block', textAlign: 'center'}}>
                            {dataBRExibir(value)}
                        </span>
                    )
                }
            },
        },
        {
            Header: 'Pagamento',
            accessor: 'data_pagamento',
            Footer: 'Pagamento',
            // disableFilters: true,
            Cell: ({ value }) => {
                if (checarData(value)) {
                    return (
                        <span style={{display:'block', textAlign: 'center'}}>
                            {dataBRExibir(value)}
                        </span>
                    )
                }
            },
        },
        {
            Header: 'Valor',
            accessor: 'valor',
            Footer: 'Valor',
            sticky: 'right',
            //align: "right",
            Cell: ({ value }) => {
                return (
                    <span style={{display:'block', textAlign: 'right'}}>
                        {Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value)}
                    </span>
                )
            },
        },
        {
            Header: 'Status',
            accessor: 'statusRecebimento.nome',
            Footer: 'Status',
            sticky: 'left'
        },
        {
            Header: 'Origem',
            accessor: 'origemRecebimento.nome',
            Footer: 'Origem',
            sticky: 'left'
        },
    ]
}