import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'
const autorizacao = header()

const initialState = {
    loading: true,
    registro: {},
    error: '',
}

export const fetchPessoaJuridica = createAsyncThunk('pessoaJuridica/fetchPessoaJuridica', (pessoa_id) => {
    return api
        .get(`pessoas/pessoaJuridica/${pessoa_id}`, autorizacao)
        .then((res) => res.data)
})

export const savePessoaJuridica = createAsyncThunk('pessoaJuridica/savePessoaJuridica', (registro) => {
    return api
        .post('pessoas/pessoaJuridica/salvar', registro, autorizacao)
        .then((res) => res.data.registro)
})

export const slice = createSlice({
    name: 'pessoaJuridica',
    initialState,
    reducers: {
        changePessoaJuridica(state, { payload }){
            return {...state, loading: false, registro: payload}
        }
    },
    extraReducers: builder  => {
        builder.addCase(fetchPessoaJuridica.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPessoaJuridica.fulfilled, (state, action) => {
            state.loading = false
            state.registro = action.payload
            state.error = ''
        })
        builder.addCase(fetchPessoaJuridica.rejected, (state, action)=> {
            state.loading = false
            state.registro = {}
            state.error = action.error.message
        })

        builder.addCase(savePessoaJuridica.pending, state => {
            state.loading = true
        })
        builder.addCase(savePessoaJuridica.fulfilled, (state, action) => {
            state.loading = false
            state.registro = action.payload
            state.error = ''
        })
        builder.addCase(savePessoaJuridica.rejected, (state, action)=> {
            state.loading = false
            state.registro = {}
            state.error = action.error.message
        })
    }
})

export const { changePessoaJuridica } = slice.actions
export const selectPessoaJuridica = state => state.pessoaJuridica.registro
export default slice.reducer
