import React from 'react'
import { Box, FormControl } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

function Input(props) {

    const { label, name, ...rest } = props

    return (
        <Box>
            <FormControl fullWidth >
                <Field
                    component={TextField}
                    id={name}
                    name={name}
                    label={label}
                    size='small'
                    {...rest}
                />
            </FormControl>
        </Box>
    )
}

export default Input