import { fetchRecebimentos, fetchRecebimentosTodos } from '../../../redux/cadastro/recebimentoSlice'
import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination, } from 'react-table'
import { FiltroColuna } from '../../table/FiltroColunaComponent'
import AppBarComponent from '../../table/AppBarComponent'
import '../../ninja.css'
import BarraInferiorTable from '../../table/BarraInferiorTableComponent'
import { getExportFileBlob } from '../../../funcoes/exportTable'
import { useExportData } from 'react-table-plugins'
import COLUMNS from './colunas'
import Detalhes from './Detalhes'
import EditIcon from '@mui/icons-material/Edit'
import Excluir from './Excluir'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
//import Parcelas from '../parcelas/Parcelas'
const Table = (props) => {

    const { setModo } = props
    const dispatch = useDispatch()
    const [exibirExcluidos, setExibirExcluidos] = useState(false)
    const [registros, setRegistros] = useState([])
    


    useEffect(() => {
        if (exibirExcluidos) {
            dispatch(fetchRecebimentosTodos())
        } else {
            dispatch(fetchRecebimentos())
        }

    }, [dispatch, exibirExcluidos])

    const recebimentos = useSelector(state => state.recebimentos)


    useEffect(() => {
        let reg = recebimentos.registros.slice(0)
        reg.sort((r1, r2) => r1.id < r2.id ? 1 : -1)
        if (!exibirExcluidos) {
            reg = reg.filter(r => {
                return r.status_recebimento_id === 1 || r.status_recebimento_id === 3
            })
        }
        setRegistros(reg)
    }, [exibirExcluidos, recebimentos])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo(() => COLUMNS(), [])
    const data = useMemo(() => registros, [registros])

    const defaultColumn = useMemo(() => {
        return {
            Filter: FiltroColuna
        }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        exportData,

    } = useTable({
        columns,
        data,
        defaultColumn,
        getExportFileBlob,
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useExportData,
    )

    const { globalFilter, pageIndex, pageSize } = state

    const handleAlterar = (registro) => {
        setModo({ modo: 'alteracao', registro: registro })
    }

    // const handleClose = () => {
    //     setOpen(false);
    //   };
    //   const handleToggle = () => {
    //     setOpen(!open);
    //   };

    return (
        <Box>
            {
                recebimentos.loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="secondary"/>
                    </Backdrop>
                ) : recebimentos.error ? (
                    <Typography variant='body'>Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {recebimentos.error})</Typography>
                ) : (
                    <Box>
                        <AppBarComponent
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            setModo={props.setModo}
                            exibirExcluidos={exibirExcluidos}
                            setExibirExcluidos={setExibirExcluidos}
                            botaoExcluidos ={ true }
                            labelExibirExcluidos="Exibir todos"
                            exportData={exportData}
                            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                            allColumns={allColumns}
                        >
                        </AppBarComponent>
                        <table {...getTableProps()} style={{ marginTop: '150px' }}>
                            <thead>
                                {
                                    headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={index} >
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                    <Box
                                                        display='flex'
                                                        justifyContent='space-between'
                                                    >
                                                        {column.render('Header')}
                                                        <span>
                                                            {column.isSorted
                                                                ? (column.isSortedDesc
                                                                    ? <ArrowDropDownIcon
                                                                        sx={{ fontSize: '13px', color: 'yellow' }}
                                                                    />
                                                                    : <ArrowDropUpIcon
                                                                        sx={{ fontSize: '13px', color: 'yellow' }}
                                                                    />)
                                                                : null}
                                                        </span>
                                                    </Box>
                                                </th>
                                            ))}
                                            <th>Ações</th>
                                        </tr>
                                    ))
                                }
                                {
                                    headerGroups.map((headerGroup, index) => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                                            {headerGroup.headers.map((column) => (
                                                <th {...column.getHeaderProps()}
                                                >
                                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                </th>
                                            ))}
                                            <th></th>
                                        </tr>
                                    ))
                                }
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {
                                    page.map((row) => {
                                        prepareRow(row)
                                        const isPago = Boolean(row.original.status_recebimento_id === 2)
                                        return (
                                            <tr {...row.getRowProps()} >
                                                {
                                                    row.cells.map((cell) => {
                                                        return (
                                                            <td style={isPago ? { color: 'DarkGreen' } : { color: '#000' }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        )
                                                    })
                                                }
                                                <td>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Detalhes registro={row.original} />
                                                        <span onClick={() => handleAlterar(row.original)}>
                                                            <EditIcon color='success' sx={{ cursor: 'pointer', }} />
                                                        </span>
                                                        {!isPago ? (
                                                            <Excluir
                                                                recebimento={row.original}
                                                            />
                                                        ) : null}
                                                    </Box>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <BarraInferiorTable
                            pageIndex={pageIndex}
                            pageOptions={pageOptions}
                            gotoPage={gotoPage}
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageCount={pageCount}
                            pageSize={pageSize}
                            setPageSize={setPageSize}
                        />
                    </Box>
                )
            }
        </Box>
    )
}

export default Table