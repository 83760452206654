import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from '../../config/configApi'
import header from '../../middleware/jwtInterceptor'

const initialState = {
    loading: true,
    registros: [],
    registrosSalvos: [],
    registroAtual: {},
    error: '',
}

export const fetchPagamento = createAsyncThunk('cadastro/pagamento', (id) => {
    const autorizacao = header()
    return api  
        .get(`pagamentos/${id}`, autorizacao)
        .then((res) => res.data)
})

export const fetchPagamentos = createAsyncThunk('cadastro/pagamentos', () => {
    const autorizacao = header()
    return api
        .get("pagamentos/lista/ativos", autorizacao)
        .then(res => res.data)
})

export const fetchPagamentosAVincular = createAsyncThunk('cadastro/pagamentos/aVincular', () => {
    const autorizacao = header()
    return api
        .get("pagamentos/lista/aVincular", autorizacao)
        .then(res => res.data)
})

export const fetchFechamentoPagamento = createAsyncThunk('cadastro/pagar/fechamento', (intervalo) => {
    const autorizacao = header()
    return api
        .post('pagamentos/lista/fechamento/pagamentos', intervalo, autorizacao)
        .then(res => res.data)
})

export const fetchPagamentosTodos = createAsyncThunk('cadastro/pagamentos/todos', () => {
    const autorizacao = header()
    return api
        .get('pagamentos/lista/todos', autorizacao)
        .then(res => res.data)
})

export const fetchPagamentosClassificar = createAsyncThunk('cadastro/pagamentos/classificar', () => {
    const autorizacao = header()
    return api
        .get('pagamentos/lista/classificar', autorizacao)
        .then(res => res.data)
})

export const savePagamento = createAsyncThunk('cadastro/pagamentos/savePagamento', (registro) => {
    const autorizacao = header()
    return api
        .post(`/pagamentos/salvar`, registro, autorizacao)
        .then((res) => res.data)
})

export const saveAllPagamento = createAsyncThunk('cadastro/pagamentos/saveAllpagamento', (registros) => {
    const autorizacao = header()
    return api
        .post(`/pagamentos/salvarAll`, registros, autorizacao)
        .then((res) => res.data)
})

export const deletePagamento = createAsyncThunk('cadastro/pagamentos/delete', (registro) => {
    const autorizacao = header()
    return api
        .delete(`/pagamentos/excluir/${registro.id}`, autorizacao)
        .then((res) => res.data)
})

export const baixarVinculadosPagamentos = createAsyncThunk('cadastro/pagamentos/baixarVinculados', (registro) => {
    const autorizacao = header()
    return api
        .post('pagamentos/baixarVinculado', registro, autorizacao)
        .then((res) => res.data)
})

export const slice = createSlice({
    name: 'pagamentos',
    initialState,
    reducers: {
        addRegistro: (state, action) => {
            state.registros.push(action.payload)
        },
        deleteRegistro: (state, action) => {
            state.registros = state.registros.filter((registro) => registro.id !== action.payload.id)
        },
        resetPagamentos: (state, action) => {
            state.registros= []
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchPagamento.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPagamento.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(fetchPagamento.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })
        
        
        builder.addCase(fetchPagamentos.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPagamentos.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchPagamentos.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })


        
        builder.addCase(fetchPagamentosTodos.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPagamentosTodos.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchPagamentosTodos.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        builder.addCase(fetchPagamentosClassificar.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPagamentosClassificar.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchPagamentosClassificar.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        builder.addCase(savePagamento.pending, state => {
            state.loading = true
        })
        builder.addCase(savePagamento.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(savePagamento.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })

        builder.addCase(saveAllPagamento.pending, state => {
            state.loading = true
        })
        builder.addCase(saveAllPagamento.fulfilled, (state, action) => {
            state.loading = false
            state.registrosSalvos = action.payload
            state.error = ''
        })
        builder.addCase(saveAllPagamento.rejected, (state, action) => {
            state.loading = false
            state.registrosSalvos = []
            state.error = action.error.message
        })

        builder.addCase(deletePagamento.pending, state => {
            state.loading = true
        })
        builder.addCase(deletePagamento.fulfilled, (state, action) => {
            state.loading = false
            state.registroAtual = action.payload
            state.error = ''
        })
        builder.addCase(deletePagamento.rejected, (state, action) => {
            state.loading = false
            state.registroAtual = {}
            state.error = action.error.message
        })

        // Fechamento
        builder.addCase(fetchFechamentoPagamento.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchFechamentoPagamento.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchFechamentoPagamento.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })

        // A vincular
        builder.addCase(fetchPagamentosAVincular.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchPagamentosAVincular.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchPagamentosAVincular.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
    }
})

export const { addRegistro, deleteRegistro, resetPagamentos } = slice.actions
export default slice.reducer