import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'

function BarraInferiorTable(props) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '50px',
        }}>
            <Typography>
                Página {' '}
                {props.pageIndex + 1} de {props.pageOptions.length}
            </Typography>
            <Box>
                <Button
                    size="small"
                    variant='outlined'
                    onClick={() => props.gotoPage(0)}
                    color="primary"
                    disabled={!props.canPreviousPage}
                >{'<<'}</Button>
                <Button
                    size="small"
                    variant='outlined'
                    onClick={() => props.previousPage()}
                    color="primary"
                    disabled={!props.canPreviousPage}
                >Anterior</Button>
                <Button
                    size="small"
                    variant='outlined'
                    onClick={() => props.nextPage()}
                    color="primary"
                    disabled={!props.canNextPage}
                >Próxima</Button>
                <Button
                    size="small"
                    variant='outlined'
                    onClick={() => props.gotoPage(props.pageCount - 1)}
                    color="primary"
                    disabled={!props.canNextPage}
                >{'>>'}</Button>
            </Box>

            <TextField
                size="small"
                type="number"
                label="Ir para..."
                sx={{
                    width: '100px',
                }}
                defaultValue={props.pageIndex + 1}
                onChange={e => {
                    const pageNumber = e.target.value
                        ? Number(e.target.value) - 1
                        : 0
                    props.gotoPage(pageNumber)
                }}
            ></TextField>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="select-small">Itens por página</InputLabel>
                <Select
                    labelId="select-small"
                    id="select-small"
                    value={props.pageSize}
                    label="Itens por página"
                    onChange={e => props.setPageSize(Number(e.target.value))}
                >
                    {
                        [5, 10, 25, 50, 100, 500].map(pageSize => (
                            <MenuItem
                                key={pageSize}
                                value={pageSize}
                            >
                                {pageSize} linhas
                            </MenuItem>
                        ))
                    }
                    <MenuItem value={props.pageOptions.length * props.pageSize}>Tudo</MenuItem>

                </Select>
            </FormControl>
        </Box>
    )
}

export default BarraInferiorTable