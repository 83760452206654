import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'

const initialState = {
    loading: false,
    registros: [],
    error: ''
}

export const fetchStatus = createAsyncThunk('tabelas/status', () => {
    const autorizacao = header()
    return api
        .get('cadastro/status', autorizacao)
        .then((res) => res.data.registros.sort((r1, r2) => r1.nome > r2.nome ? 1 : -1))
})

const statusSlice = createSlice({
    name: 'status',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStatus.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchStatus.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchStatus.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
    }
})

export default statusSlice.reducer
export const selectStatus = state => state.status
