import { Backdrop, CircularProgress, Typography } from '@mui/material'
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { dataBRExibir } from '../../../../funcoes/data'
import { fetchAVincularTodos } from '../../../../redux/cadastro/recebimentoSlice'

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 60
    },
    {
        field: 'data',
        headerName: 'Vencimento',
        width: 100,
        valueFormatter: (params) => {
            if (params.value === null) { return '' }
            const dataFormatada = dataBRExibir(params.value)
            return dataFormatada
        } 
    },
    {
        field: 'cliente',
        headerName: 'Cliente',
        width: 163,
        valueGetter: (params) => params.row.projeto.cliente.nome
    },
    {
        type: 'number',
        field: 'valor',
        headerName: 'Valor',
        width: 120,
        valueFormatter: (params) => {
            if (params.value === null) { return '' }
            const valorFormatado = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(params.value)
            return valorFormatado
        }
    },
    // {
    //     field: 'status',
    //     headerName: 'Status',
    //     width: 170,
    //     valueGetter: (params) => params.row?.statusRecebimento.nome || ''
    // },
    // {
    //     field: 'origem',
    //     headerName: 'Origem',
    //     width: 170,
    //     valueGetter: (params) => params.row?.origemRecebimento?.nome || ''
    // },
    {
        field: 'projeto',
        headerName: 'Projeto',
        width: 170,
        valueGetter: (params) => params.row.projeto.nome
    },
    {
        field: 'servico',
        headerName: 'Serviço',
        width: 180,
        valueGetter: (params) => params.row.servico.nome
    }
]

export const TableRecebimentos = (props) => {
    const { 
        setVlrVinculado, 
        setVinculosSelecionados, 
        recebimentos, 
        listarRecebimentos 
    } = props

    const [selectionModel, setSelectionModel] = useState([])

    const linhasSelecionadas = selectionModel.map((s) => {
        return recebimentos.registros.find(r => r.id === s)
    })

    useEffect(() => {
        listarRecebimentos()
    }, [listarRecebimentos])

    useEffect(() => {
        setVlrVinculado(linhasSelecionadas.reduce((a, b) => a + parseFloat(b.valor), 0.00))
        setVinculosSelecionados(linhasSelecionadas)
    }, [linhasSelecionadas, setVinculosSelecionados, setVlrVinculado])

    return (
        <div style={{ height: 310, width: '100%' }}>
            {
                recebimentos.loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color='secondary' />
                    </Backdrop>
                ) : recebimentos.error ? (
                    <Typography variant='body'>
                        Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {recebimentos.error})
                    </Typography>
                ) : (
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        columns={columns}
                        rows={recebimentos.registros}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        components={{ Toolbar: GridToolbar }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel)
                        }}
                        selectionModel={selectionModel}
                        density={'compact'}
                    />
                )
            }

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        recebimentos: {
            loading: state.recebimentos.loading,
            registros: state.recebimentos.registros,
            error: state.recebimentos.error
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listarRecebimentos: () => dispatch(fetchAVincularTodos())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TableRecebimentos)

