import { Backdrop, Button, CircularProgress, Divider, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { checarData, dataBRExibir, valorMoeda } from '../../../funcoes/data'
import Layout from '../../layouts'
import '../relatorios.css'
import { fetchPagamentosClassificar } from '../../../redux/cadastro/pagamentoSlice'
import Classificar from './Classificar'

const sxTableHeader = {
    color: 'black',
    fontSize: '0.9rem',
    backgroundColor: '#DCDCDC',
    fontWeight: 'bold',
}

const sxTableCell = {
    fontSize: '0.8rem',

}

function PagamentosAClassificar(props) {

    const { loading, pagamentos, error, carregarPagamentos } = props

    const [dtInicio, setDtInicio] = useState('')
    const [dtFim, setDtFim] = useState('')
    const [pag, setPag] = useState(pagamentos)

    useEffect(() => {
        carregarPagamentos()
    }, [carregarPagamentos])

    const regraPagamento = (r) => {
        let regra = false
        if (r.data_vencimento >= dtInicio && r.data_vencimento <= dtFim) regra = true
        if (r.data_pagamento >= dtInicio && r.data_pagamento <= dtFim) regra = true
        return regra
    }

    const criarRelatorio = () => {
        const pagamentosFilter = pagamentos.filter((r) => regraPagamento(r))
        pagamentosFilter.sort((r1, r2) => r1?.data_vencimento < r2?.data_vencimento ? 1 : -1)
        setPag(pagamentosFilter)
    }

    const calcularTotalGeralPagamentos = (parcelas) => {
        return parcelas.map(({ valor }) => valor).reduce((sum, i) => sum + parseFloat(i), 0.00);
    }

    return (
        <Layout
            titulo="Pagamentos a classificar"
            subtitulo="Projeto despesas operacionais a classificar"
        >
            <Grid container>
                <Grid item xs={12} md={12} mb={0}>
                    <Typography sx={{ mt: 4 }} variant="h6" component="div">
                        Pagamentos a Classificar
                    </Typography>
                    <Typography sx={{ mt: 2 }} variant="body2" component="div" mb={1}>
                        Lista de pagamentos não vinculados a serviços que foram temporáriamento vinculados ao projeto "<strong>Despesas Operacionais</strong>" e ao serviço "<strong>A Classificar</strong>. Cada pagamento precisa estar vinculado a um serviço/projeto.
                    </Typography>
                    <Divider />
                </Grid>
                <Grid
                    container
                    rowSpacing={{ xs: 2, sm: 2, }}
                    columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                    alignItems="center"
                    justifyContent={'center'}
                    p={2}
                >
                    <Grid item xs={12} md={3} lg={2}>
                        <Typography variant="body" component="div">
                            Informe o Período:
                        </Typography>
                    </Grid>
                    <Grid item  >
                        <TextField
                            size="small"
                            type="date"
                            label={'de'}
                            InputLabelProps={{ shrink: true }}
                            value={dtInicio}
                            onChange={(e) => setDtInicio(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            size="small"
                            type="date"
                            label="até"
                            InputLabelProps={{ shrink: true }}
                            value={dtFim}
                            onChange={(e) => setDtFim(e.target.value)}
                        />
                    </Grid>
                    <Grid item >
                        <Button
                            variant='outlined'
                            onClick={() => criarRelatorio()}
                        >
                            Listar
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
            <Divider />
            {loading ? (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="secondary" />
                </Backdrop>
            ) : (
                <div>
                    {pag.length > 0 ? (
                        <TableContainer>
                            <Typography sx={{ mt: 4 }} variant="h6" component="div">
                                Pagamentos (Saídas)
                            </Typography>
                            <Table size="small">
                                <TableHead >
                                    <TableRow >
                                        <TableCell sx={sxTableHeader}>OR</TableCell>
                                        <TableCell sx={sxTableHeader} align="right">ID</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Fornecedor</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Tipo</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Nº</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Venc.</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Pag.</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Status</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Origem</TableCell>
                                        <TableCell sx={sxTableHeader} align="right">Valor</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Histórico</TableCell>
                                        <TableCell sx={sxTableHeader} align="left">Ações</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pag.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={sxTableCell} align="right">{index + 1}</TableCell>
                                            <TableCell sx={sxTableCell} align="right">{row.id}</TableCell>
                                            <TableCell align="left">{row.fornecedor.nome}</TableCell>
                                            <TableCell align="left">{row.tipoPagamento.nome}</TableCell>
                                            <TableCell sx={sxTableCell} align="left">{row.numero_parcela}</TableCell>
                                            <TableCell sx={sxTableCell} align="left">{checarData(row.data_vencimento) ? dataBRExibir(row.data_vencimento) : null}</TableCell>
                                            <TableCell sx={sxTableCell} align="left">{checarData(row.data_pagamento) ? dataBRExibir(row.data_pagamento) : null}</TableCell>
                                            <TableCell sx={sxTableCell} width={'6%'} align="left">{row.statusPagamento.nome}</TableCell>
                                            <TableCell sx={sxTableCell} width={'6%'} align="left">{row.origemPagamento?.nome}</TableCell>
                                            <TableCell sx={sxTableCell} align="right">{valorMoeda(row.valor)}</TableCell>
                                            <TableCell sx={sxTableCell}>{row.historico}</TableCell>
                                            <TableCell><Classificar registro={row} /></TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={6}></TableCell>
                                        <TableCell colSpan={3} align="right"><strong>TOTAL GERAL</strong></TableCell>
                                        <TableCell colSpan={3} align="right"><strong>{valorMoeda(calcularTotalGeralPagamentos(pag))}</strong></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                </div>
            )}
            {error ? (error) : null}
        </Layout >
    )
}

const mapStateToProps = state => {
    return {
        loading: state.pagamentos.loading,
        pagamentos: state.pagamentos.registros,
        error: state.pagamentos.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        carregarPagamentos: () => dispatch(fetchPagamentosClassificar())
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PagamentosAClassificar)
