import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    refreshContasAPagar: false,
}

export const slice = createSlice({
    name: 'controle',
    initialState,
    reducers: {
        setRefreshContasAPagar: (state, action) => {
            console.log('ativou (true)')
            console.log('ativou (true) payload', action.payload)
            state.refreshContasAPagar = action.payload
        },
    },
})

export const { setRefreshContasAPagar } = slice.actions
export default slice.reducer