import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import LinkIcon from '@mui/icons-material/Link';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useState } from 'react';
import { listarVinculados } from '../../../../redux/sistemas/contas/movimentosSlice'
import { makeStyles, createStyles } from '@material-ui/styles'
import { dataBRExibir, valorMoeda } from '../../../../funcoes/data';

const useStyles = makeStyles((theme) =>
    createStyles({
        textContainer: {
            display: 'block',
            width: '150px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    })
)


export const ViewVinculos = (props) => {
    const classes = useStyles()
    const { movimento, listarVinculos } = props
    const [open, setOpen] = useState(false)
    const [vinculos, setVinculos] = useState([])

    const handleClickOpen = async () => {
        const lista = await listarVinculos(movimento)
        setVinculos(lista.payload)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Tooltip title={<Box sx={{ fontSize: '0.9rem' }}>Exibir parcelas vinculadas</Box>} placement='top'>
                <IconButton
                    color='primary'
                    onClick={handleClickOpen}
                    size='small'
                >
                    <LinkIcon />
                </IconButton>
            </Tooltip>

            <Dialog
                open={open}
                fullWidth
                maxWidth='md'
                sx={{ minHeight: '100vh', maxHeight: '100vh' }}
            >
                <DialogTitle sx={{ p: 3 }}>
                    <Box>Parcelas de {movimento.natureza === 'D' ? 'pagamentos' : 'recebimentos'} vinculados</Box>
                </DialogTitle>
                <Divider />

                <DialogContent>
                    {
                        vinculos.length === 0 ? (
                            <p>Nenhum movimento vinculado encontrado.</p>
                        ) : (
                            <Table>
                                <TableHead>
                                    <TableRow >
                                        <TableCell>#</TableCell>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Projeto</TableCell>
                                        <TableCell>Serviço</TableCell>
                                        {
                                            movimento.natureza === "D" ? (
                                                <TableCell>Fornecedor</TableCell>
                                            ) : null
                                        }

                                        <TableCell align='center'>Data</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align='right'>Valor</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vinculos.map((m, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{m.id}</TableCell>
                                                <TableCell>{m.projeto.cliente.nome}</TableCell>
                                                <TableCell>{m.projeto.nome}</TableCell>
                                                <TableCell ><span className={classes.textContainer}>{m.servico.nome}</span></TableCell>
                                                {
                                                    movimento.natureza === "D" ? (
                                                        <TableCell>{m.fornecedor.nome}</TableCell>
                                                    ) : null
                                                }
                                                {
                                                    movimento.natureza === 'D' ? (
                                                        <TableCell>{dataBRExibir(m.data_vencimento)}</TableCell>
                                                    ) : (
                                                        <TableCell align='center'>{dataBRExibir(m.data)}</TableCell>
                                                    )
                                                }
                                                {
                                                    movimento.natureza === "D" ? (
                                                        <TableCell>{m.statusPagamento.nome}</TableCell>
                                                    ) : (
                                                        <TableCell>{m.statusRecebimento.nome}</TableCell>
                                                    )
                                                }

                                                <TableCell align='right'>{valorMoeda(m.valor)}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        )
                    }
                </DialogContent>
                <Divider />

                <DialogActions sx={{ px: 3, py: 2, gap: 1 }} >
                    <Grid container>
                        <Grid item xs={4}>
                            <Box display={'flex'} gap={2}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    type="reset"
                                    onClick={handleClose}
                                    startIcon={<ArrowBackIcon />}
                                >
                                    Voltar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div >
    )
}

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listarVinculos: (registro) => dispatch(listarVinculados(registro))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewVinculos)


// .textContainer {
//     display: block;
//     width: 200px;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }