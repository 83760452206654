import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'
import FormRecebimento from './Form'
import { useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelIcon from '@mui/icons-material/Cancel';

export default function Parcelas(props) {
    const { dados } = props
    const [open, setOpen] = useState(false);
    const [maxWidth] = useState('md')

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Gerador de Parcelas" placement="top">
                <IconButton
                    color="primary"
                    onClick={handleClickOpen}
                    size="small"
                >
                    <AttachFileIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                fullWidth={true}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth={maxWidth}
            >
                <DialogTitle sx={{ display: 'flex', gap: 2, backgroundColor: '#262626' }} color='white'>
                    Gerador de Parcelas
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <DialogContentText mt={2} mb={2}> */}
                        <Box mt={2} mb={2}>
                            <Typography component={'div'} variant="body1" gutterBottom><strong>Aqui você poderá gerar as parcelas de recebimento ou pagamento negociadas com o cliente ou fornecedor.</strong></Typography>
                            <Typography component={'div'} variant="body2" gutterBottom>Selecione o tipo de parcela a ser gerado, em seguida será apresentado o formulário com os dados necessários, preencha-os, salve a negociação, confira as parcelas que serão criadas e salve. Com base nos dados do serviço e das parcelas anteriores serão sugeridos os valores do formulário.</Typography>
                            <Typography component={'div'} variant="body2" gutterBottom>Para negociações com pagamento mensal, serão geradas parcelas somente para o ano corrente.</Typography>
                        </Box>
                    {/* </DialogContentText> */}

                    <FormRecebimento 
                        dados={dados.registro}
                        fecharForm ={handleClose}
                    />
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                        startIcon={<CancelIcon fontSize="small" />}
                    >
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
