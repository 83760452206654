import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Box, Divider, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import { useState } from 'react';
import { fetchOrigemRecebimento } from '../../../redux/cadastro/tabelas/origemRecebimentoSlice';
import { connect } from 'react-redux';
import { fetchStatusRecebimento } from '../../../redux/cadastro/tabelas/statusRecebimentoSlice';
import { baixarRecebidos, deleteRegistros } from '../../../redux/cadastro/recebimentoSlice'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/CancelTwoTone'
import CloseIcon from '@mui/icons-material/Close'

function ConfirmarRecebimento(props) {

    const {
        numSelected,
        selected,
        baixarRecebidos,
        origensRecebimento,
        statusRecebimento,
        deleteRegistros
    } = props

    const [dataPagamento, setDataPagamento] = useState('')
    const [optionOrigemSelecionado, setOptionOrigemSelecionado] = useState({ value: 1, label: 'Conta' })
    const [optionStatusSelecionado, setOptionStatusSelecionado] = useState({ value: 2, label: 'Pago' })

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSalvar = async () => {
        
        const registro = {
            data_pagamento: dataPagamento,
            origem_recebimento_id: optionOrigemSelecionado.value,
            status_recebimento_id: optionStatusSelecionado.value,
            itens: selected
        }
        
        await baixarRecebidos(registro)
        deleteRegistros(registro)

    }

    const origemRecebimentoOptions = origensRecebimento.dados?.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })
    const statusRecebimentoOptions = statusRecebimento.dados?.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })

    return (
        <div>
            <Tooltip placement={'top'} title={<Box sx={{ fontSize: '1.0rem' }}>Confirmar {numSelected} pagamento(s)</Box>}>
                <IconButton
                    onClick={() => handleClickOpen()}
                >
                    <CreditScoreIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Box display='flex' justifyContent={'space-between'} alignItems="center">
                        <Typography variant="h6">Confirmar Recebimentos</Typography>
                        <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>


                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} lg={4} >
                            <TextField
                                autoFocus
                                type={'date'}
                                size='small'
                                label="Data de Pagamento"
                                fullWidth
                                InputLabelProps={{ shrink: true, }}
                                onChange={(e) => setDataPagamento(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4} >
                            <Autocomplete
                                disablePortal
                                id="origem_recebimento_id"
                                name="origem_recebimento_id"
                                options={origemRecebimentoOptions}
                                value={optionOrigemSelecionado}
                                isOptionEqualToValue={(option) => option = optionOrigemSelecionado}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(event, newValue) => {

                                    setOptionOrigemSelecionado(newValue)

                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.value}>
                                        {option.label}
                                    </Box>
                                )}
                                renderInput={(params) => <TextField {...params} label="Origem" />}
                                size="small"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} lg={4} >
                            <Autocomplete
                                disablePortal
                                id="status_recebimento_id"
                                name="status_recebimento_id"
                                options={statusRecebimentoOptions}
                                value={optionStatusSelecionado}
                                isOptionEqualToValue={(option) => option = optionStatusSelecionado}
                                getOptionLabel={(option) => option.label || ''}
                                onChange={(event, newValue) => {

                                    setOptionStatusSelecionado(newValue)

                                }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.value}>
                                        {option.label}
                                    </Box>
                                )}
                                renderInput={(params) => <TextField {...params} label="Status" />}
                                size="small"
                            />
                        </Grid>
                    </Grid>
                    <Box mb={10} mt={2}>
                        <Typography variant="subtitle2" gutterBottom component="div">
                            O sistema realizará a baixa de {numSelected} registro(s) por pagamento, atraso, pendência ou prejuízo.
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" startIcon={<SaveIcon />} onClick={handleSalvar}>
                        Salvar
                    </Button>
                    <Button variant="outlined" startIcon={<CancelIcon />} autoFocus onClick={handleClose}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapStateToProps = state => {

    return {
        origensRecebimento: {
            loading: state.origemRecebimento.loading,
            dados: state.origemRecebimento.registros,
            error: state.origemRecebimento.error,
        },
        statusRecebimento: {
            loading: state.statusRecebimento.loading,
            dados: state.statusRecebimento.registros,
            error: state.statusRecebimento.error,
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        carregarOrigensRecebimento: dispatch(fetchOrigemRecebimento()),
        carregarStatusRecebimento: dispatch(fetchStatusRecebimento()),
        baixarRecebidos: (registro) => dispatch(baixarRecebidos(registro)),
        deleteRegistros: (registro) => dispatch(deleteRegistros(registro)),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarRecebimento)