import { Grid, List, Typography } from '@mui/material'
import Layout from '../../layouts'
import { styled } from '@mui/material/styles';
import ItemTabela from './itemTabela';

const itensCadastro = [
    { id: 1, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/formaspagamento', titulo: 'FORMAS DE PAGAMENTO', descricao: 'Formas de pagamento negociadas com o cliente para execução de um serviço.' },
    { id: 2, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/grupospessoas', titulo: 'GRUPOS DE PESSOAS', descricao: 'Grupos de Pessoas que interegem com o sistema.' },
    { id: 3, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/origenspagamento', titulo: 'ORIGENS DE PAGAMENTOS', descricao: 'Origens dos valores utilizados para os pagamentos.' },
    { id: 4, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/origensrecebimento', titulo: 'ORIGENS DE RECEBIMENTOS', descricao: 'Origens dos valores recebidos.' },
    { id: 5, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/periodicidadeservico', titulo: 'PERIODICIDADES DOS SERVIÇOS', descricao: 'Periodicidades dos serviços prestados.' },
    { id: 6, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/statuspagamento', titulo: 'STATUS DOS PAGAMENTOS', descricao: 'Situação atual dos pagamentos.' },
    { id: 7, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/statusprojeto', titulo: 'STATUS DOS PROJETOS', descricao: 'Situação dos projetos.' },
    { id: 8, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/statusrecebimento', titulo: 'STATUS DOS RECEBIMENTOS', descricao: 'Situação dos recebimentos.' },
    { id: 9, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/statusservico', titulo: 'STATUS DOS SERVIÇOS', descricao: 'Situação dos serviços.' },
    { id: 10, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tiposendereco', titulo: 'TIPOS DE ENDEREÇO', descricao: 'Tipos de endereço do cadastro.' },
    { id: 11, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tipospagamento', titulo: 'TIPOS DE PAGAMENTO', descricao: 'Tipos de pagamento do cadastro.' },
    { id: 12, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tiposprojeto', titulo: 'TIPOS DE PROJETO', descricao: 'Tipos de projeto do cadastro.' },
    { id: 13, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tiposredessociais', titulo: 'TIPOS DE REDES SOCIAIS', descricao: 'Tipos de redes sociais do cadastro.' },
    { id: 14, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tiposservico', titulo: 'TIPOS DE SERVIÇO', descricao: 'Tipos de serviço do cadastro.' },
    { id: 15, icon: '<FolderIcon/>', rota: '/cadastro/tabelas/tipostelefone', titulo: 'TIPOS DE TELEFONE', descricao: 'Tipos de telefone do cadastro.' },

]
itensCadastro.sort((r1, r2) => r1.titulo > r2.titulo ? 1 : -1)

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

function Tabelas() {
    return (
        <Layout
            titulo="Tabelas e Parâmetros"
            subtitulo="Tabelas e Parâmetros do Sistema"
        >
            <Grid 
                container 
                spacing={2}
                display='flex'
                flexDirection={'column'}
            >
                <Grid item xs={12} md={6}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
                        Tabelas e Parâmetros do Sistema
                    </Typography>
                </Grid>
                <Demo>
                    <List dense={true}>
                        <Grid container >
                            {itensCadastro.map(item => (
                                <Grid item xs={12} md={6} lg={4} key={item.id}>
                                    <ItemTabela key={item.id} item={item} />
                                </Grid>
                            ))}
                        </Grid>
                    </List>
                </Demo>
            </Grid>
        </Layout>
    )
}

export default Tabelas