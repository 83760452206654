import { Box } from '@mui/material'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../../layouts'
import Formulario from './Formulario'
import Tabela from './Tabela'

export const TiposEndereco = (props) => {

    const [modo, setModo] = useState({ status: 'table', registro: {} })

    return (
        <Layout
            titulo="Tipos de Endereço"
            subtitulo="Tipos de endereço disponíveis no sistema."
        >
            <Box>
                {
                    modo.status === 'table' ? (
                        <Tabela
                            setModo={setModo}
                            modo={modo}
                        />
                    ) : (
                        <Formulario
                            setModo={setModo}
                            modo={modo}
                        />
                    )
                }
            </Box>
        </Layout>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TiposEndereco)