import { useState } from 'react'
import { Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Tooltip } from '@mui/material';
import { Info, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { checarData } from '../../../funcoes/data'

export default function Detalhes(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { servico } = props
    
    return (
        <span>
            <Tooltip title="Detalhes do Serviço" placement="top">
                <IconButton
                    color="primary"
                    onClick={handleOpen}
                    size="small"
                >
                    <Info color='alternativo'/>
                </IconButton>
            </Tooltip>
            
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                    className="dialog-title"
                >
                    Informações completas da servico
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <Close sx={{ color: 'white' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 4 }}>
                    <Box rowSpacing={1}>
                        <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px", mt: '10px' }}>
                            <Grid container columnSpacing={1} >
                                <Grid item xs={4}>Identificador</Grid><Grid item xs={8}>{servico.id}</Grid>
                                <Grid item xs={4}>Nome</Grid><Grid item xs={8}>{servico.nome}</Grid>
                                <Grid item xs={4}>Descrição</Grid><Grid item xs={8}>{servico.descricao}</Grid>
                                <Grid item xs={4}>Cliente</Grid><Grid item xs={8}>{servico.cliente_id} - {servico.cliente?.nome}</Grid>
                                <Grid item xs={4}>Projeto</Grid><Grid item xs={8}>{servico.projeto_id} - {servico.projeto?.nome}</Grid>
                                <Grid item xs={4}>Tipo de servico</Grid><Grid item xs={8}>{servico.tipo_servico_id} - {servico.tipoServico.nome}</Grid>
                                <Grid item xs={4}>Data de Início</Grid><Grid item xs={8}>{checarData(servico.data_inicio) ? format(new Date(servico.data_inicio), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Data de Conclusão</Grid><Grid item xs={8}>{checarData(servico.data_termino) ? format(new Date(servico.data_termino), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Status</Grid><Grid item xs={8}>{servico.status_servico_id} - {servico?.statusServico.nome}</Grid>
                                <Grid item xs={4}>Periodicidade</Grid><Grid item xs={8}>{servico.periodicidade_id} - {servico?.periodicidadeServico.nome}</Grid>
                                <Grid item xs={4}>Data de Inclusão</Grid><Grid item xs={8}>{checarData(servico.created_at) ? format(new Date(servico.created_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                <Grid item xs={4}>Última Alteração</Grid><Grid item xs={8}>{checarData(servico.updated_at) ? format(new Date(servico.updated_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                {
                                    servico.deleted_at ? (
                                        <>
                                            <Grid item xs={4}>Exclusão</Grid>
                                            <Grid item xs={8}>{checarData(servico.deleted_at) ? format(new Date(servico.deleted_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                        </>
                                    ) : null
                                }
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                >
                    <Button variant="outlined" color="secondary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}


