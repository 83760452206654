import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../../config/configApi'
import header from '../../../middleware/jwtInterceptor'

const initialState = {
    loading: false,
    registros: [],
    error: ''
}

export const fetchStatusNegociacao = createAsyncThunk('tabelas/statusNegociacao', () => {
    const autorizacao = header()
    return api
        .get('cadastro/statusNegociacao', autorizacao)
        .then((res) => res.data.registros.sort((r1, r2) => r1.nome > r2.nome ? 1 : -1))
})

export const slice = createSlice({
    name: 'statusNegociacao',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStatusNegociacao.pending, state => {
            state.loading = true
        })
        builder.addCase(fetchStatusNegociacao.fulfilled, (state, action) => {
            state.loading = false
            state.registros = action.payload
            state.error = ''
        })
        builder.addCase(fetchStatusNegociacao.rejected, (state, action) => {
            state.loading = false
            state.registros = []
            state.error = action.error.message
        })
    }
})

export default slice.reducer
export const selectStatusNegociacao = state => state.statusNegociacao.registros
