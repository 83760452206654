import { Box, Typography } from '@mui/material'
import React from 'react'
import Layout from '../../layouts'

function Configuracao() {
    return (
        <Layout
            titulo="Configurações"
            subtitulo="Configurações do sistema"
        >
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'row',
                    justifyContent:'center',
                    alignItems:'center',
                    height:'80vh',
                }}
            >
                <Typography variant="h5" gutterBottom >Em desenvolvimento</Typography>
            </Box>
        </Layout>

    )
}

export default Configuracao