import { Download, FileDownload, Grid4x4, PictureAsPdf } from '@mui/icons-material'
import { Box, Button, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Menu } from '@mui/material'
import React, { useState } from 'react'

function ExportarTableComponent(props) {

    //console.log('props em ExportarTableComponent: ', props)
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => { setAnchorEl(event.currentTarget)}
    const handleClose = () => { setAnchorEl(null)}

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<Download />}
                color='secondary'
            >
                Exportar

            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Box display='flex' flexDirection='column' sx={{ px: 2 }}>
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader">
                                Selecione o tipo de arquivo
                            </ListSubheader>
                        }
                    >
                        <ListItemButton onClick={() => { props.exportData("xlsx", false) }}>
                            <ListItemIcon>
                                < Grid4x4 />
                            </ListItemIcon>
                            <ListItemText primary="XLSX" secondary="Planilha Excel" />
                        </ListItemButton>
                        <ListItemButton onClick={() => { props.exportData("pdf", false) }}>
                            <ListItemIcon>
                                <PictureAsPdf />
                            </ListItemIcon>
                            <ListItemText primary="PDF" secondary="Documento em PDF" />
                        </ListItemButton>
                        <ListItemButton onClick={() => { props.exportData("csv", false) }}>
                            <ListItemIcon>
                                <FileDownload />
                            </ListItemIcon>
                            <ListItemText primary="CSV" secondary="Campos separados por vírgula" />
                        </ListItemButton>
                    </List>
                </Box>
            </Menu>
        </div>
    )
}

export default ExportarTableComponent