import { Grid, List, ListItemButton, ListItemText } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { connect } from 'react-redux'
import Layout from '../../layouts'
import EntradasPorOrigem from './EntradasPorOrigem'
import Conta from './Conta'
import DebitosPorOrigem from './DebitosPorOrigem'
import Inadimplencia from './Inadimplencia'
import Servicos from './Servicos'
import Salarios from './Salarios'
import SalariosHistorico from './SalariosHistorico'
import EntradasSaidas from './EntradasSaidas'
import Clientes from './Clientes'
import Fornecedores from './Fornecedores'

const itensControle = [
    { id: 1, titulo: 'Status atual da conta' },
    { id: 2, titulo: 'Entradas por origem (legenda)' },
    { id: 3, titulo: 'Débitos por origem (legenda)' },
    { id: 4, titulo: 'Inadimplência' },
    { id: 5, titulo: 'Relatório de Serviços' },
    { id: 6, titulo: 'Salários' },
    { id: 7, titulo: 'Histórico de Salários da Equipe' },
    { id: 8, titulo: 'Totais Entradas e Saídas' },
    { id: 9, titulo: 'Clientes' },
    { id: 10, titulo: 'Fornecedores' },
]

export const ControleGeral = (props) => {

    const [opcao, setOpcao] = useState('')

    return (
        <Layout
            titulo="Controle Geral"
            subtitulo="Tabelas da planilha de controle (legado)"
        >
            <Grid container
                spacing={2}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Grid item sx={{ width: '20%', backgroundColor: '#F2F2F2', }} >
                    <List component="nav"
                        sx={{
                            '& .MuiListItemText-primary': { width: '100%', fontSize: '0.9rem' },
                            '& .MuiListItemButton-root': { px: 1, py: 0.5 },
                            mt: 1,
                        }}
                    >
                        {itensControle.map((item) => {
                            return (
                                <ListItemButton
                                    key={item.id}
                                    onClick={() => setOpcao(item.id)}
                                >
                                    <ListItemText primary={item.titulo} />
                                </ListItemButton>
                            )
                        })}
                    </List>
                </Grid>
                <Grid item sx={{ width: '75%' }}>
                    {opcao === 1 ? <Conta /> : null}
                    {opcao === 2 ? <EntradasPorOrigem /> : null}
                    {opcao === 3 ? <DebitosPorOrigem /> : null}
                    {opcao === 4 ? <Inadimplencia /> : null}
                    {opcao === 5 ? <Servicos /> : null}
                    {opcao === 6 ? <Salarios /> : null}
                    {opcao === 7 ? <SalariosHistorico /> : null}
                    {opcao === 8 ? <EntradasSaidas /> : null}
                    {opcao === 9 ? <Clientes /> : null}
                    {opcao === 10 ? <Fornecedores /> : null}
                </Grid>
            </Grid>
        </Layout>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ControleGeral)