import { useState } from 'react'
import { Grid, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import { Info, Close } from '@mui/icons-material';
import { format } from 'date-fns';
import { checarData, valorMoeda } from '../../../funcoes/data'

export default function Detalhes(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { registro } = props
    
    return (
        <span>
            <span onClick={handleOpen} >
                <Info color='alternativo' sx={{ cursor: 'pointer' }} />
            </span>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                    className="dialog-title"
                >
                    Informações completas do recebimento
                    <IconButton aria-label="add to shopping cart" onClick={handleClose}>
                        <Close sx={{ color: 'white' }} />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ p: 4 }}>
                    <Box rowSpacing={1}>
                        <Box sx={{ border: '0.5px solid gray', padding: '10px', mb: "5px", mt: '10px' }}>
                            <Grid container columnSpacing={1} >
                                <Grid item xs={4}>Identificador</Grid><Grid item xs={8}>{registro.id}</Grid>
                                <Grid item xs={4}>Cliente</Grid><Grid item xs={8}>{registro.projeto?.cliente?.nome}</Grid>
                                <Grid item xs={4}>Projeto</Grid><Grid item xs={8}>{registro.projeto?.nome}</Grid>
                                <Grid item xs={4}>Serviço</Grid><Grid item xs={8}>{registro.servico?.nome}</Grid>
                                <Grid item xs={4}>Status</Grid><Grid item xs={8}>{registro.statusRecebimento?.nome}</Grid>
                                <Grid item xs={4}>Origem</Grid><Grid item xs={8}>{registro.origemRecebimento?.nome}</Grid>
                                <Grid item xs={4}>Número da Parcela</Grid><Grid item xs={8}>{registro?.numero_parcela}</Grid>
                                <Grid item xs={4}>Data de Vencimento</Grid><Grid item xs={8}>{checarData(registro?.data) ? format(new Date(registro?.data), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Data de Pagamento</Grid><Grid item xs={8}>{checarData(registro?.data_pagamento) ? format(new Date(registro?.data_pagamento), 'dd/MM/yyyy') : ''}</Grid>
                                <Grid item xs={4}>Valor</Grid><Grid item xs={8}>{valorMoeda(registro?.valor)}</Grid>
                                <Grid item xs={4}>Histórico</Grid><Grid item xs={8}>{registro.observacoes}</Grid>
                                <Grid item xs={4}>Data de Inclusão</Grid><Grid item xs={8}>{checarData(registro.created_at) ? format(new Date(registro.created_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                <Grid item xs={4}>Última Alteração</Grid><Grid item xs={8}>{checarData(registro.updated_at) ? format(new Date(registro.updated_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                {
                                    registro.deleted_at ? (
                                        <>
                                            <Grid item xs={4}>Exclusão</Grid>
                                            <Grid item xs={8}>{checarData(registro.deleted_at) ? format(new Date(registro.deleted_at), 'dd/MM/yyyy hh:mm:ss') : ''}</Grid>
                                        </>
                                    ) : null
                                }
                            </Grid>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        pr: 1,
                        pl: 4,
                        bgcolor: 'background.alternativo',
                        color: 'white',
                    }}
                >
                    <Button variant="outlined" color="secondary" onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </span>
    );
}


