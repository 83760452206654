import { Box, Divider, Typography } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import TableTiposMovimento from './tableTiposMovimento'

export const TiposMovimento = (props) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom align='center' mt={1}>
        Tipos de Movimento das Contas Bancárias
      </Typography>
      <Divider sx={{ mb: '10px' }} />
      <TableTiposMovimento />
    </Box>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(TiposMovimento)