import { Checkbox } from "@mui/material"
import React from "react"

export const CheckboxInput = React.forwardRef( ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolveRef = ref || defaultRef

    React.useEffect(() => {
        resolveRef.current.indeterminate = indeterminate
    }, [resolveRef, indeterminate])

    return (
        // <input type='checkbox' color="success" ref={resolveRef} {...rest}/>
        <Checkbox size="small" color="primary" ref={resolveRef} {...rest}/>
    )
})
