import { FormControlLabel, Switch } from '@mui/material'
import React from 'react'

const ExibirExcluidos = (props) => {
    return (
        <FormControlLabel
            control={
                <Switch
                    color="secondary"
                    checked={props.exibirExcluidos}
                    onChange={() => props.setExibirExcluidos(!props.exibirExcluidos)}
                />
            }
            label={<span style={{ fontSize: '0.8rem', color: 'black' }}>{props.labelExibirExcluidos ? props.labelExibirExcluidos : 'Excluídos'}</span>}
            labelPlacement="end"
            sx={{ fontSize: '15px' }}
        />
    )
}

export default ExibirExcluidos