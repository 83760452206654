import { Grid, Typography } from '@mui/material'
import Layout from '../layouts'
import Logo from '../../img/logo-site-ninja.png'

function Home() {
    return (
        <Layout
            titulo="Home"
            subtitulo="Página inicial do sistema"
        >
            <Grid container sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
            }}>
                <Grid item
                    display={'flex'}
                    justifyContent={'center'}
                    sx={{p:1, width:{lg:'500px', md:'400px', sm:'350px', xs:'300px',}}}
                >
                    <img
                        src={Logo}
                        alt='Logotipo Ideia Ninja'
                        loading="lazy"
                        width={'100%'}
                    />
                </Grid>
                <Grid item>
                    <Typography sx={{fontSize:{lg:'60px', md:'50px', sm:'40px', xs:'30px',}}}>
                        SAIN
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography sx={{fontSize:{lg:'30px', md:'25px', sm:'20px', xs:'18px',}}}>
                        Sistema de Administração Idéia Ninja
                    </Typography>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default Home