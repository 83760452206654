import axios from 'axios'
import { Config } from '../../ninjaConfig.js'

import {
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGOUT,
    
} from "./authTypes"

const API_URL_AUTH = `${Config.API}/users/authLogin`

export const fetchLoginRequest = () => {
    return {
        type: FETCH_LOGIN_REQUEST,
        payload: true
    }
}

export const fetchLoginSuccess = login => {
    return {
        type: FETCH_LOGIN_SUCCESS,
        payload: login
    }
}

export const fetchUsersLogout = error => {
    return {
        type: FETCH_LOGOUT,
        payload: error
    }
}

export const authLogin = (login) => {
    return (dispatch) => {
        dispatch(fetchLoginRequest)
        try{
        axios.post(API_URL_AUTH, login)
            .then(response => {
                
                //setTimeout(() => {
                    const login = response.data
                    dispatch(fetchLoginSuccess(login))
                    localStorage.setItem('stateAutenticacao', JSON.stringify(login))
                //}, 1000)
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchUsersLogout(errorMsg))
            })
        } catch(e) {
            console.log(e)
        }
    }
}