import * as React from 'react';
import { DataGrid, GridToolbar, ptBR } from '@mui/x-data-grid';
import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchPagamentosAVincular } from '../../../../redux/cadastro/pagamentoSlice';
import { useEffect } from 'react';
import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { dataBRExibir } from '../../../../funcoes/data';

const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { 
        field: 'data_vencimento', 
        headerName: 'Vencimento', 
        width: 100,
        valueFormatter: (params) => {
            if (params.value === null) { return '' }
            const dataFormatada = dataBRExibir(params.value)
            return dataFormatada
        } 
    },
    {
        field: 'fornecedor',
        headerName: 'Fornecedor',
        width: 200,
        valueGetter: (params) => params.row.fornecedor.nome,
    },
    {
        field: 'valor',
        headerName: 'Valor',
        type: 'number',
        width: 120,
        valueFormatter: (params) => {
            if (params.value === null) { return '' }
            const valorFormatado = Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(params.value)
            return valorFormatado
        }
    },
    {
        field: 'projeto',
        headerName: 'Projeto',
        width: 150,
        valueGetter: (params) => params.row.projeto.nome
    },
    {
        field: 'servico',
        headerName: 'Serviço',
        width: 150,
        valueGetter: (params) => params.row.servico.nome
    }
];

export const TablePagamentos = (props) => {

    const { 
        setVlrVinculado, 
        setVinculosSelecionados, 
        pagamentos, 
        listarPagamentos 
    } = props

    const [selectionModel, setSelectionModel] = useState([])

    const linhasSelecionadas = selectionModel.map((s) => {
        return pagamentos.registros.find(r => r.id === s)
    })

    useEffect(() => {
        listarPagamentos()
    }, [listarPagamentos])

    useEffect(() => {
        setVlrVinculado(linhasSelecionadas.reduce((a, b) => a + parseFloat(b.valor), 0.00))
        setVinculosSelecionados(linhasSelecionadas)
    }, [linhasSelecionadas, setVinculosSelecionados, setVlrVinculado])

    return (
        <div style={{ height: 310, width: '100%' }}>
            {
                pagamentos.loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="secondary" />
                    </Backdrop>
                ) : pagamentos.error ? (
                    <Typography
                        variant='body'
                    >
                        Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {pagamentos.error})
                    </Typography>
                ) : (
                    <DataGrid
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                        rows={pagamentos.registros}
                        columns={columns}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        components={{ Toolbar: GridToolbar }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel)
                        }}
                        selectionModel={selectionModel}
                        density={'compact'}
                    />
                )}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        pagamentos: {
            loading: state.pagamentos.loading,
            registros: state.pagamentos.registros,
            error: state.pagamentos.error
        },
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        listarPagamentos: () => dispatch(fetchPagamentosAVincular()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TablePagamentos)