import { Backdrop, CircularProgress, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table'
import { useExportData } from 'react-table-plugins'
import { deleteRegistro, deleteUsuario, fetchUsuarios } from '../../../redux/cadastro/usuarioSlice'
import AppBarComponent from '../../table/AppBarComponent'
import COLUMNS from './colunas'
import { getExportFileBlob } from '../../../funcoes/exportTable'
import { FiltroColuna } from '../../table/FiltroColunaComponent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BarraInferiorTable from '../../table/BarraInferiorTableComponent'
import Detalhes from './Detalhes'
import DialogExclusao from '../../layouts/DialogExclusao'
import EditIcon from '@mui/icons-material/Edit';

function Tabela(props) {
    const { 
        setModo, 
        atualizarUsuarios,
        usuarios,
    } = props

    const dispatch = useDispatch()
    const [registros, setRegistros] = useState([])
    // eslint-disable-next-line no-unused-vars
    const [idExclusao, setIdExclusao] = useState('')
    const [reloadUsuarios, setReloadUsuarios] = useState(true)
    const [exibirExcluidos, setExibirExcluidos] = useState(false)
    
    
    useEffect(() => {
        if(reloadUsuarios){
            atualizarUsuarios()
            setReloadUsuarios(false)
        }
        let reg = usuarios.dados.slice(0) 
        reg.sort((r1, r2) => r1.id < r2.id ? 1 : -1)
        reg = reg.filter(r => {
            return r.deleted_at === null
        })
        setRegistros(reg)
    }, [atualizarUsuarios, reloadUsuarios, usuarios.dados])
    
    const columns = useMemo(() => COLUMNS(), [])
    const data = useMemo(() => registros, [registros])

    const defaultColumn = useMemo(() => {
        return {
            Filter: FiltroColuna
        }
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        prepareRow,
        state,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        exportData,
    } = useTable({
        columns,
        data,
        defaultColumn,
        getExportFileBlob,
        
    },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useExportData,
    )

    const { globalFilter, pageIndex, pageSize } = state

    const handleExcluir = idExclusao => {
        dispatch(deleteUsuario(idExclusao))
        dispatch(deleteRegistro(idExclusao))
    }

    //console.log('error', usuarios.error)

    return (
        <Box>
            {
                usuarios.loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <CircularProgress color="secondary" />
                    </Backdrop>
                ) : usuarios.error ? (
                    <Typography variant='body'>Não foi possivel acessar os dados. Informe ao administrador do sistema. (Erro: {usuarios.error})</Typography>
                ) : (
                    <Box>
                        <AppBarComponent
                            globalFilter={globalFilter}
                            setGlobalFilter={setGlobalFilter}
                            setModo={setModo}
                            exibirExcluidos={exibirExcluidos}
                            setExibirExcluidos={setExibirExcluidos}
                            botaoExcluidos ={ true }
                            exportData={exportData}
                            getToggleHideAllColumnsProps={getToggleHideAllColumnsProps}
                            allColumns={allColumns}
                        />
                        <Box>
                            <table {...getTableProps()} style={{ marginTop: '150px' }}>
                                <thead>
                                    {
                                        headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                        >
                                                            {column.render('Header')}
                                                            <span>
                                                                {column.isSorted
                                                                    ? (column.isSortedDesc
                                                                        ? <ArrowDropDownIcon
                                                                            sx={{ fontSize: '13px', color: 'yellow' }}
                                                                        />
                                                                        : <ArrowDropUpIcon
                                                                            sx={{ fontSize: '13px', color: 'yellow' }}
                                                                        />)
                                                                    : null}
                                                            </span>
                                                        </Box>
                                                    </th>
                                                ))}
                                                <th>Ações</th>
                                            </tr>
                                        ))
                                    }
                                    {
                                        headerGroups.map((headerGroup, index) => (
                                            <tr {...headerGroup.getHeaderGroupProps()} key={2}>
                                                {headerGroup.headers.map((column) => (
                                                    <th {...column.getHeaderProps()}
                                                    >
                                                        <div>{column.canFilter ? column.render('Filter') : null}</div>
                                                    </th>
                                                ))}
                                                <th></th>
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {
                                        page.map((row) => {
                                            prepareRow(row)
                                            const isDeleted = Boolean(row.original.status_servico_id === 6)
                                            return (
                                                <tr {...row.getRowProps()} >
                                                    {
                                                        row.cells.map((cell) => {
                                                            return (
                                                                <td style={isDeleted ? { color: 'red' } : { color: '#000' }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                            )
                                                        })
                                                    }
                                                    <td>
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Detalhes
                                                                registro={row.original}
                                                            />
                                                            <IconButton
                                                                color="primary"
                                                                onClick={() => setModo({ status: 'edit', registro: row.original, usuarios: registros })}
                                                                size="small"
                                                            >
                                                                <EditIcon color='danger' />
                                                            </IconButton>
                                                            <DialogExclusao
                                                                title="o usuário do sistema"
                                                                referencia={`${row.original.name} (${row.original.email})`}
                                                                idExclusao={row.original.id}
                                                                setIdExclusao={setIdExclusao}
                                                                handleExcluir={handleExcluir}
                                                            />
                                                        </Box>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <BarraInferiorTable
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                gotoPage={gotoPage}
                                canPreviousPage={canPreviousPage}
                                canNextPage={canNextPage}
                                previousPage={previousPage}
                                nextPage={nextPage}
                                pageCount={pageCount}
                                pageSize={pageSize}
                                setPageSize={setPageSize}
                            />
                        </Box>
                    </Box>
                )
            }
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        usuarios: {
            loading: state.usuarios.loading,
            dados: state.usuarios.registros,
            error: state.usuarios.error,
        },
    }
}

const mapDispatchToProps = dispatch => {
    return {
        atualizarUsuarios: () => dispatch(fetchUsuarios())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tabela)
