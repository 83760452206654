import { Box, FormControl, MenuItem } from "@mui/material"
import { Field } from "formik"
import { Select } from "formik-mui"

function SelectOptions(props) {

    const { label, name, options, ...rest } = props

    return (
        <Box sx={{ml:1, mr:1}}>
            <FormControl fullWidth >
                <Field
                    size="small"
                    {...rest}
                    component={Select}
                    type="text"
                    name={name}
                    label={label}
                    sx={{ mt: 1 }}
                >
                    {
                        options.map(option => {
                            return (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.key}
                                </MenuItem>)
                        })
                    }
                </Field>
            </FormControl>
        </Box>
    )
}

export default SelectOptions