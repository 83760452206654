import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Popper,
    TextField,
} from '@mui/material'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPessoas } from '../../../redux/cadastro/pessoa/pessoaSlice'
import { fetchStatusProjeto } from '../../../redux/cadastro/tabelas/statusProjetoSlice'
import { fetchTiposProjeto } from '../../../redux/cadastro/tabelas/tiposProjetoSlice'
import * as Yup from 'yup'
import { fetchProjetos, saveProjeto } from '../../../redux/cadastro/projetoSlice'
import Aviso from '../../layouts/Aviso'
import DialogErro from '../../layouts/DialogErro'
import { makeStyles, createStyles } from '@material-ui/styles'
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            "& .MuiAutocomplete-listbox": {
                border: "2px solid black",
                fontSize: 12,
                zIndex: 9999,
                "& li:nth-child(even)": { backgroundColor: '#F2F2F2' },
                "& li:nth-child(odd)": { backgroundColor: '#FFF' },
            }
        },
        menu: (styles) => ({
            ...styles,
            zIndex: 9999,
        })
    })
)

const CustomPopper = (props) => {
    const classes = useStyles()
    return <Popper sx={{ zIndex: 999 }} {...props} className={classes.root} placement="bottom" />
}
function Formulario(props) {

    const { modo, setModo } = props
    const [openAviso, setOpenAviso] = useState(false);
    const [openDialogErro, setOpenDialogErro] = useState(false);
    const [dialogError, setDialogError] = useState('')
    const [optionClienteSelecionado, setOptionClienteSelecionado] = useState({})
    const [optionTipoProjetoSelecionado, setOptionTipoProjetoSelecionado] = useState({})
    const [optionStatusProjetoSelecionado, setOptionStatusProjetoSelecionado] = useState({})

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchTiposProjeto())
        dispatch(fetchPessoas())
        dispatch(fetchStatusProjeto())

        setOptionClienteSelecionado({
            value: modo.registro?.cliente?.id || '',
            label: modo.registro?.cliente?.nome || ''
        })

        setOptionTipoProjetoSelecionado({
            value: modo.registro?.tipoProjeto?.id || '',
            label: modo.registro?.tipoProjeto?.nome || ''
        })

        setOptionStatusProjetoSelecionado({
            value: modo.registro?.statusProjeto?.id || '',
            label: modo.registro?.statusProjeto?.nome || ''
        })

    }, [dispatch, modo])

    const tiposProjeto = useSelector(state => state.tiposProjeto.registros)
    const pessoas = useSelector(state => state.pessoas.registros)
    let clientes = pessoas.filter(p => p.grupo_pessoa_id === 2 || p.grupo_pessoa_id === 4)
    const statusProjeto = useSelector(state => state.statusProjeto.registros)
    clientes.sort((r1, r2) => r1.nome > r2.nome ? 1 : -1)

    const tiposProjetoOptions = tiposProjeto?.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })

    const clientesOptions = clientes?.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })

    const statusProjetoOptions = statusProjeto?.map((item) => {
        return {
            value: item.id,
            label: item.nome
        }
    })

    const validationSchema = Yup.object({
        tipo_projeto_id: Yup
            .number('O valor não é numérico')
            .required('Informe o tipo de projeto'),
        cliente_id: Yup
            .number('O valor não é numérico')
            .required('Informe o cliente do projeto'),
        nome: Yup
            .string()
            .min(3, 'Mínimo de 3 carcateres')
            .max(255, 'Máximo de 255 carcateres')
            .required('Informe o título do projeto'),
        descricao: Yup
            .string()
            .min(3, 'Mínimo de 3 carcateres')
            .max(500, 'Máximo de 500 carcateres')
            .required('Informe a descrição do projeto'),
        status_projeto_id: Yup
            .number('O valor não é numérico')
            .required('Informe o status do projeto'),
    })

    const onSubmit = async (values, onSubmitProps) => {
        const res = await dispatch(saveProjeto(values))
        dispatch(fetchProjetos())
        if (!!res?.error) {
            setDialogError(`Adicionar projetos: ${res.error?.code} - ${res.error?.message}`)
            setOpenDialogErro(true)
        } else {
            setOpenAviso(true)
            onSubmitProps.resetForm()
            limparForm(onSubmitProps.resetForm)
            if (modo.status !== 'add') setModo({ status: 'table', registro: {} })
        }
    }

    const limparForm = (resetForm) => {
        resetForm()
        setOptionClienteSelecionado({})
        setOptionStatusProjetoSelecionado({})
        setOptionTipoProjetoSelecionado({})
    }

    const initialValues={
        id: modo.registro.id || '',
        tipo_projeto_id: modo.registro.tipo_projeto_id || '',
        cliente_id: modo.registro.cliente_id || '',
        nome: modo.registro.nome || '',
        descricao: modo.registro.descricao || '',
        data_inicio: modo.registro.data_inicio || '',
        data_termino: modo.registro.data_termino || '',
        status_projeto_id: modo.registro.status_projeto_id || '',
    }

    return (
        <>
            <Card sx={{ width: '100%' }}>
                <CardHeader title={modo.status === 'add' ? "Incluir novo projeto" : "Alterar projeto"} subtitle={props.value}></CardHeader>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                    enableReinitialize
                >
                    {({
                        formik,
                        values,
                        isSubmitting,
                        isValid,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        touched,
                        errors,
                        setFieldValue,
                        resetForm,
                        defaultValues,
                    }) => {
                        return (
                            <Form>
                                <CardContent>
                                    <Grid
                                        container
                                        rowSpacing={{ xs: 2, sm: 2, }}
                                        columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                                    >
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <Autocomplete
                                                disablePortal
                                                id="cliente_id"
                                                name="cliente_id"
                                                size="small"
                                                noOptionsText={'Nenhuma opção disponível'}
                                                options={clientesOptions}
                                                value={optionClienteSelecionado}
                                                isOptionEqualToValue={(option) => option = optionClienteSelecionado}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, newValue, option) => {
                                                    setOptionClienteSelecionado(newValue)
                                                    setFieldValue('cliente_id', !!newValue ? newValue.value : '')
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label="Cliente/Fornecedores"
                                                        value={values.cliente_id || ''}
                                                        error={touched.cliente_id && Boolean(errors.cliente_id)}
                                                        helperText={touched.cliente_id && errors.cliente_id}
                                                    />
                                                )}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} key={option.value}>
                                                        {`${option.label} (${option.value})`}
                                                    </Box>
                                                )}
                                                PopperComponent={CustomPopper}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <TextField
                                                id="nome"
                                                name="nome"
                                                label="Título"
                                                fullWidth
                                                value={values.nome}
                                                size="small"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.nome && Boolean(errors.nome)}
                                                helperText={touched.nome && errors.nome}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <Autocomplete
                                                id="tipo_projeto_id"
                                                name="tipo_projeto_id"
                                                options={tiposProjetoOptions}
                                                value={optionTipoProjetoSelecionado}
                                                isOptionEqualToValue={(option) => option = optionTipoProjetoSelecionado}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, newValue, option) => {
                                                    setFieldValue('tipo_projeto_id', !!newValue ? newValue.value : '')
                                                    setOptionTipoProjetoSelecionado(newValue)
                                                }}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} key={option.value}>
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        value={values.tipo_projeto_id}
                                                        label="Tipo"
                                                        error={touched.tipo_projeto_id && Boolean(errors.tipo_projeto_id)}
                                                        helperText={touched.tipo_projeto_id && errors.tipo_projeto_id}
                                                    />
                                                )}
                                                size="small"
                                                PopperComponent={CustomPopper}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <Autocomplete
                                                id="status_projeto_id"
                                                name="status_projeto_id"
                                                options={statusProjetoOptions}
                                                value={optionStatusProjetoSelecionado}
                                                isOptionEqualToValue={(option) => option = optionStatusProjetoSelecionado}
                                                getOptionLabel={(option) => option.label || ''}
                                                onChange={(event, newValue, option) => {
                                                    setFieldValue('status_projeto_id', !!newValue ? newValue.value : '')
                                                    setOptionStatusProjetoSelecionado(newValue)
                                                }}
                                                renderOption={(props, option) => (
                                                    <Box component="li" {...props} key={option.value}>
                                                        {option.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        value={values.status_projeto_id}
                                                        label="Status"
                                                        error={touched.status_projeto_id && Boolean(errors.status_projeto_id)}
                                                        helperText={touched.status_projeto_id && errors.status_projeto_id}
                                                    />
                                                )}
                                                size="small"
                                                PopperComponent={CustomPopper}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                id="descricao"
                                                name="descricao"
                                                label="Descrição"
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={values.descricao}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                error={touched.descricao && Boolean(errors.descricao)}
                                                helperText={touched.descricao && errors.descricao}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <TextField
                                                type='date'
                                                id="data_inicio"
                                                name="data_inicio"
                                                label="Data de Início"
                                                fullWidth
                                                size="small"
                                                format='MM/dd/yyyy'
                                                value={values.data_inicio}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} lg={4} >
                                            <TextField
                                                type='date'
                                                id="data_termino"
                                                name="data_termino"
                                                label="Data de Término"
                                                fullWidth
                                                size="small"
                                                format='MM/dd/yyyy'
                                                value={values.data_termino}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ ml: 1 }}>
                                    <Button
                                        variant="contained"
                                        type='submit'
                                        disabled={isSubmitting}
                                        startIcon={<SaveIcon/>}
                                    >
                                        {modo.status === 'add' ? 'Salvar' : 'Alterar'}
                                    </Button>
                                    {modo.status === 'add' ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => limparForm(resetForm)}
                                            startIcon={<ClearIcon/>}
                                        >
                                            Limpar
                                        </Button>
                                    ) : null}
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        type="reset"
                                        onClick={() => setModo({ status: 'table', registro: {} })}
                                        startIcon={<ArrowBackIcon/>}
                                    >
                                        VOLTAR
                                    </Button>
                                </CardActions>
                            </Form>
                        )
                    }}
                </Formik>
            </Card>
            <Aviso
                openSnakbar={openAviso}
                setOpenAviso={setOpenAviso}
                mensagem={`Projeto ${modo.status === 'add' ? 'adicionado' : 'alterado'} com sucesso!!!`}
                tipo="success"
            />
            <DialogErro
                openDialogErro={openDialogErro}
                setOpenDialogErro={setOpenDialogErro}
                dialogError={dialogError}
                mensagem="Não foi possível incluir o projeto. Tente novamente e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
            />
        </>
    )
}

export default Formulario