import { combineReducers } from 'redux'
import userReducer from './user/userReducer'
import authReducer from './auth/authReducer'
import abasPessoaReducer from './auxiliar/abasPessoaSlice'
import pessoaFisicaReducer from './cadastro/pessoa/pessoaFisicaSlice'
import pessoaJuridicaReducer from './cadastro/pessoa/pessoaJuridicaSlice'
import enderecosReducer from './cadastro/pessoa/EnderecoSlice'
import pessoaReducer from './cadastro/pessoa/pessoaSlice'
import redesSociaisReducer from './cadastro/pessoa/RedesSociaisSlice'
import tiposRedesSociaisReducer from './cadastro/tabelas/tiposRedesSociaisSlice'
import tiposEnderecoReducer from './cadastro/tabelas/tiposEnderecoSlice'
import gruposPessoasReducer from './cadastro/tabelas/gruposPessoasSlice'
import tiposPessoaReducer from './cadastro/tabelas/tiposPessoaSlice'
import statusReducer from './cadastro/tabelas/statusSlice'
import telefonesReducer from './cadastro/pessoa/TelefonesSlice'
import tiposTelefoneReducer from './cadastro/tabelas/tiposTelefoneSlice'
import projetosReducer from './cadastro/projetoSlice'
import tiposProjetoReducer from './cadastro/tabelas/tiposProjetoSlice'
import tiposServicoReducer from './cadastro/tabelas/tiposServicoSlice'
import statusProjetoReducer from './cadastro/tabelas/statusProjetoSlice'
import statusServicoReducer from './cadastro/tabelas/statusServicoSlice'
import statusRecebimentoReducer from './cadastro/tabelas/statusRecebimentoSlice'
import origemRecebimentoReducer from './cadastro/tabelas/origemRecebimentoSlice'
import periodicidadeServicoReducer from './cadastro/tabelas/periodicidadeServicoSlice'
import servicosReducer from './cadastro/servicoSlice'
import recebimentosReducer from './cadastro/recebimentoSlice'
import pagamentosReducer from './cadastro/pagamentoSlice'
import tiposPagamentoReducer from './cadastro/tabelas/tiposPagamentoSlice'
import statusPagamentoReducer from './cadastro/tabelas/statusPagamentoSlice'
import origemPagamentoReducer from './cadastro/tabelas/origemPagamentoSlice'
import formasPagamentoReducer from './cadastro/tabelas/formasPagamentoSlice'
import statusNegociacaoReducer from './cadastro/tabelas/statusNegociacaoSlice'
import negociacaoReducer from './cadastro/negociacaoSlice'
import usuariosReducer from './cadastro/usuarioSlice'
import historicoReducer from './cadastro/historicoSlice'
import controleReducer from './cadastro/controleSlice'
import controleGeralReducer from './controleGeral/controleGeralSlice'
import bancosReducer from './sistemas/contas/bancosSlice'
import agenciasReducer from './sistemas/contas/agenciasSlice' 
import contasReducer from './sistemas/contas/contasSlice'
import tiposContaReducer from './sistemas/contas/tiposContaSlice'
import tiposMovimentoReducer from './sistemas/contas/tiposMovimentoSlice'
import movimentosReducer from './sistemas/contas/movimentosSlice'
import preferenciasReducer from './sistemas/sain/preferenciasSlice'

const rootReducer = combineReducers({
    user: userReducer,
    auth: authReducer,
    status:statusReducer,
    abasPessoa: abasPessoaReducer,
    pessoaFisica: pessoaFisicaReducer,
    pessoaJuridica: pessoaJuridicaReducer,
    enderecos: enderecosReducer,
    tiposPessoa: tiposPessoaReducer,
    pessoas: pessoaReducer,
    redesSociais:redesSociaisReducer,
    tiposRedesSociais:tiposRedesSociaisReducer,
    tiposEndereco: tiposEnderecoReducer,
    gruposPessoas: gruposPessoasReducer,
    telefones: telefonesReducer,
    tiposTelefone:tiposTelefoneReducer,
    projetos: projetosReducer,
    tiposProjeto: tiposProjetoReducer,
    tiposServico: tiposServicoReducer,
    statusProjeto: statusProjetoReducer,
    statusServico: statusServicoReducer,
    statusRecebimento: statusRecebimentoReducer,
    origemRecebimento: origemRecebimentoReducer,
    servicos: servicosReducer,
    periodicidadeServico: periodicidadeServicoReducer,
    recebimentos: recebimentosReducer,
    pagamentos: pagamentosReducer,
    tiposPagamento: tiposPagamentoReducer,
    statusPagamento: statusPagamentoReducer,
    origemPagamento: origemPagamentoReducer,
    formasPagamento: formasPagamentoReducer,
    statusNegociacao: statusNegociacaoReducer,
    negociacoes: negociacaoReducer,
    usuarios: usuariosReducer,
    historico: historicoReducer,
    controle: controleReducer,
    controleGeral: controleGeralReducer,
    bancos: bancosReducer,
    agencias: agenciasReducer,
    contas: contasReducer,
    tiposConta: tiposContaReducer,
    tiposMovimento: tiposMovimentoReducer,
    movimentos: movimentosReducer,
    preferencias: preferenciasReducer,
})

export default rootReducer