import { Box, Button, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { listarContas } from '../../../../redux/sistemas/contas/contasSlice'
import TableMovimento from './tableMovimento'
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { adicionarMeses, validarPeriodo } from '../../../../funcoes/data'
import DialogErro from '../../../layouts/DialogErro'
import { listarPreferencias } from '../../../../redux/sistemas/sain/preferenciasSlice'

const hoje = new Date().toLocaleDateString('fr-CA')
const periodo = 1
const inicio = adicionarMeses(hoje, -periodo)

export const Movimento = (props) => {

  const { contas, listarContas, preferencias, listarPreferencias } = props

  const [contaSelecionada, setContaSelecionada] = useState('')
  const [saldo, setSaldo] = useState(0.00)
  const [dtInicio, setDtInicio] = useState(inicio)
  const [dtFim, setDtFim] = useState(hoje)
  const [periodoPreferencia, setPeriodoPreferencia] = useState(periodo)
  const [erroData, setErroData] = useState('')
  const [openDialogErro, setOpenDialogErro] = useState(false)
  const [parametros, setParametros] = useState({})

  const user_id = JSON.parse(localStorage.getItem('stateAutenticacao')).login.user.id

  useEffect(() => {
    listarPreferencias(user_id)
  }, [listarPreferencias, user_id])

  useEffect(() => {
    const prefLocal = preferencias.registros.find((el) => el.preferencia_id === 1)
    if (prefLocal) {
      setPeriodoPreferencia(prefLocal.valor)
      setDtInicio(adicionarMeses(hoje, -periodoPreferencia))
    }
  }, [periodoPreferencia, preferencias.registros])

  const listaContas = contas.registros

  useEffect(() => {
    listarContas()
  }, [listarContas])


  const handleContaSelecionada = e => {
    setContaSelecionada(e.target.value)
    setSaldo(contas.registros.find(conta => conta.id === e.target.value).saldo)
    setParametros({
      contaSelecionada: e.target.value,
      dtInicio,
      dtFim
    })
  }

  const extratoPorPeriodo = () => {
    const periodoValido = validarPeriodo(dtInicio, dtFim)
    if (periodoValido.validado) {
      const registro = {
        contaSelecionada,
        dtInicio,
        dtFim
      }
      setParametros(registro)

    } else {
      setErroData(periodoValido.msg)
      setOpenDialogErro(true)
    }
  }

  const resetParametros = () => {
    setDtInicio(adicionarMeses(hoje, -periodoPreferencia))
    setDtFim(hoje)
    setParametros({
      contaSelecionada,
      dtInicio,
      dtFim: hoje
    })
  }

  const exibirErro = () => {
    return (
      <DialogErro
        openDialogErro={openDialogErro}
        setOpenDialogErro={setOpenDialogErro}
        dialogError={erroData}
        mensagem="Não foi possível acessar os dados. Tente novamente mais tarde e se o problema persistir entre em contato com o administrador do sistema informando a mensagem abaixo."
      />
    )
  }

  return (
    <Box sx={{ flexGrow: 1, mt: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} >
          <Box display='flex' flexDirection={'column'} gap={2}>
            <TextField
              select
              fullWidth
              size='small'
              label="Conta"
              value={contaSelecionada}
              onChange={handleContaSelecionada}
            >
              {listaContas.map((conta, index) => (
                <MenuItem value={conta.id} key={index}>{`${conta.banco.nome}/${conta.agencia.nome}/${conta.numero}`}</MenuItem>
              ))}
            </TextField>

            {contaSelecionada ? (
              <>
                <Box>
                  <Typography variant='h6'>
                    Saldo: {Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(saldo)}
                  </Typography>
                </Box>
                <Divider />

                <>
                  <Typography variant="Body1">Período:</Typography>
                  <TextField
                    size="small"
                    type="date"
                    label={'de'}
                    InputLabelProps={{ shrink: true }}
                    value={dtInicio}
                    onChange={(e) => {
                      setDtInicio(e.target.value)
                    }}
                  />
                  <TextField
                    size="small"
                    type="date"
                    label="até"
                    InputLabelProps={{ shrink: true }}
                    value={dtFim}
                    onChange={(e) => setDtFim(e.target.value)}
                  />
                  <Box display={'flex'} gap={2}>
                    <Button
                      variant="contained"
                      startIcon={<PlaylistPlayIcon />}
                      onClick={extratoPorPeriodo}
                    >
                      Listar
                    </Button>
                    <Button
                      variant="contained"
                      color="warning"
                      startIcon={<HighlightOffIcon />}
                      onClick={resetParametros}
                    >
                      Reiniciar
                    </Button>
                  </Box>
                  <Divider />
                </>
              </>
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12} md={9}>
          {contaSelecionada ? (
            <Box>
              <TableMovimento
                contaSelecionada={contaSelecionada}
                acao="add"
                setSaldo={setSaldo}
                parametros={parametros}
              />
            </Box>
          ) : null}

        </Grid>

        {!!erroData ? exibirErro() : null}
      </Grid>

    </Box>
  )
}

const mapStateToProps = (state) => {
  return {
    contas: {
      loading: state.contas.loading,
      registros: state.contas.registros,
      error: state.contas.error
    },
    preferencias: {
      loading: state.preferencias.loading,
      registros: state.preferencias.registros,
      error: state.preferencias.error
    },
  }
}

const mapDispatchToProps = dispatch => {
  return {
    listarContas: () => dispatch(listarContas()),
    listarPreferencias: (user_id) => dispatch(listarPreferencias(user_id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Movimento)